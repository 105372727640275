import { axiosClient } from '../axiosClient';

type Params = {
  userId: number;
  roleIds: number[];
};

export const createUserRoles = ({ roleIds, userId }: Params) => {
  return axiosClient.post(`/users/${userId}/roles`, { role_id: roleIds });
};
