import { nanoid } from 'nanoid';

import { cardAttributes } from '~/constants';
import { CardTypes } from '~/constants/general';
import { STATUS_CODE_ATTRIBUTE } from '~/containers/Conversations/Cards/APICard/APICard';

import { InstaStackActionBodySchema } from './instaStackUtils';

export const cardObjectCreator = (actionType, dataOverride) => {
  const card = {
    id: null,
    action_type: actionType,
    action_order: null,
    cust_attr_id: null,
    param1: null,
    param2: null,
    param3: null,
    validState: true,
    error: cardAttributes[actionType].errors,
    saved: false,
    ...dataOverride,
    tip: {
      text: cardAttributes[actionType].tip,
      state: false,
      ...dataOverride?.tip,
    },
  };
  let extraData = {};
  switch (actionType) {
    case CardTypes.TEXT:
      extraData = {
        intent_choices: [],
      };
      break;
    case CardTypes.DELAY:
      extraData = {
        param1: 'PT5S',
        intent_choices: [],
      };
      break;
    case CardTypes.ATTRIBUTE:
      extraData = {
        intent_choices: [],
      };
      break;
    case CardTypes.CAROUSEL:
      extraData = {
        intent_carousel: [
          {
            h_choose_buttons: [],
            click_url: '',
            h_choose_id: null,
            image_url: '',
            h_choose_order: 1,
            h_choose_title: '',
            h_choose_subtitle: '',
          },
        ],
      };
      break;
    case CardTypes.API:
      extraData = {
        intent_choices: [],
        api_params: [],
        api_templates: [],
        api_attributes: [],
        param2: STATUS_CODE_ATTRIBUTE,
      };
      break;
    case CardTypes.IMAGE:
      extraData = {
        intent_choices: [],
      };
      break;
    case CardTypes.VIDEO:
      extraData = {
        intent_choices: [],
      };
      break;
    case CardTypes.GOTO:
      extraData = {
        intent_choices: dataOverride?.intent_choices ?? [],
      };
      break;
    case CardTypes.HANDOVER:
      extraData = {
        intent_choices: [],
      };
      break;
    case CardTypes.QUICK_REPLY:
      extraData = {
        intent_choices: [],
      };
      break;
    case CardTypes.DATA_SUBMIT:
      extraData = {
        intent_choices: [],
      };
      break;
    case CardTypes.SMS:
    case CardTypes.SMS_VALIDATION:
      extraData = {
        param1: '',
        intent_choices: [],
      };
      break;
    case CardTypes.GOAL:
      extraData = {
        param1: '',
        param2: '',
        intent_choices: [],
      };
      break;
    case CardTypes.FALLBACK:
      extraData = {
        param1: '',
        intent_choices: [],
      };
      break;
    case CardTypes.INSTA_STACK_API:
      return {
        ...card,
        intent_choices: [],
        api_action: {
          api_action_body: JSON.stringify(
            InstaStackActionBodySchema.getDefault({
              context: { isNewCard: true },
            })
          ),
        },
      };
    case CardTypes.SET_AI:
      extraData = {
        param1: '',
        param2: '',
        intent_choices: [],
      };
      break;
    case CardTypes.INTENT_AI:
      extraData = {
        param1: '',
        // We save the intents names in the param2 field separated by commas and we required at least 3 intents so we add 2 commas by default
        // to make 3 inputs to appear in the Card by default
        param2: ',,',
        intent_choices: [],
      };
      break;
    case CardTypes.FORM_AI:
      extraData = {
        // We save the fields names in the param1 field separated by commas and we show 3 inputs by default
        // to make 3 inputs to appear in the Card by default
        param1: ',,',
        intent_choices: [],
      };
      break;
    default:
      break;
  }
  return { ...card, ...extraData };
};

export const createIntentData = (intents, prevIntents = null) => {
  return intents.map((intent, index) => ({
    ...intent,
    uuid: intent?.id ? `${intent.id}` : nanoid(),
    save: true,
    validState: true,
    error: cardAttributes[intent.action_type]?.errors,
    tip: prevIntents?.[index]?.tip ?? {
      text: cardAttributes[intent.action_type]?.tip,
      state: false,
    },
  }));
};

export const toISOTimeFormat = (duration, unit) => {
  return 'PT' + duration + '' + unit;
};

export const getCurrentIntentId = (search = window.location.search) => {
  const params = new window.URLSearchParams(search);
  const intentId = parseInt(params.get('blockId'), 10);

  return Number.isNaN(intentId) ? null : intentId;
};

export const getIntentName = (intent) => {
  if (!intent || !intent.intent_name) return '';

  const { intent_name } = intent;
  return intent_name === 'No Match' ? 'AI Routing' : intent_name;
};
