import type { paths } from '~/__generated__/botco-api-schema';
import type {
  ExtractPathParamsFromPath,
  ExtractOkResponseFromPath,
  // ExtractRequestBodyFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type Path = paths['/agents/{agentId}/intents/inputs/batch'];
type PathUrl = Path['post'];
type UrlParams = ExtractPathParamsFromPath<Path>;
// type Body = ExtractRequestBodyFromPath<PathUrl>; //TODO: add it back when BE team updates the OpenAPI schema
type Response = ExtractOkResponseFromPath<PathUrl>;

type Request = {
  params: UrlParams;
  body: Array<{
    intent_id?: number | null;
    inputs: Array<{
      deploy_id: number;
      input_value: string;
      training_log_id: string;
      skip_training?: boolean;
    }>
  }>
};

export const bulkAgentIntentInputsTranscript = ({ body, params }: Request) => {
  return axiosClient.post<Response>(
    `/agents/${params.agentId}/intents/inputs/batch`,
    body
  );
};
