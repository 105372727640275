import { Box } from '@material-ui/core';
import * as React from 'react';
import { useMutation } from 'react-query';

import { SpinnerLoader } from '~/components/SpinnerLoader';
import { useDashboardQueryServiceContext } from '~/containers/Dashboard/Provider/DasboardQueryServiceProvider';
import { useApi } from '~/hooks/useApi';
import { useQueryServiceApi } from '~/hooks/useQueryServiceApi';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { handleErrors } from '~/utils';
import Api from '~/utils/http';
import {
  PersonalDashboardMetric,
  SubMetricType,
} from '~/utils/http/dashboard/types';

type ContextType = {
  personalMetrics: PersonalDashboardMetric[];
  toggleMetricView: (personalMetricId: string) => void;
  replaceDisplayedMetric: (
    currentPersonalMetricId: string,
    newPersonalMetricId: string
  ) => void;
};

const Context = React.createContext<ContextType | undefined>({
  personalMetrics: [],
  toggleMetricView: () => {},
  replaceDisplayedMetric: () => {},
});

export const PersonalDashboardProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { selectedAgent } = useSelectedChatbot();
  const { conditions } = useDashboardQueryServiceContext();

  const { data: qsData, isFetched: isQsDataFetched } = useQueryServiceApi(
    ['goals', 'dataSubmits', conditions?.customer_account],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: {
            customer_account: conditions?.customer_account,
          },
          projection: [
            {
              metric: 'data_submits',
              views: ['counts_by_tag'],
            },
            {
              metric: 'goals',
              views: ['counts_by_tag'],
            },
          ],
        },
      })
  );

  const goals =
    qsData?.goals?.content?.counts_by_tag?.map((g) => g.tag ?? '') ?? [];
  const dataSubmits =
    qsData?.data_submits?.content?.counts_by_tag?.map((ds) => ds.tag ?? '') ??
    [];

  const [personalMetrics, setPersonalMetrics] = React.useState<
    PersonalDashboardMetric[]
  >([]);

  const { isLoading } = useApi(
    ['getPersonalDashboardMetrics', selectedAgent?.account_id],
    (Api) =>
      Api.dashboard.overview.getPersonalDashboardMetrics({
        accountId: selectedAgent?.account_id!,
        goals,
        dataSubmits,
      }),
    {
      enabled: Boolean(isQsDataFetched && selectedAgent?.account_id),
      retry: false,
      onSuccess: (data) => setPersonalMetrics(data),
    }
  );

  const setMetricsMutation = useMutation({
    mutationFn: (metrics: PersonalDashboardMetric[]) =>
      Api.dashboard.overview.setPersonalDashboardMetrics({
        metrics,
        accountId: selectedAgent?.account_id!,
      }),
    onError: (error) => {
      handleErrors(error);
    },
  });

  if (isLoading || !personalMetrics.length) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <SpinnerLoader />
      </Box>
    );
  }

  const toggleMetricView = (personalMetricId: string) => {
    setPersonalMetrics((metrics) => {
      const updatedMetrics = metrics.map<PersonalDashboardMetric>((metric) => {
        if (metric.id === personalMetricId) {
          return {
            ...metric,
            status: metric.status === 'show' ? 'hidden' : 'show',
          };
        }

        if (metric.submetrics?.length) {
          const updatedSubmetrics = metric.submetrics.map<SubMetricType>(
            (submetric) =>
              submetric.id === personalMetricId
                ? {
                    ...submetric,
                    status: submetric.status === 'show' ? 'hidden' : 'show',
                  }
                : submetric
          );

          return {
            ...metric,
            submetrics: updatedSubmetrics,
          };
        }

        return metric;
      });

      setMetricsMutation.mutate(updatedMetrics);

      return updatedMetrics;
    });
  };

  const replaceDisplayedMetric = (
    currentMetricId: string,
    newMetricId: string
  ) => {
    setPersonalMetrics((metrics) => {
      const updatedMetrics = metrics.map<PersonalDashboardMetric>((metric) => {
        if (metric.id === currentMetricId) {
          return {
            ...metric,
            status: 'hidden',
          };
        }

        if (metric.id === newMetricId) {
          return {
            ...metric,
            status: 'show',
          };
        }

        if (metric.submetrics?.length) {
          const updatedSubmetrics = metric.submetrics.map<SubMetricType>(
            (submetric) =>
              submetric.id === currentMetricId
                ? { ...submetric, status: 'hidden' }
                : submetric.id === newMetricId
                  ? { ...submetric, status: 'show' }
                  : submetric
          );

          return {
            ...metric,
            submetrics: updatedSubmetrics,
          };
        }

        return metric;
      });

      setMetricsMutation.mutate(updatedMetrics);

      return updatedMetrics;
    });
  };

  return (
    <Context.Provider
      value={{ personalMetrics, toggleMetricView, replaceDisplayedMetric }}
    >
      {children}
    </Context.Provider>
  );
};

export const usePersonalDashboard = () => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error(
      'useDashboardPersonalization must be used within a DashboardPersonalizationProvider'
    );
  }

  return context;
};
