import type { AxiosResponse } from 'axios';

import type { UpdateDeployment, Deployment } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  deployId: number;
  payload: UpdateDeployment;
};

export const redeploy = ({ deployId, payload }: Options) => {
  return axiosClient.put<UpdateDeployment, AxiosResponse<Deployment>>(
    `/deployments/${deployId}/deploy`,
    payload
  );
};
