import { PaginatedResponse } from '@botco/api';
import { paths } from '~/__generated__/botco-api-schema';
import { ExtractQueryParamsFromPath } from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';
import { AgentAuditTrail } from './types';

type PathUrl = paths['/audit/agents/{agentId}']['get'];
type QueryParams = NonNullable<ExtractQueryParamsFromPath<PathUrl>>;

type Params = {
  agentId: number;
  query?: QueryParams;
};

export const getAgentAuditTrail = ({ agentId, query }: Params) => {
  return axiosClient.get<PaginatedResponse<AgentAuditTrail[]>>(
    `/audit/agents/${agentId}`,
    { params: query }
  );
};
