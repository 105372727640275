import {
  Popper,
  PopperProps,
  Fade,
  Paper,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core';

type PropsType = Pick<PopperProps, 'anchorEl'> & { title: string };

export const SideNavPopper = ({ anchorEl, title }: PropsType) => {
  const classes = useStyles();

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="right"
      transition
      className={classes.popper}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper classes={{ root: classes.paper }}>
            <Typography className={classes.popperParagraph}>{title}</Typography>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    popper: {
      position: 'relative',
      zIndex: '9999 !important' as any,
    },
    paper: {
      boxShadow: 'none',
      background: '#19125F',
      borderRadius: '5px',
      color: '#ffffff',
      marginLeft: '18px',
      overflow: 'visible !important',
      position: 'relative',
      padding: '8px 12px',
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '10.83px',
        height: '10.83px',
        left: '-4px',
        top: 'calc(50% - 5px)',
        background: '#19125F',
        transform: 'rotate(-45deg)',
        zIndex: 9999,
      },
    },
    popperParagraph: {
      textTransform: 'capitalize',
    },
  })
);
