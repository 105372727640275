export class TokenStore {
  constructor() {
    this.aToken = null;
    this.rToken = null;
  }

  get accessToken() {
    return this.aToken;
  }

  set accessToken(val) {
    this.aToken = val;
  }

  get refreshToken() {
    return this.rToken;
  }

  set refreshToken(val) {
    this.rToken = val;
  }

  initTokens() {
    this.aToken = null;
    this.rToken = null;
  }
}
