
import type { paths } from '~/__generated__/botco-api-schema';
import type {
  ExtractOkResponseFromPath,
  ExtractRequestBodyFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/auth/login']['post'];
type Body = ExtractRequestBodyFromPath<PathUrl>;
type Response = Required<ExtractOkResponseFromPath<PathUrl>>;

type Options = {
  body: Body;
};

export const login = ({ body }: Options) => {
  return axiosClient.post<Response>('/auth/login', body);
};
