type Primitive = string | number;

type SortOrder = 'ASC' | 'DESC';

export function sortArray<T extends object, SortBy extends Primitive>(
  fn: (obj: T) => SortBy,
  order: SortOrder = 'DESC'
) {
  return (a: T, b: T): number => {
    const value1 = fn(a);
    const value2 = fn(b);

    switch (order) {
      case 'ASC':
        return value1 < value2 ? -1 : 1;
      default:
        return value1 > value2 ? -1 : 1;
    }
  };
}

export function getLastItemInArray<T>(slice: T[]): T | null {
  if (!slice.length) return null;
  return slice[slice.length - 1];
}

export function arrayToMap<Obj extends Object, Key extends String>(
  array: Obj[],
  getKey: (obj: Obj) => Key
) {
  return new Map(array.map((item) => [getKey(item), item]));
}
