import { MenuItem, MenuItemProps } from '@material-ui/core';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ConfirmationDialog } from '~/components/ConfirmationDialog';
import { routes } from '~/constants/routes';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { deleteAgent } from '~/redux/actions/conversationDataR';

import { AgentType } from '../../../types';

type PropsType = MenuItemProps<
  'li',
  { agent: AgentType; handleUnselectChatbot: () => void }
>;

export const DeleteAgentMenuItem = ({
  agent,
  handleUnselectChatbot,
  ...menuItemProps
}: PropsType) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { selectedAgent } = useSelectedChatbot();

  const toggleModal = () => setIsModalOpen((current) => !current);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      if (agent.id !== selectedAgent?.id) {
        return dispatch(deleteAgent(agent.id));
      }

      handleUnselectChatbot();
      dispatch(deleteAgent(agent.id));
      history.push(routes.chatbots.get());
    } finally {
      toggleModal();
      setIsLoading(false);
    }
  };

  return (
    <>
      <MenuItem {...menuItemProps} button onClick={toggleModal}>
        Delete
      </MenuItem>
      {isModalOpen && (
        <ConfirmationDialog
          open
          onClose={toggleModal}
          confirmButton={{
            text: 'Yes',
            color: 'secondary',
            onClick: handleDelete,
            disabled: isLoading,
          }}
        />
      )}
    </>
  );
};
