import { ALL_INTENTS, SET_SELECTED_AGENT } from '../types/conversationNew';

const initialState = {
  allIntents: [],
};

export const conversationNewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_INTENTS: {
      const allIntents = action.payload.map((item) => ({
        value: item.id,
        label: item.intent_name,
        ...item,
      }));
      return { ...state, allIntents, allIntentsNew: action.payload };
    }
    case SET_SELECTED_AGENT: {
      return {
        ...state,
        selectedAgent: action.payload,
      };
    }
    default:
      return state;
  }
};
