import { axiosClient } from '../axiosClient';

type Options = {
  agentId: number;
};

export const getIsValid = ({ agentId }: Options) =>
  axiosClient.get<{ message: string | null; is_valid: boolean }>(
    `/agents/${agentId}/valid`
  );
