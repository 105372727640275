import { axiosClient } from '../axiosClient';
import { DataSubmitMapping } from '../deployment/types';

type Params = {
  agentId: number;
  deployId: number;
  data: DataSubmitMapping[];
};

export const updateTargetConfiguration = async ({
  agentId,
  data,
  deployId,
}: Params) => {
  return axiosClient.post(`/target-configuration`, data, {
    params: {
      deploy_id: deployId,
      agent_id: agentId,
    },
  });
};
