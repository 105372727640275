import { Honeybadger } from '@honeybadger-io/react';
import LogRocket from 'logrocket';
import * as React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getAllAgents, getMe } from '~/redux/actions/conversationDataR';
import { resetReduxStore } from '~/redux/actions/conversationPreview';
import { selectUserDetails } from '~/redux/selectors/conversationsDataR';
import { getToken, removeTokens } from '~/utils';

type State = {
  openModal: boolean;
  showComponent: boolean;
  loading: boolean;
};

const initialState: Readonly<State> = {
  openModal: false,
  showComponent: false,
  loading: true,
};

export const useLayout = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);
  const [state, setState] = React.useState<State>(initialState);
  const history = useHistory();

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    debounce: 500,
    onIdle: () => {
      setState((state) => ({ ...state, openModal: true }));
    },
  });

  React.useEffect(() => {
    const token = getToken();
    if (!token) {
      const url = new URL(window.location.href);
      history.push(
        url.pathname !== '/'
          ? `/?redirectUrl=${url.pathname}${url.search}`
          : '/'
      );
      return;
    }
    setState((state) => ({ ...state, showComponent: true }));
    dispatch(getMe());
    dispatch(getAllAgents());
  }, [history, dispatch]);

  React.useEffect(() => {
    if (!state.showComponent || !userDetails?.userName || !state.loading)
      return;

    const user = {
      userId: userDetails.user_id,
      accountId: userDetails.account_id,
      email: userDetails.email,
      userName: userDetails.userName,
      userAvatar: userDetails.user_avatar ?? '',
    };

    Honeybadger.setContext(user);
    LogRocket.identify(`${user.userId}`, user);

    setState((state) => ({ ...state, loading: false }));
  }, [state.showComponent, state.loading, userDetails, history]);

  const logout = () => {
    setState(initialState);
    removeTokens();
    window.sessionStorage.clear();
    dispatch(resetReduxStore());
    history.push('/');
  };

  return {
    showComponent: !state.loading && state.showComponent,
    openIdleDialog: state.openModal,
    logout,
  };
};
