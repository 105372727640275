import { makeStyles } from '@material-ui/core';

import { CreatableDropDown } from '@botco/library';
import { isValidNewOption } from '~/utils';

import { AttributeInputProps } from './AttributesInput.types';
import { useAttributeInput } from './useAttributeInput';
import { AttributeChip } from '../AttributeChip';
import { AttributeGroup } from '../AttributeGroup';
import { AttributeOption } from '../AttributeOption';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: `${theme.spacing(1)}px !important`,
  },
  option: {
    padding: theme.spacing(0.5, 0, 0.5, 2),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
}));

export const AttributesInput = ({
  additionalAttributes,
  agentId,
  value,
  className,
  showError,
  placeholder,
  dataTestId,
  label = '',
  onChange: onChangeProp,
  disabled,
  disableCreate,
  error,
  helperText,
}: AttributeInputProps) => {
  const {
    currentValue,
    onChange,
    onInputChange,
    onCreate,
    options,
    inputValue,
    showInvalidAttributeMessage,
  } = useAttributeInput({
    agentId,
    additionalAttributes,
    onChange: onChangeProp,
    value,
  });

  const classes = useStyles();

  return (
    <CreatableDropDown
      label={label}
      current={currentValue}
      classes={{
        option: classes.option,
        inputRoot: classes.root,
      }}
      className={className}
      disableCreateOption={disableCreate}
      inputValue={inputValue}
      onInputChange={onInputChange}
      dataTest={dataTestId}
      options={options}
      required
      showError={showError}
      error={error}
      helperText={helperText}
      placeholder={currentValue?.length ? '' : placeholder}
      groupBy={(option) => option.group ?? ''}
      disabled={disabled}
      multiple
      renderTags={(value) => {
        return value.map((option) => (
          <AttributeChip
            className={classes.chip}
            dataTestId="attribute-selected-value"
            onDelete={() => onChange([])}
            key={`${option.label}_${option.group}`}
            attribute={option.label}
          />
        ));
      }}
      renderGroup={(props) => <AttributeGroup {...props} key={props.key} />}
      renderOption={(props) => (
        <AttributeOption
          {...props}
          showInvalidAttributeMessage={showInvalidAttributeMessage}
        />
      )}
      formatCreateLabel={(inputValue) => {
        return inputValue;
      }}
      isValidNewOption={isValidNewOption}
      onCreateOption={onCreate}
      onChange={onChange}
    />
  );
};
