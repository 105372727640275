import { styled } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';

import { Tooltip, Typography, theme } from '@botco/library';

const Title = styled(Typography)(() => ({
  position: 'relative',
  zIndex: 1,
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'default',
  width: 'fit-content',
  maxWidth: '100%',
}));

type Props = {
  title: string;
  value: number;
};

export const PopularBlocksChart: React.FC<Props> = ({ title, value }) => {
  const options: ApexCharts.ApexOptions = {
    fill: {
      colors: ['#302A6F'],
    },
    chart: {
      type: 'bar',
      toolbar: { show: false },
    },
    grid: { show: false },
    plotOptions: {
      bar: {
        distributed: false,
        barHeight: '30px',
        horizontal: true,
        borderRadius: 8,
        dataLabels: {
          position: 'end',
          hideOverflowingLabels: true,
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      background: { padding: 20 },
      style: {
        fontSize: '16px',
        fontWeight: 600,
        colors: ['#302A6F'],
        fontFamily: theme.typography.fontFamily,
      },
      // added invisible character to be able to add spacing between label and graph
      formatter: (val) => `‎   ${val} %`,
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      max: 100,
      categories: [''],
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    tooltip: { enabled: false },
  };

  return (
    <div>
      <Title color="black">
        <Tooltip title={title} interactive>
          <span>{title}</span>
        </Tooltip>
      </Title>
      <ReactApexChart
        options={options}
        series={[{ data: [value] }]}
        type="bar"
        height="100"
        style={{ zIndex: 0, margin: '-35px 0 -24px -20px' }}
      />
    </div>
  );
};
