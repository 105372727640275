import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractCreatedResponseFromPath,
  ExtractPathParamsFromPath,
  ExtractRequestBodyFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/agents/{agentId}/forms']['post'];
type Response = ExtractCreatedResponseFromPath<PathUrl>;
type UrlParams = ExtractPathParamsFromPath<PathUrl>;
type Body = ExtractRequestBodyFromPath<PathUrl>;

type Request = {
  payload: Body;
  params: UrlParams;
};

export const createAgentForm = ({ payload, params }: Request) => {
  return axiosClient.post<Response>(`/agents/${params.agentId}/forms`, payload);
};
