import axios from 'axios';

import { constants } from '~/config';

import type { PersonalDashboardMetric } from '../types';

type Params = {
  accountId: number;
  metrics: PersonalDashboardMetric[];
};

export const setPersonalDashboardMetrics = ({ accountId, metrics }: Params) =>
  axios.post<PersonalDashboardMetric[]>(
    'https://updatedashboarddata-c6qhepyr5q-uc.a.run.app/',
    { dashboardSettings: metrics },
    { params: { orgID: accountId, env: constants.app_env } }
  );
