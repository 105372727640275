import { Intent } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  agentId: number;
  intentId: number;
};

export const getAgentIntentById = ({ agentId, intentId }: Options) => {
  return axiosClient.get<Intent>(`/agents/${agentId}/intents/${intentId}`);
};
