import { axiosClient } from '../axiosClient';

type Params = {
  userId: number;
  roleIds: number[];
};

export const deleteUserRoles = ({ roleIds, userId }: Params) => {
  return axiosClient({
    method: 'DELETE',
    url: `/users/${userId}/roles`,
    data: { role_id: roleIds },
  });
};
