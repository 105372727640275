import { Box } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Typography, theme } from '@botco/library';
import { Attributes } from '~/components/Attributes';
import {
  addApiCardAttribute,
  removeApiCardAttribute,
} from '~/redux/actions/conversationDataR';
import { showToast } from '~/utils';
import { Attribute } from '~/utils/http/agent/types';

import { APICardButton } from './APICardButton';
import { ApiCardAttributeModal } from '../../../ApiCardAttributeModal';
import { ApiUsageTypeEnum } from '../APICard.types';

type Props = {
  index: number;
  type: ApiUsageTypeEnum;
};

type ModalState = {
  attribute: Partial<Attribute>;
  jsonPath: string;
  order?: number;
};

export const ApiAttributes = ({ index, type }: Props) => {
  const dispatch = useDispatch();
  const [modal, setModal] = React.useState<ModalState | null>(null);
  const { attributes } = useSelector(({ conversationsDataR }) => ({
    attributes:
      conversationsDataR.intentCardsData?.[index]?.api_attributes ?? [],
  }));

  const handleSetModal = (name: string, value: string) => {
    if (!modal) return;
    setModal({ ...modal, [name]: value });
  };

  const handleSaveAttributes = () => {
    if (!modal?.attribute?.cust_attr_name || !modal?.jsonPath) {
      showToast('error', 'Please enter both required fields');
      return;
    }

    const existingKeys = attributes
      .filter(
        (attribute, index) =>
          index !== modal.order && attribute.attr_usage_type === type
      )
      .map((attribute) => attribute.cust_attr_name);

    if (existingKeys.includes(modal.attribute.cust_attr_name)) {
      showToast('error', 'Please use different Attribute Name');
      return;
    }

    dispatch(
      addApiCardAttribute(index, modal?.order ?? -1, {
        cust_attr_id: modal.attribute.cust_attr_id,
        cust_attr_name: modal.attribute.cust_attr_name,
        cust_attr_type: modal.attribute.cust_attr_type,
        attr_value_jsonpath: modal.jsonPath,
        attr_usage_type: type,
      })
    );
    setModal(null);
  };

  const handleDeleteAttribute = (attributeIndex: number) => {
    dispatch(removeApiCardAttribute(index, attributeIndex));
  };

  return (
    <>
      <Box pt={2} pb={1}>
        <Typography variant="h6" fontWeight="600">
          Attributes
        </Typography>
        <Typography color="textSecondary">
          Create Attributes to format API&apos;s response
        </Typography>
      </Box>
      <Box borderTop={`2px solid ${theme.palette.grey[400]}`}>
        {attributes.map((attribute, i) => {
          if (attribute.attr_usage_type !== type) return null;

          return (
            <APICardButton
              key={`${i}-${attribute.cust_attr_id}`}
              onDelete={() => handleDeleteAttribute(i)}
            >
              <Attributes.Chip
                attribute={attribute.cust_attr_name!}
                onClick={() =>
                  setModal({
                    attribute,
                    jsonPath: attribute.attr_value_jsonpath ?? '',
                    order: i,
                  })
                }
              />
            </APICardButton>
          );
        })}
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          variant="text"
          size="small"
          color="grey"
          onClick={() => setModal({ attribute: {}, jsonPath: '' })}
        >
          + Add Attributes
        </Button>
      </Box>
      {modal && (
        <ApiCardAttributeModal
          modal
          value={modal}
          onClose={() => setModal(null)}
          onUpdate={handleSetModal}
          onSave={handleSaveAttributes}
        />
      )}
    </>
  );
};
