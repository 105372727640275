import type {
  CustomChip,
  CustomText,
  CustomEditor,
} from '@botco/library/dist/slate';
import type { Descendant, Point } from 'slate';
import { Range, Editor } from 'slate';

import { defaultAttributes } from '~/constants/attributes';
import {
  cleanAttribute,
  extractAttributesFromTextRegex,
} from '~/utils/attributeUtils';

import { ATTRIBUTE_MATCH_REGEX } from './AttributeTextEditor.types';

export const buildParagraphElement = (
  children: Array<CustomText | CustomChip>
): Descendant => {
  return {
    type: 'paragraph',
    children,
  };
};

export const buildTextElement = (text: string): CustomText => ({
  text,
  type: 'text',
});

export const buildChipElement = (value: string): CustomChip => {
  const attribute = cleanAttribute(value);
  return {
    type: 'chip',
    children: [buildTextElement('')],
    label: attribute,
    props: {
      dataTestId: `attribute-${attribute}`,
      color: defaultAttributes.includes(attribute) ? 'secondary' : 'default',
    },
  };
};

const buildDescendantsFromLine = (line: string): Descendant => {
  const matches = [...line.matchAll(extractAttributesFromTextRegex)];

  if (!matches.length) {
    return buildParagraphElement([buildTextElement(line)]);
  }

  const [descendants] = matches.reduce<
    [Array<CustomText | CustomChip>, string, number]
  >(
    (acc, attribute, index, attributes) => {
      const [descendants, text, counter] = acc;
      const [match] = attribute;

      const start = attribute.index ?? 0;
      const end = start + match.length;

      const subStrStart = start - counter;
      const subStrEnd = end - counter;

      const startText = text.substring(0, subStrStart);

      if (startText) {
        descendants.push(buildTextElement(startText));
      }

      descendants.push(
        buildChipElement(text.substring(subStrStart, subStrEnd))
      );
      // Adding whitespace to separate chips
      descendants.push(buildTextElement(' '));

      if (index === attributes.length - 1) {
        const remainingText = text.substring(subStrEnd);
        if (remainingText) {
          descendants.push(buildTextElement(remainingText));
        }
      }

      return [descendants, text.substring(subStrEnd), end];
    },
    [[], line, 0]
  );

  return buildParagraphElement(descendants);
};

export const stringToDescendats = (
  value: string = ''
): [Descendant, ...Descendant[]] => {
  const lines = value?.split('\n') ?? [];
  if (!lines.length) {
    return [buildParagraphElement([buildTextElement('')])];
  }

  const children = lines.reduce<Descendant[]>((acc, line) => {
    if (!line.includes('${')) {
      return [...acc, buildParagraphElement([buildTextElement(line)])];
    }

    return [...acc, buildDescendantsFromLine(line)];
  }, []);

  return children as [Descendant, ...Descendant[]];
};

export const serializeDescendants = (descendants: Descendant[]): string => {
  const serializeText = (text: CustomText): string => {
    if (!text.text.trim()) return '';
    return text.text;
  };

  const serializeChip = (chip: CustomChip): string => {
    return `\${${chip.label}}`;
  };

  const serializeParagraph = (paragraph: Descendant): string => {
    if (!('type' in paragraph) || paragraph.type !== 'paragraph') {
      return '';
    }

    return paragraph.children
      .map((element): string => {
        if (!('type' in element)) {
          return '';
        }
        switch (element.type) {
          case 'chip':
            return serializeChip(element);
          default:
            return serializeText(element);
        }
      })
      .join('');
  };

  return descendants.map(serializeParagraph).join('\n');
};

// Just copy-pasted this function from https://github.com/ianstormtaylor/slate/issues/4162#issuecomment-812618312
// if you think you can make it better please do so
function getWordFromLocation(
  editor: CustomEditor,
  location: Range,
  options: {
    terminator?: string[];
    include?: boolean;
    directions?: 'both' | 'left' | 'right';
  } = {}
): Range | undefined {
  const { terminator = [' '], include = false, directions = 'both' } = options;

  const { selection } = editor;
  if (!selection) return;

  // Get start and end, modify it as we move along.
  let [start, end] = Range.edges(location);

  let point: Point = start;

  function move(direction: 'right' | 'left'): boolean {
    const next =
      direction === 'right'
        ? Editor.after(editor, point, {
            unit: 'character',
          })
        : Editor.before(editor, point, { unit: 'character' });

    const wordNext =
      next &&
      Editor.string(
        editor,
        direction === 'right'
          ? { anchor: point, focus: next }
          : { anchor: next, focus: point }
      );

    const last =
      wordNext && wordNext[direction === 'right' ? 0 : wordNext.length - 1];
    if (next && last && !terminator.includes(last)) {
      point = next;

      if (point.offset === 0) {
        // Means we've wrapped to beginning of another block
        return false;
      }
    } else {
      return false;
    }

    return true;
  }

  // Move point and update start & end ranges

  // Move forwards
  if (directions !== 'left') {
    point = end;
    while (move('right'));
    end = point;
  }

  // Move backwards
  if (directions !== 'right') {
    point = start;
    while (move('left'));
    start = point;
  }

  if (include) {
    return {
      anchor: Editor.before(editor, start, { unit: 'offset' }) ?? start,
      focus: Editor.after(editor, end, { unit: 'offset' }) ?? end,
    };
  }

  return { anchor: start, focus: end };
}

export const getAttributeAndTargetNodeFromEditor = (editor: CustomEditor) => {
  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    const wordBeforeRange = getWordFromLocation(editor, selection, {
      include: true,
    });
    const wordBefore =
      wordBeforeRange && Editor.string(editor, wordBeforeRange)?.trim();

    const beforeMatch = wordBefore?.match(ATTRIBUTE_MATCH_REGEX);

    if ((beforeMatch || wordBefore === '${') && wordBeforeRange) {
      const beforeMatchText = beforeMatch?.[1] ?? '';
      return {
        attribute: beforeMatchText,
        targetNode: wordBeforeRange,
      };
    }
  }

  return {
    attribute: null,
    targetNode: null,
  };
};
