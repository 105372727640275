import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractOkResponseFromPath,
  ExtractQueryParamsFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/trainingsv2/fallback']['get'];
type Response = ExtractOkResponseFromPath<PathUrl>;
type Request = ExtractQueryParamsFromPath<PathUrl>;

export async function getTrainingFallbacks(params: Request) {
  return axiosClient.get<Response>('/trainingsv2/fallback', {
    params,
  });
}
