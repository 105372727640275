import { isAxiosError, type AxiosError } from 'axios';
import { UseBaseQueryOptions } from 'react-query';

import { isValidAgentId } from '~/utils/agentUtils';
import type { Deployment } from '~/utils/http/deployment/types';

import { useApi } from './useApi';

type Options<T> = Pick<
  UseBaseQueryOptions<Deployment[], AxiosError<unknown>, T, Deployment[]>,
  'enabled' | 'onError' | 'onSettled' | 'onSuccess' | 'select'
> & {
  agentId?: number;
  runtimeTenantId?: boolean;
};

export const useGetDeployments = <T = Deployment[]>({
  agentId,
  enabled = true,
  runtimeTenantId = false,
  ...options
}: Options<T>) => {
  return useApi(
    ['GetDeployments', agentId, runtimeTenantId],
    // @ts-ignore
    (Api) => {
      return Api.deployment.getDeployments({
        agent_id: agentId,
        runtime_tenant_id: runtimeTenantId,
      });
    },
    {
      enabled: enabled && isValidAgentId(agentId),
      cacheTime: 10 * 60 * 1000,
      retry: (failureCount, error) => {
        if (isAxiosError(error) && error.response?.status === 404) {
          return false;
        }

        if (failureCount > 2) return false;

        return false;
      },
      ...options,
    }
  );
};
