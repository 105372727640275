import * as yup from 'yup';

export const utmSources = [
  {
    value: '_utm_term',
    label: 'UTM Term',
  },
  {
    value: '_referral_page',
    label: 'Referral Page',
  },
  {
    value: '_referral_code',
    label: 'Referral Code',
  },
  {
    value: '_utm_source',
    label: 'UTM Source',
  },
  {
    value: '_utm_campaign',
    label: 'UTM Campaign',
  },
] as const;

export const UTMSourceSchema = yup
  .mixed<(typeof utmSources)[number]['value']>()
  .oneOf(utmSources.map(({ value }) => value));

export type UTMSourceSchemaType = yup.InferType<typeof UTMSourceSchema>;
