import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import {
  useForm,
  FormProvider,
  Controller,
  useController,
  useFieldArray,
  FieldErrors,
} from 'react-hook-form';

import { Button, Select, TrashIcon } from '@botco/library';
import { Attributes } from '~/components/Attributes';
import { FilterFormItem } from '~/components/FilterFormItem';
import { useDashboardContext } from '~/containers/Dashboard/Provider/DashboardProvider';
import {
  type DashboardFiltersSchemaType,
  DashboardFiltersSchema,
} from '~/containers/Dashboard/Provider/DashboardProvider.types';

import { DashboardDatePicker } from '../DatePicker';

const useStyles = makeStyles((theme) => ({
  attributeInputsWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  attributeField: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    justifyContent: 'center',
  },
}));

type Props = {
  onClose: () => void;
};

export const DashboardFilterForm = ({ onClose }: Props) => {
  const classes = useStyles();
  const { filters, deployTypeOptions, deploymentOptions, setFilters } =
    useDashboardContext();

  const methods = useForm<DashboardFiltersSchemaType>({
    resolver: yupResolver(DashboardFiltersSchema),
    defaultValues: DashboardFiltersSchema.cast(filters),
  });

  const startTime = useController({
    name: 'startTime',
    control: methods.control,
  });

  const endTime = useController({
    name: 'endTime',
    control: methods.control,
  });

  const compare = useController({
    name: 'compare',
    control: methods.control,
  });

  const attributes = useFieldArray({
    name: 'attributes.conditions',
    control: methods.control,
  });

  const deployType = methods.watch('deployType');

  const handleSubmit = methods.handleSubmit((data) => {
    setFilters(data);
    onClose();
  });

  return (
    <FormProvider {...methods}>
      <Grid container spacing={4}>
        <FilterFormItem title="Date">
          <DashboardDatePicker
            value={{
              compare: compare.field.value,
              startTime: startTime.field.value,
              endTime: endTime.field.value,
            }}
            onChange={(value) => {
              startTime.field.onChange(value.startTime);
              endTime.field.onChange(value.endTime);
              compare.field.onChange(value.compare);
            }}
          />
        </FilterFormItem>
        <Grid item xs={12} sm={6} />
        <FilterFormItem title="Platform">
          <Controller<DashboardFiltersSchemaType, 'deployType'>
            name="deployType"
            render={({ field }) => (
              <Select
                fullWidth
                id="deployType"
                placeholder="Select a Platform"
                current={filters.viewAll ? '' : `${field.value ?? ''}`}
                onChange={(value) => {
                  field.onChange(value);
                  methods.setValue('deployId', null);
                }}
                disabled={filters.viewAll}
                options={[
                  { label: 'Select a Platform', value: '' },
                  ...deployTypeOptions,
                ]}
              />
            )}
          />
        </FilterFormItem>
        <FilterFormItem title="Deployment">
          <Controller<DashboardFiltersSchemaType, 'deployId'>
            name="deployId"
            render={({ field }) => (
              <Select
                fullWidth
                id="deployId"
                placeholder="Select Launch"
                current={`${filters.viewAll ? '' : (field.value ?? '')}`}
                onChange={field.onChange}
                disabled={!deployType || filters.viewAll}
                options={[
                  { label: 'Select Launch', value: '' },
                  ...deploymentOptions,
                ]}
              />
            )}
          />
        </FilterFormItem>
        <FilterFormItem title="Attribute" gridProps={{ sm: 12 }}>
          <div className={classes.attributeInputsWrapper}>
            {attributes.fields.map((field, index) => (
              <Controller
                key={field.id}
                control={methods.control}
                name={`attributes.conditions.${index}`}
                render={({ field, formState }) => (
                  <div className={classes.attributeField}>
                    <Attributes.Filter
                      value={field.value ?? { operator: 'eq' }}
                      onChange={field.onChange}
                      errors={
                        formState.errors.attributes?.conditions?.[
                          index
                        ] as FieldErrors<
                          DashboardFiltersSchemaType['attributes']
                        >
                      }
                    />
                    {attributes.fields.length > 1 && (
                      <IconButton
                        size="small"
                        onClick={() => attributes.remove(index)}
                      >
                        <TrashIcon />
                      </IconButton>
                    )}
                  </div>
                )}
              />
            ))}

            <div>
              {attributes.fields.length < 3 && (
                <Button
                  variant="text"
                  size="small"
                  onClick={() => attributes.append({ operator: 'eq' })}
                >
                  + Add Attribute
                </Button>
              )}
            </div>
          </div>
        </FilterFormItem>
        <Grid item xs={12} className={classes.actions}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Apply</Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
