import { makeStyles } from '@material-ui/core';
import * as React from 'react';

import { Card } from '@botco/library';

import { PersonalDashboardOverviewChart } from './components/Chart';
import { HiddenMetricsMenu } from './components/HiddenMetricsMenu';
import { PersonalDashboardOverviewTab } from './components/Tab';
import {
  PersonalMetricOverviewType,
  usePersonalDashboardOverview,
} from './usePersonalDashboardOverview';
import { usePersonalDashboard } from '../../Provider/PersonalDashboardProvider';

const useStyles = makeStyles(() => ({
  metricsWrapper: {
    display: 'flex',

    '& > div:first-child': {
      borderBottomLeftRadius: 0,
    },
  },
  addMetricWrapper: {
    width: 256,
    height: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chartWrapper: {
    minHeight: 350,
    borderTopLeftRadius: 0,
  },
}));

export const PersonalDashboardOverview = () => {
  const classes = useStyles();
  const { toggleMetricView, replaceDisplayedMetric } = usePersonalDashboard();

  const {
    shownMetrics,
    selectedMetricId,
    setSelectedMetricId,
    shouldShowAddMetric,
  } = usePersonalDashboardOverview();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [mode, setMode] = React.useState<'toggle' | 'replace'>('toggle');

  const handleAddMetric = (
    overviewMetricId: PersonalMetricOverviewType['id']
  ) => {
    switch (mode) {
      case 'toggle':
        toggleMetricView(overviewMetricId);
        break;
      case 'replace':
        replaceDisplayedMetric(selectedMetricId, overviewMetricId);
        break;
    }

    setAnchorEl(null);
    setSelectedMetricId(overviewMetricId);
  };

  const handleShowMenu = (
    e: React.MouseEvent<HTMLElement>,
    mode: 'toggle' | 'replace'
  ) => {
    e.stopPropagation();
    setMode(mode);
    setAnchorEl(e.currentTarget);
  };

  const handleRemove = (
    e: React.MouseEvent<HTMLElement>,
    overviewMetricId: PersonalMetricOverviewType['id']
  ) => {
    e.stopPropagation();
    toggleMetricView(overviewMetricId);
    if (selectedMetricId === overviewMetricId) {
      setSelectedMetricId(shownMetrics[0]?.id);
    }
  };

  return (
    <div>
      <PersonalDashboardOverviewTab
        metrics={shownMetrics}
        selectedMetricId={selectedMetricId}
        setSelectedMetricId={setSelectedMetricId}
        shouldShowAddMetric={shouldShowAddMetric}
        onAddMetric={(e) => handleShowMenu(e, 'toggle')}
        onEdit={(e) => handleShowMenu(e, 'replace')}
        onRemove={handleRemove}
      />
      {anchorEl && (
        <HiddenMetricsMenu
          open
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          onClick={handleAddMetric}
        />
      )}
      {shownMetrics.map((metric) => (
        <Card
          role="tabpanel"
          key={`tab-panel-${metric.id}`}
          id={`tab-panel-${metric.id}`}
          aria-labelledby={`tab-${metric.id}`}
          hidden={metric.id !== selectedMetricId}
          className={classes.chartWrapper}
        >
          <PersonalDashboardOverviewChart overviewMetric={metric} />
        </Card>
      ))}
    </div>
  );
};
