import type { paths } from '~/__generated__/botco-api-schema';
import type { ExtractRequestBodyFromPath } from '~/types/open-api-helpers';

import type { InstaStackCollection } from './types';
import { axiosClient } from '../axiosClient';

type PathUrl = paths['/instastack/collection']['post'];
type Body = ExtractRequestBodyFromPath<PathUrl>;

type Options = {
  body: Body;
};

export const createInstaStackCollection = ({ body }: Options) => {
  return axiosClient.post<InstaStackCollection>('/instastack/collection', body);
};
