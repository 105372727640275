import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractOkResponseFromPath,
  ExtractPathParamsFromPath,
  ExtractQueryParamsFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type Path = paths['/agents/{agentId}/categories'];
type PathUrl = Path['get'];
type UrlParams = ExtractPathParamsFromPath<PathUrl>;
type QueryParams = ExtractQueryParamsFromPath<PathUrl>;
type Response = ExtractOkResponseFromPath<PathUrl>;

type Options = UrlParams & QueryParams;

export const getCategories = ({
  agentId,
  include_actions,
  include_intents,
}: Options) => {
  return axiosClient.get<Response>(`/agents/${agentId}/categories`, {
    params: {
      include_intents,
      include_actions,
    },
  });
};
