import { PaginatedResponse } from '@botco/api';

import { InputHistoryContent } from './types';
import { axiosClient } from '../axiosClient';

type Response = PaginatedResponse<InputHistoryContent[]>;
type Request = {
  urlParams: {
    agentId: number;
  };
  params: {
    page: number;
    size: number;
  };
};

export const getAgentInputHistory = ({ params, urlParams }: Request) => {
  return axiosClient.get<Response>(
    `agents/${urlParams.agentId}/inputs/history`,
    {
      params,
    }
  );
};
