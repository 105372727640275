import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractOkResponseFromPath,
  ExtractQueryParamsFromPath,
  ExtractRequestBodyFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/chatbot/simulate/enqueue']['post'];
// @ts-ignore
type Response = ExtractOkResponseFromPath<PathUrl>;
// @ts-ignore
type Body = ExtractRequestBodyFromPath<PathUrl>;
// @ts-ignore
type Query = ExtractQueryParamsFromPath<PathUrl>;

type Request = {
  body: Body;
  params: Query;
};

export const chatbotSimulate = ({ body, params }: Request) => {
  return axiosClient.post<Response>('/chatbot/simulate/enqueue', body, {
    params,
  });
};
