import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { Theme } from 'packages/library/dist';

const useStyles = makeStyles<Theme, { $align: 'left' | 'right' }>((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: (props) => (props.$align === 'left' ? 'row' : 'row-reverse'),
  },
  avatar: {
    position: 'absolute',
    left: theme.spacing(-2),
    bottom: theme.spacing(-1),
    width: theme.spacing(5),
    minWidth: theme.spacing(5),
    height: theme.spacing(5),
    minHeight: theme.spacing(5),
    backgroundColor: theme.palette.grey[400],
  },
}));

type Props = {
  align?: 'left' | 'right';
};

export const TranscriptItemSkeleton = ({ align = 'left' }: Props) => {
  const classes = useStyles({ $align: align });

  return (
    <div className={classes.container}>
      <Skeleton variant="text" width="70%" height={68} />
      {align === 'left' && (
        <Skeleton
          className={classes.avatar}
          variant="circle"
          animation={false}
        />
      )}
    </div>
  );
};
