import { clsx } from 'clsx';
import * as React from 'react';

type PropsType = {
  className?: string;
  loaderStyle?: React.CSSProperties;
};

export const SpinnerLoader = ({ loaderStyle, className }: PropsType) => {
  return (
    <div
      style={loaderStyle}
      className={clsx('spinner-border text-primary', className)}
      role="progressbar"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};
