import { Container, Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { UserContactInfoDto } from '~/__generated__/query-service';
import { SpinnerLoader } from '~/components/SpinnerLoader';
import { routes } from '~/constants/routes';
import { useApi } from '~/hooks/useApi';
import { useQueryServiceApi } from '~/hooks/useQueryServiceApi';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { QueryServiceMetricsConfig } from '~/types/query-service-helpers';

import { ContactAttributesTable } from './components/AttributesTable';
import { ContactTagsTable } from './components/ContactTagsTable';
import { ContactDataSubmitTable } from './components/DataSubmitTable';
import { ContactGoalsTable } from './components/GoalsTable';
import { ContactTranscripts } from './components/Transcripts';

type Props = { contact: UserContactInfoDto } | { contactId: string };

const useStyles = makeStyles((theme) => ({
  container: {
    width: '80vw',
    height: '100%',
    paddingTop: theme.spacing(4),
  },
  grid: {
    height: '100%',
  },
  leftColumn: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

export const ContactDetails = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { selectedAgent } = useSelectedChatbot();

  const contactId = 'contactId' in props ? props.contactId : null;
  const isContactIdUUID = yup.string().uuid().isValidSync(contactId);

  const { isLoading, data } = useQueryServiceApi(
    ['contacts', contactId, selectedAgent],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: {
            customer_account: {
              external_id: selectedAgent?.account_id,
              chatbot_definitions: [{ external_id: selectedAgent?.id }],
            },
            user_id: contactId!,
          },
          projection: [QueryServiceMetricsConfig.Contacts],
        },
      }),
    {
      enabled: Boolean(contactId && isContactIdUUID && selectedAgent?.id),
    }
  );

  const { isLoading: loadingLegacyContactId } = useApi(
    ['legacyContact', contactId, selectedAgent],
    (Api) =>
      Api.contacts.getContactDetails({
        contactId: contactId!,
        queryParams: {
          agent_id: selectedAgent?.id!,
          account_id: selectedAgent?.account_id!,
          join_with_data_submits: false,
        },
      }),
    {
      enabled: Boolean(contactId && !isContactIdUUID && selectedAgent?.id),
      onSuccess: (data) => {
        if (!data[0]?.platform_id) return;

        history.replace(
          routes.contacts.get(selectedAgent?.id!, {
            contactId: data[0]?.platform_id,
          })
        );
      },
    }
  );

  if (contactId && (isLoading || loadingLegacyContactId)) {
    return (
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.loadingWrapper}>
          <SpinnerLoader />
        </div>
      </Container>
    );
  }

  const contact =
    'contactId' in props
      ? (data?.users?.content?.contacts?.page_items?.[0] ?? {})
      : props.contact;

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container spacing={4} className={classes.grid}>
        <Grid item xs={6}>
          <div className={classes.leftColumn}>
            <ContactGoalsTable goals={contact.goals ?? []} />
            <ContactDataSubmitTable dataSubmits={contact.data_submits!} />
            <ContactTagsTable tags={contact.tag_labels ?? []} />
            <ContactAttributesTable contactId={contact.id!} />
          </div>
        </Grid>
        <Grid item xs={6} className={classes.grid}>
          <ContactTranscripts contactId={contact.id!} />
        </Grid>
      </Grid>
    </Container>
  );
};
