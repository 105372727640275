import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  selectAgents,
  selectDeploymentsByAccount,
} from '~/redux/selectors/conversationsDataR';

import { AgentType, AgentsListTabs } from './types';

type PropsType = {
  activeTab: AgentsListTabs;
  isPublic: boolean | null;
  searchText: string;
};

export const useAgentsList = ({
  activeTab,
  isPublic,
  searchText,
}: PropsType) => {
  const agents: AgentType[] = useSelector(selectAgents);
  const deployments = useSelector(selectDeploymentsByAccount);

  const publishedAgentIds: number[] = React.useMemo(() => {
    return Array.from(new Set(deployments.map((item: any) => item.agent_id)));
  }, [deployments]);

  const filteredAgents = React.useMemo(() => {
    const filteredConversations: AgentType[] = searchText.length
      ? agents.filter((conversation) =>
          conversation.agent_name
            .toLowerCase()
            .includes(searchText.toLowerCase())
        )
      : agents;

    switch (activeTab) {
      case AgentsListTabs.Published:
        return filteredConversations.filter((item) =>
          publishedAgentIds.includes(item.id)
        );

      case AgentsListTabs.InProgress:
        return filteredConversations.filter(
          (item) => !publishedAgentIds.includes(item.id)
        );

      case AgentsListTabs.Templates:
        return filteredConversations.filter(
          (item) => item.is_template === true && item.is_public === isPublic
        );

      case AgentsListTabs.All:
      default:
        return filteredConversations;
    }
  }, [activeTab, agents, isPublic, searchText, publishedAgentIds]);

  const AgentTemplateHasChildrenMap: Record<number, boolean> = filteredAgents
    .filter((agent) => agent.is_template)
    .reduce<Record<number, boolean>>((acc, template) => {
      if (!acc[template.id]) {
        acc[template.id] =
          agents.findIndex((agent) => agent.parent_agent_id === template.id) >
          -1;
      }

      return acc;
    }, {});

  return {
    agents,
    AgentTemplateHasChildrenMap,
    filteredAgents,
  };
};
