import { makeStyles, Tab, Tabs } from '@material-ui/core';
import { AddCircleOutline as AddIcon } from '@material-ui/icons';
import * as React from 'react';

import { Typography } from '@botco/library';

import { PersonalDashboardOverviewTabHeader } from './PersonalDashboardOverviewTabHeader';
import { PersonalMetricOverviewType } from '../../usePersonalDashboardOverview';

const useStyles = makeStyles((theme) => ({
  indicator: {
    display: 'none',
  },
  tabs: {
    marginLeft: -theme.spacing(5),
  },
  tab: {
    width: 256,
    minWidth: 256,
    maxWidth: 256,
    height: 120,
    padding: '0 20px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px 8px 0px 0px',
    textTransform: 'none',
    borderRight: '1px solid rgba(0, 0, 0, 0.20)',
    boxShadow: '0px 0px 20px rgba(3, 27, 137, 0.1)',
    color: theme.palette.text.disabled,
    borderTop: `8px solid ${(theme.palette as any).purple[600]}`,

    '&:hover #overview-card-button': {
      visibility: 'visible',
    },
  },
  selected: {
    borderTopColor: (theme.palette as any).purple[900],
    color: theme.palette.common.black,
    boxShadow: 'none',
  },
  title: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  addMetric: {
    textTransform: 'none',
    opacity: 1,
    color: theme.palette.primary.main,
  },
  addMetricTitle: {
    textTransform: 'none',
    flexDirection: 'row',
    gap: theme.spacing(1),
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
  },
}));

type Props = {
  metrics: PersonalMetricOverviewType[];
  selectedMetricId: PersonalMetricOverviewType['id'];
  setSelectedMetricId: (metricId: PersonalMetricOverviewType['id']) => void;
  shouldShowAddMetric: boolean;
  onAddMetric: (e: React.MouseEvent<HTMLElement>) => void;
  onEdit: (e: React.MouseEvent<HTMLElement>) => void;
  onRemove: (
    e: React.MouseEvent<HTMLElement>,
    metricId: PersonalMetricOverviewType['id']
  ) => void;
};

export const PersonalDashboardOverviewTab = ({
  metrics,
  onAddMetric,
  onEdit,
  onRemove,
  selectedMetricId,
  setSelectedMetricId,
  shouldShowAddMetric,
}: Props) => {
  const classes = useStyles();

  return (
    <Tabs
      value={selectedMetricId}
      variant="scrollable"
      scrollButtons="on"
      onChange={(_, tab) =>
        tab === 'add-metric' ? null : setSelectedMetricId(tab)
      }
      classes={{ root: classes.tabs, indicator: classes.indicator }}
    >
      {metrics.map((metric) => (
        <Tab
          key={metric.id}
          id={`tab-${metric.id}`}
          aria-controls={`tabpanel-${metric.id}`}
          label={
            <PersonalDashboardOverviewTabHeader
              overviewMetric={metric}
              onEdit={onEdit}
              onRemove={onRemove}
            />
          }
          value={metric.id}
          disableRipple
          classes={{
            root: classes.tab,
            selected: classes.selected,
            wrapper: classes.title,
          }}
        />
      ))}
      {shouldShowAddMetric && (
        <Tab
          key="add-metric"
          aria-controls="search-menu"
          aria-haspopup="true"
          label={
            <div className={classes.addMetricTitle} onClick={onAddMetric}>
              <AddIcon />
              <Typography fontWeight="700">Add Metric</Typography>
            </div>
          }
          value="add-metric"
          disableRipple
          classes={{ root: classes.addMetric }}
        />
      )}
    </Tabs>
  );
};
