import { paths } from '~/__generated__/botco-api-schema';
import { ExtractRequestBodyFromPath } from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/instastack/web-crawler']['post'];
// @ts-ignore
type Body = ExtractRequestBodyFromPath<PathUrl>;

export const webCrawler = (data: Body) => {
  return axiosClient.post<void>('/instastack/web-crawler', data);
};
