import { Box, makeStyles } from '@material-ui/core';
import { clsx } from 'clsx';
import { useSelector } from 'react-redux';

import { SearchInput } from '@botco/library';
import { selectUserDetails } from '~/redux/selectors/conversationsDataR';
import { isInAccount0 } from '~/utils/userUtils';

import { Tabs } from '../../Tabs';
import { AgentsListTabs } from '../types';

const useStyles = makeStyles((theme) => ({
  indicator: { backgroundColor: '#20a8bf !important ' },
  searchBar: {
    marginBottom: theme.spacing(4),
  },
}));

interface PropsType {
  currentTab: AgentsListTabs;
  isPublic: boolean | null;
  searchText: string;
  onEnter?: () => void;
  onChangeTab: (tab: AgentsListTabs) => void;
  onChangePublic: (isPublic: boolean | null) => void;
  onChangeSearchText: (text: string) => void;
}

export const AgentsListFilter = ({
  currentTab,
  isPublic,
  searchText,
  onEnter,
  onChangeTab,
  onChangePublic,
  onChangeSearchText,
}: PropsType) => {
  const classes = useStyles();
  const userDetails = useSelector(selectUserDetails);

  const showTemplates =
    isInAccount0(userDetails) && currentTab === AgentsListTabs.Templates;

  const handleClickPrivateSkills = () => onChangePublic(null);
  const handleClickPublicSkills = () => onChangePublic(true);

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Tabs
          active={currentTab}
          classes={{ indicator: classes.indicator }}
          onChange={onChangeTab}
        >
          <div key={AgentsListTabs.All} className="botco-conversation-tab">
            All
          </div>
          <div
            key={AgentsListTabs.InProgress}
            className="botco-conversation-tab"
          >
            In Progress
          </div>
          <div
            key={AgentsListTabs.Published}
            className="botco-conversation-tab"
          >
            Published
          </div>
          {isInAccount0(userDetails) ? (
            <div
              key={AgentsListTabs.Templates}
              className="templatesNav botco-conversation-tab"
            >
              Skills
            </div>
          ) : (
            <></>
          )}
        </Tabs>
      </Box>

      <div className={clsx('row', classes.searchBar)}>
        <div className="col-md-3" />
        <div className="col-md-6">
          <SearchInput
            autoFocus
            name="searchText"
            value={searchText}
            onEnter={onEnter}
            dataTestId="chatbot-list-search-input"
            onChange={(_, value) => onChangeSearchText(value)}
          />
        </div>
        <div className="col-md-3" />
      </div>

      {showTemplates && (
        <Box display="flex" justifyContent="center">
          <button
            onClick={handleClickPrivateSkills}
            className={clsx({
              'botco-deploy-default-button': isPublic,
              'botco-deploy-blue-button': !isPublic,
              'botco-conversation-tab': true,
            })}
          >
            Private Skills
          </button>
          <button
            onClick={handleClickPublicSkills}
            className={clsx({
              'botco-deploy-default-button': !isPublic,
              'botco-deploy-blue-button': isPublic,
              'botco-conversation-tab': true,
            })}
          >
            Public Skills
          </button>
        </Box>
      )}
    </>
  );
};
