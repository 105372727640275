import { AxiosResponse } from 'axios';

import { InstaStackDocument } from './types';
import { axiosClient } from '../axiosClient';

export const instaStackDocumentUpload = (
  collectionId: number,
  data: FormData
) => {
  return axiosClient.post<FormData, AxiosResponse<InstaStackDocument[]>>(
    `/instastack/document/upload/${collectionId}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};
