import {
  Box,
  Container,
  Grid,
  TableCell,
  TablePagination,
} from '@material-ui/core';
import * as React from 'react';

import { Card, Table, Typography } from '@botco/library';
import { TableSkeleton } from '~/components/TableSkeleton';
import { useQueryServiceApi } from '~/hooks/useQueryServiceApi';
import { QueryServiceMetricsConfig } from '~/types/query-service-helpers';

import { DashboardBlocksChart } from './components/DashboardBlocksChart';
import { DashboardCSVButton } from '../components/CSVButton/DashboardCSVButton';
import { useDashboardQueryServiceContext } from '../Provider/DasboardQueryServiceProvider';

const PAGE_SIZE = 10;

export const DashboardBlocks: React.FC = () => {
  const [page, setPage] = React.useState(0);
  const { conditions } = useDashboardQueryServiceContext();

  const { isLoading, data } = useQueryServiceApi(
    ['dashboard', 'v2', conditions, 'Blocks'],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: conditions!,
          projection: [
            {
              ...QueryServiceMetricsConfig.Blocks,
              options: { 'metric/limit': '100' },
            },
          ],
        },
      }),
    { enabled: Boolean(conditions) }
  );

  const total =
    data?.blocks?.content?.counts_by_name?.reduce(
      (acc, { count }) => acc + (count ?? 0),
      0
    ) ?? 0;

  const totalCount = data?.blocks?.content?.counts_by_name?.length ?? 0;

  const result =
    data?.blocks?.content?.counts_by_name?.map((d) => ({
      name: d.name!,
      count: d.count ?? 0,
      average: Number(((100 * (d.count ?? 0)) / (total || 1)).toFixed(2)),
    })) ?? [];

  const startIndex = page * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;

  const resultForCurrentPage = result.slice(startIndex, endIndex);

  return (
    <Container maxWidth="xl">
      <Grid item xs={12}>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography fontWeight="700">Popular Blocks</Typography>
              <Table
                data={resultForCurrentPage}
                getKey={(item) => `${item.name}-${item.count}`}
                variant="transparent"
                headerColumns={
                  <>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Total</TableCell>
                  </>
                }
                renderRow={(item) => (
                  <>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="center">{item.count}</TableCell>
                  </>
                )}
              >
                {isLoading && <TableSkeleton columns={6} rows={6} />}
              </Table>
              <TablePagination
                rowsPerPageOptions={[]}
                count={totalCount}
                colSpan={5}
                component="div"
                rowsPerPage={10}
                page={page}
                onPageChange={(_, page) => setPage(page)}
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography fontWeight="700">Percentage</Typography>
                <div>
                  <DashboardCSVButton
                    data={result}
                    variant="contained"
                    fileName="popular-blocks.csv"
                    kind="array"
                    headers={[
                      { key: 'name', label: 'Name' },
                      { key: 'count', label: 'Count' },
                      { key: 'average', label: 'Average' },
                    ]}
                  />
                </div>
              </Box>
              <DashboardBlocksChart total={total} data={result} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Container>
  );
};
