import { makeStyles, createStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

import { Card } from '@botco/library';

type PropsType = {
  title: React.ReactNode;
  height?: number;
};

export const DashboardCard = (props: React.PropsWithChildren<PropsType>) => {
  const { title, height = 0 } = props;
  const classes = useStyles();

  return (
    <Card className={classes.card} height={height > 0 ? `${height}px` : 'auto'}>
      <Typography variant="h4" classes={{ root: classes.title }}>
        {title}
      </Typography>
      {props.children}
    </Card>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      margin: theme.spacing(2, 0),
      textTransform: 'uppercase',
    },
    card: {
      minHeight: 245,
      position: 'relative',
      fontFamily: 'Montserrat',
    },
  })
);
