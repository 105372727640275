import { ReferralCode } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  payload: ReferralCode[];
};

export const pushReferralCodes = ({ payload }: Options) => {
  return axiosClient.post('/referrals/multiple', payload);
};
