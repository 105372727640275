import { paths } from '~/__generated__/botco-api-schema';
import { ExtractQueryParamsFromPath } from '~/types/open-api-helpers';

import { WebJobCrawlerType } from './types';
import { axiosClient } from '../axiosClient';

type PathUrl = paths['/instastack/web-crawler/job']['get'];
type Params = ExtractQueryParamsFromPath<PathUrl>;

export const webCrawlerJobStatus = (params: Params) => {
  return axiosClient.get<WebJobCrawlerType>('/instastack/web-crawler/job', {
    params,
  });
};
