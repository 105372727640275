export const FORM_BRANCH = 'Form Branch';
export const BLOCK = 'Block';

export const ResponseTypes = [FORM_BRANCH, BLOCK];

export const LastQuestionResponseTypes = [FORM_BRANCH, BLOCK];

export const ContineLinkTypes = [FORM_BRANCH, BLOCK];

export const BUILD_TAB = 'BUILD';
export const PREVIEW_TAB = 'PREVIEW';

export const SCREENS = {
  CREATE_FORM_QUESTIONS: 'CREATE_FORM_QUESTIONS',
  ASK_FORM_QUESTIONS: 'ASK_FORM_QUESTIONS',
  FINISH_FORM: 'FINISH_FORM',
};

export const HEADER_HEIGHT_PIXELS = '64px';
