import { components } from '~/__generated__/botco-api-schema';

export const RoleEnum = {
  Administrator: 1,
  BotAuthor: 2,
  Deploy: 3,
  BotPii: 4,
  InstaStack: 5,
} as const;

export type Role = {
  role_id: EnumValueType<typeof RoleEnum>;
  role_name: string;
};

export type User = Required<components['schemas']['User']>;
