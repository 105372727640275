import {
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@botco/library';
import CrossIcon from '~/assets/icons/cross.svg?react';
import PencilIcon from '~/assets/icons/pencil.svg?react';
import { useGetAgent } from '~/containers/Conversations/ConversationPreview/useGetAgent';
import { ConversationsListContainer } from '~/containers/Conversations/ConversationsList/ConversationsListContainer';
import { AgentType } from '~/containers/Conversations/ConversationsList/types';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useQueryParams } from '~/hooks/useQueryParams';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { getCurrentRouteRecord } from '~/utils';
import { getDefaultChatbot } from '~/utils/storageHelpers';
import * as yup from '~/utils/yup';

export const SelectedChatbotButton = () => {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = useQueryParams();

  const currentUser = useCurrentUser();

  const defaultChatbotId = React.useMemo(() => {
    const { match } = getCurrentRouteRecord(history.location.pathname);

    let urlAgentId: number | null | undefined = null;

    if (match) {
      const conversationId =
        'conversation_id' in match.params
          ? match?.params.conversation_id
          : null;

      const agentId = 'agentId' in match.params ? match?.params.agentId : null;

      urlAgentId = yup
        .safeNumber()
        .nullable()
        .validateSync(conversationId || agentId || queryParams.get('agentId'));
    }

    return urlAgentId || getDefaultChatbot(currentUser.account_id);
  }, [history.location.pathname, currentUser.account_id, queryParams]);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { selectedAgent, setSelectChatbot } = useSelectedChatbot();

  const { isLoading } = useGetAgent({
    agentId: Number(defaultChatbotId),
    enabled: Boolean(
      defaultChatbotId &&
        (!selectedAgent || selectedAgent.id !== Number(defaultChatbotId))
    ),
    retry: false,
    onSuccess: (agent) => {
      if (!agent) return;
      setSelectChatbot(agent);
      handleSelectChatbot(agent);
    },
  });

  const handleOpenModal = () => setIsModalOpen(true);

  const handleSelectChatbot = (agent?: AgentType) => {
    setIsModalOpen(false);

    if (isLoading) return;

    const { route, match } = getCurrentRouteRecord(history.location.pathname);

    const isSameAgent = agent?.id === selectedAgent?.id;

    if (route && agent && !isSameAgent) {
      const params = new URLSearchParams(window.location.search);
      history.push(
        `${route.get(agent.id, match?.params ?? {})}${!selectedAgent ? `?${params}` : ''}`
      );
    }
  };

  return (
    <>
      <Button id="selected-chatbot-button" onClick={handleOpenModal}>
        {selectedAgent?.agent_name ?? 'Select Chatbot'}
        <PencilIcon className={classes.editButton} />
      </Button>

      {isModalOpen && (
        <Dialog
          open
          keepMounted
          maxWidth="xl"
          classes={{ paper: classes.modalPaper }}
          onClose={() => handleSelectChatbot()}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            disableRipple
            onClick={() => handleSelectChatbot()}
          >
            <CrossIcon />
          </IconButton>
          <DialogContent>
            <ConversationsListContainer
              onSelectingChatbot={handleSelectChatbot}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    padding: 0,
    position: 'absolute',
    right: 14,
    top: 10,
    color: 'black',
    '&:hover': {
      background: 'none',
    },
  },
  modalPaper: {
    width: '80%',
    maxHeight: 'calc(100% - 240px)',
  },
  editButton: {
    width: 18,
    height: 18,
    marginLeft: theme.spacing(0.5),
  },
}));
