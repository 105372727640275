import './assets/css/botco-theme.css';
import './assets/css/ReactToastify.css';
import './App.css';

import * as React from 'react';
import { QueryClientProvider } from 'react-query';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { getToken, initFBSDK } from '~/utils';
import { queryClient } from '~/utils/queryClient';

import { Layout } from './components/Layout/Layout';
import { Login } from './containers/Login/Login';
import { USER_LOGGED_OUT } from './redux/types/conversationDataR';

export const App = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const checkIfUserIsLoggedIn = () => {
      const token = getToken();
      if (!token) {
        dispatch({ type: USER_LOGGED_OUT });
      }
    };

    checkIfUserIsLoggedIn();
    initFBSDK();
  }, [dispatch]);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Login} exact />
          <Layout />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </QueryClientProvider>
  );
};
