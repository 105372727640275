import { createStyles, ListItem, makeStyles } from '@material-ui/core';
import { clsx } from 'clsx';
import * as React from 'react';
import { LinkProps, NavLink } from 'react-router-dom';

import { SideNavPopper } from './SideNavPopper';

type PropsType = {
  to?: LinkProps['to'];
  title: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void;
  component?: React.ElementType;
  ['data-testid']: string;
  active?: boolean;
  disableTooltip?: boolean;
};

export const SideNavItem = (props: React.PropsWithChildren<PropsType>) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handlePopoverOpen = (e: React.MouseEvent<HTMLDivElement>) =>
    setAnchorEl(props.disableTooltip ? null : e.currentTarget);

  const handlePopoverClose = () => setAnchorEl(null);

  return (
    <>
      <ListItem
        component={props.component || NavLink}
        to={props.to}
        button
        className={clsx(classes.menuItem, {
          [classes.activeMenuItem]: props.active,
        })}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter ?? handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        data-testid={props['data-testid']}
        {...(!props.component && { activeClassName: classes.activeMenuItem })}
      >
        {props.children}
      </ListItem>
      <SideNavPopper title={props.title} anchorEl={anchorEl} />
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      color: `${theme.palette.common.white} !important`,
      padding: '8px',
      marginTop: 12,
      borderRadius: '10px 0px 0px 10px',
      justifyContent: 'center',

      '&:hover': {
        backgroundColor: (theme.palette as any).purple['800'],
      },

      '& > svg': {
        width: 24,
        height: 24,
      },
    },
    activeMenuItem: {
      backgroundColor: `${theme.palette.grey['300']} !important`,
      color: `${(theme.palette as any).purple.main} !important`,
    },
  })
);
