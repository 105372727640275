import * as React from 'react';

import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useQueryServiceApi } from '~/hooks/useQueryServiceApi';
import { Attribute } from '~/utils/http/agent/types';
import { paginate } from '~/utils/paginate';

import { useDashboardQueryServiceContext } from '../Provider/DasboardQueryServiceProvider';

export const PAGE_SIZE = 10;

export const useAttributeBreakdown = () => {
  const user = useCurrentUser();

  const [page, setPage] = React.useState(1);
  const [selectedAttribute, setSelectedAttribute] =
    React.useState<Partial<Attribute>>();

  const { conditions } = useDashboardQueryServiceContext();

  const { data } = useQueryServiceApi(
    ['goals', conditions],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: conditions!,
          projection: [
            {
              metric: 'goals',
              views: ['counts_by_tag'],
            },
          ],
        },
      }),
    { enabled: user.hasPIIRole }
  );

  const { data: attributeValueData, isLoading: isLoadingAttrValues } =
    useQueryServiceApi(
      ['AttributeBreakdown_Attribute_Values', conditions, selectedAttribute],
      (Api) =>
        Api.query.query({
          requestBody: {
            conditions: conditions!,
            projection: [
              {
                metric: 'attributes',
                views: ['counts_by_value'],
                options: {
                  'metric/include_names': selectedAttribute?.cust_attr_name!,
                },
              },
            ],
          },
        }),
      {
        enabled: user.hasPIIRole && Boolean(selectedAttribute?.cust_attr_name),
      }
    );

  const goals = data?.goals?.content?.counts_by_tag?.map((t) => t.tag!) ?? [];

  const {
    data: attributeValues,
    totalItems,
    totalPages,
  } = paginate(
    attributeValueData?.attributes?.content?.counts_by_value ?? [],
    page,
    PAGE_SIZE
  );

  return {
    attributeValues,
    goals,
    isLoadingAttrValues,
    page,
    setPage,
    selectedAttribute,
    setSelectedAttribute,
    totalItems,
    totalPages,
  };
};
