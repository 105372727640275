import 'bootstrap/dist/css/bootstrap.css';
import 'react-image-crop/dist/ReactCrop.css';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { base64ToBlob } from 'base64-blob';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Button, FormGroup } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BotcoAPI } from '@botco/api';
import spinner from '~/assets/img/35.gif';
import upload from '~/assets/img/UPLOAD.png';
import { constants } from '~/config';
import { routes } from '~/constants/routes';
import { getAllAgents } from '~/redux/actions/conversationDataR';
import { handleErrors, showToast } from '~/utils';

const newApiCall = new BotcoAPI(constants.api_url);

class ModalSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conversation_title: '',
      avatar: '',
      avatarPreview: '',
      showSpinner: false,
      croppedImage: '',
      disableSave: false,
      titleError: false,
      crop: {
        unit: 'px',
        width: 200,
        height: 200,
        maxWidth: 500,
        aspect: 1 / 1,
      },
      croppedImageUrl: '',
    };
  }

  handleChange = (event) => {
    this.setState(
      {
        conversation_title: event.target.value,
      },
      () => this.checkConversationTitle()
    );
  };

  validateForm() {
    return (
      this.state.conversation_title.trim().length > 0 &&
      this.state.disableSave === false
    );
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ showSpinner: true, disableSave: true });
    const body = {
      avatar: this.state.croppedImageUrl,
      agent_name: this.state.conversation_title.trim(),
      is_template: false,
      is_public: null,
      welcome_intent_id: null,
      parent_agent_id: null,
    };

    newApiCall
      .createAgent(body)
      .then(({ data }) => {
        document.getElementById('myInput').value = '';
        this.setState(
          {
            agent_id: data.id,
            showSpinner: false,
            disableSave: false,
            croppedImageUrl: '',
            croppedImage: '',
          },
          () => {
            this.props.getAllAgents();
            this.props.closed();
            this.props.history.push(routes.chatbotDetails.get(data.id));
          }
        );
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          showToast('error', err?.response?.data?.message);
        }

        this.setState({
          showSpinner: false,
          disableSave: false,
        });
        document.getElementById('myInput').value = '';
      });
  };

  checkConversationTitle = () => {
    if (this.state.conversation_title === '' || !this.state.conversation_title)
      this.setState({
        titleError: true,
      });
    else
      this.setState({
        titleError: false,
      });
  };

  onSelectFile = (e) => {
    this.setState({
      crop: {
        unit: 'px',
        width: 200,
        height: 200,
        maxWidth: 500,
        aspect: 1 / 1,
      },
    });
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      (e.target.files[0].type === 'image/png' ||
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/jpg' ||
        e.target.files[0].type === 'image/gif' ||
        e.target.files[0].type === 'image/bmp')
    ) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({
          avatarPreview: reader.result,
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    } else {
      showToast('error', 'Invalid format');
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    if (crop.width >= 200 && crop.width <= 500) this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl, disableSave: false });
    }
  }

  async getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL('image/jpeg');

    // return base64Image;
    const body = new FormData();
    const avatarBlob = await base64ToBlob(base64Image);
    body.append('file', avatarBlob);

    try {
      const response = await newApiCall.uploadFile(body);
      const { location } = response.data;
      return location;
    } catch (err) {
      handleErrors(err);
      return null;
    }
  }

  closeCreateConvModal = () => {
    document.getElementById('myInput').value = '';
    this.setState(
      {
        croppedImageUrl: '',
        avatarPreview: '',
        disableSave: false,
      },
      () => this.props.closed()
    );
  };

  render() {
    const { fullScreen } = this.props;
    const { crop } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={this.props.modalOpen}
        PaperProps={{ square: true, style: { maxWidth: '100%' } }}
      >
        <DialogContent style={{ maxWidth: '1000px' }}>
          <div className="ModalSummary">
            <form
              onSubmit={(e) => {
                this.props.useThisTemplateFlag
                  ? this.props.useThisTemplate(
                      this.state.croppedImageUrl,
                      this.state.conversation_title.trim(),
                      e
                    )
                  : this.handleSubmit(e);
              }}
              encType="multipart/form-data"
            >
              <h4 className="title">Create Conversation!</h4>
              <div className="content">
                <div className="row">
                  <div className="col-md-12 create_conv_titles">
                    <b>Conversation Title</b>
                  </div>
                </div>
                <div className="row" style={{ paddingBottom: 30 }}>
                  <div className="col-md-12">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <input
                        autoFocus
                        onBlur={this.checkConversationTitle}
                        type="text"
                        className="form_inputs"
                        value={this.state.conversation_title}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-12 conv_placeholders">
                    e.g. NIKE Welcome Script
                  </div>
                </div>
                <div className="row">
                  <input
                    id="myInput"
                    type="file"
                    ref={(ref) => (this.myInput = ref)}
                    style={{ display: 'none' }}
                    onChange={this.onSelectFile}
                  />
                  <div className="col-md-12 create_conv_titles">
                    <b>Upload Bot Avatar</b>
                  </div>
                </div>
                <div className="row">
                  <div className="pl-5">
                    <img
                      alt="upload"
                      src={upload}
                      className="mr-5 mt-5 pointer"
                      onClick={() => this.myInput.click()}
                    />
                  </div>
                  {this.state.croppedImage != '' && (
                    <div className="col-md-10">
                      <div className="col-md-2 react-divs">
                        <img
                          alt="avatar"
                          src={this.state.croppedImage}
                          className="avatarPreview"
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-10">
                    <span className="conv_placeholders">
                      (Images should be 200px x 200px,ideally uploaded as a
                      square.)
                    </span>
                  </div>
                </div>

                {this.state.avatarPreview !== '' && (
                  <div className="col-12 text-center mt-5">
                    <ReactCrop
                      src={this.state.avatarPreview}
                      crop={crop}
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  </div>
                )}
                <div className="btns row">
                  <div className="text-center botco-fluid">
                    <Button
                      className="botco-outline-button"
                      onClick={() => this.closeCreateConvModal()}
                    >
                      CANCEL
                    </Button>
                    <Button
                      className="botco-red-outline-button"
                      type="submit"
                      disabled={!this.validateForm()}
                    >
                      SAVE
                    </Button>
                    {this.state.showSpinner ||
                    this.props.showSpinner ||
                    this.props.loader ? (
                      <img alt="spinner" src={spinner} />
                    ) : null}
                  </div>
                </div>
                {this.state.titleError && (
                  <div className="row">
                    <div className="col-md-12 botco-error-label text-center">
                      Please give a title to this conversation!
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
ModalSummary.propTypes = {
  fullScreen: PropTypes.bool,
};

const mapActionsToProps = {
  getAllAgents,
};

const connector = connect(null, mapActionsToProps);

// eslint-disable-next-line import/no-default-export
export default connector(withRouter(ModalSummary));
