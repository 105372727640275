export const CUSTOM_ATTRIBUTE = 'Custom Attribute' as const;
export const DEFAULT_ATTRIBUTE = 'Default Attribute' as const;

export const defaultAttributes = [
  'first_name',
  'last_name',
  'email',
  'phone',
  'sms_number',
  'location',
  'rating',
];

export const reservedAttributes = [
  'and',
  'block',
  'cycle',
  'elif',
  'else',
  'endblock',
  'endfilter',
  'endfor',
  'endif',
  'endmacro',
  'endraw',
  'endtrans',
  'extends',
  'filter',
  'for',
  'if',
  'in',
  'include',
  'is',
  'macro',
  'not',
  'or',
  'pluralize',
  'print',
  'raw',
  'recursive',
  'set',
  'trans',
  'call +',
  'endcall +',
];
