import {
  RESET_REDUX_STORE,
  LEAVE_PAGE_TRUE,
} from '../types/conversationPreview';

export const leavePageTrue = () => {
  return {
    type: LEAVE_PAGE_TRUE,
    payload: true,
  };
};

export function resetReduxStore() {
  return {
    type: RESET_REDUX_STORE,
  };
}
