export const getToken = () => {
  const token = localStorage.getItem('token');
  if (!token) localStorage.removeItem('token');
  return token;
};

const logoutFacebook = () => {
  localStorage.removeItem('fb_access_token');
  localStorage.removeItem('fb_uid');

  const FB = window.FB;
  if (!FB) return;

  FB.getLoginStatus((response) => {
    if (response && response.status === 'connected') {
      FB.logout();
    }
  });
};
export const removeTokens = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshtoken');

  logoutFacebook();
};

export const getFacebookToken = () => {
  const token = localStorage.getItem('fb_access_token');
  return token;
};

export const getFacebookUid = () => {
  const token = localStorage.getItem('fb_uid');
  return token;
};

export const removeFacebookToken = () => {
  localStorage.removeItem('fb_access_token');
  localStorage.removeItem('fb_uid');
};
