import './index.css';

import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import { ThemeProvider, StylesProvider, CssBaseline } from '@material-ui/core';
import LogRocket from 'logrocket';
import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { theme } from '@botco/library';

import { App } from './App';
import { constants } from './config';
import { LOG_ROCKET_KEY } from './constants';
import { store } from './redux/store';

const environment = constants.app_env || 'dev';

if (environment === 'production') {
  LogRocket.init(LOG_ROCKET_KEY);

  LogRocket.getSessionURL((sessionURL) => {
    Honeybadger.beforeNotify((notice) => {
      if (!notice) return;
      notice.context.LogRocketSessionURL = sessionURL;
    });
  });
}

if (import.meta.env.VITE_ENABLE_MSW_WORKER === 'true') {
  // code splitting
  import('./mocks/worker').then(({ worker }) => {
    // eslint-disable-next-line no-console
    console.info('starting worker');
    worker.start({ waitUntilReady: true });
  });
}

const ErrorBoundary: React.FC = ({ children }) => {
  if (import.meta.env.DEV) {
    return <>{children}</>;
  }

  const honeybadger = Honeybadger.configure({
    apiKey: '82819390',
    revision: import.meta.env.VITE_HONEYBADGER_REVISION || 'main',
    environment,
  });

  return (
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      {children}
    </HoneybadgerErrorBoundary>
  );
};

render(
  <ErrorBoundary>
    <Provider store={store}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  </ErrorBoundary>,

  document.getElementById('root')
);
