import { useSelector } from 'react-redux';

import { selectUserDetails } from '~/redux/selectors/conversationsDataR';
import { RoleEnum, User } from '~/utils/http/user/types';

type BooleanRoles = {
  isAdmin: boolean;
  isBotAuthor: boolean;
  hasPIIRole: boolean;
  hasDeployRole: boolean;
  hasInstaStackRole: boolean;
};

const defaultRoles: BooleanRoles = {
  isAdmin: false,
  isBotAuthor: false,
  hasPIIRole: false,
  hasDeployRole: false,
  hasInstaStackRole: false,
};

type ReturnType = User & BooleanRoles;

export const useCurrentUser = (): ReturnType => {
  const user: User = useSelector(selectUserDetails);

  const roles = user?.roles?.reduce(
    (acc, curr) => ({
      ...acc,
      ...(curr.role_id === RoleEnum.Administrator && { isAdmin: true }),
      ...(curr.role_id === RoleEnum.BotAuthor && { isBotAuthor: true }),
      ...(curr.role_id === RoleEnum.BotPii && { hasPIIRole: true }),
      ...(curr.role_id === RoleEnum.Deploy && { hasDeployRole: true }),
      ...(curr.role_id === RoleEnum.InstaStack && { hasInstaStackRole: true }),
    }),
    defaultRoles
  );

  return {
    ...user,
    ...roles,
  };
};
