import { FormGroup, makeStyles } from '@material-ui/core';
import { FieldErrors } from 'react-hook-form';

import { Select } from '@botco/library';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { AttributeConditionsOptions } from '~/types/query-service-helpers';
import * as yup from '~/utils/yup';

import { AttributesInput } from '../AttributesInput';
import { AttributeValuesInput } from '../AttributeValuesInput';

const Schema = yup.attribute();

type ValueType = yup.InferType<typeof Schema>;

type Props = {
  value: ValueType;
  errors?: FieldErrors<ValueType>;
  onChange: (value: ValueType) => void;
};

const useStyles = makeStyles((theme) => ({
  formGroup: {
    flexDirection: 'row',
    width: '100%',

    '& div:last-child': {
      flex: 1,
    },
  },
  attribute: {
    '& .MuiAutocomplete-root': {
      width: theme.spacing(26),
      flex: 1,
      display: 'flex',
      '& .MuiInputBase-root': {
        borderRadius: theme.spacing(1, 0, 0, 1),
      },
    },
  },
  attributeValue: {
    width: '100%',
    borderRadius: theme.spacing(0, 1, 1, 0),

    '& .MuiAutocomplete-root': {
      width: '100%',

      '& .MuiInputBase-root': {
        borderRadius: theme.spacing(0, 1, 1, 0),
      },
    },
  },
  attributeOperator: {
    width: theme.spacing(20),
    borderRadius: 0,
  },
}));

export const AttributeFilter = ({ errors, onChange, value }: Props) => {
  const classes = useStyles();
  const { selectedAgent } = useSelectedChatbot();

  return (
    <FormGroup className={classes.formGroup}>
      <AttributesInput
        agentId={selectedAgent?.id!}
        disableCreate
        className={classes.attribute}
        showError={Boolean(errors?.attribute)}
        helperText={errors?.value?.message}
        value={value?.attribute ?? {}}
        placeholder="Select Attribute"
        onChange={(attribute) =>
          onChange({
            ...value,
            attribute: attribute as ValueType['attribute'],
            value: '',
          })
        }
      />
      <Select
        id="attributeOperator"
        current={value?.operator ?? 'eq'}
        onChange={(operator) =>
          onChange({ ...value, operator: operator as ValueType['operator'] })
        }
        error={Boolean(errors?.operator?.message)}
        helperText={errors?.operator?.message}
        placeholder="Operator"
        className={classes.attributeOperator}
        options={AttributeConditionsOptions}
      />
      <AttributeValuesInput
        placeholder="Value"
        agentId={selectedAgent?.id!}
        attributeId={value?.attribute?.cust_attr_id ?? 0}
        disabled={!value?.attribute}
        value={value?.value ?? ''}
        operator={value?.operator ?? 'eq'}
        error={Boolean(errors?.value)}
        helperText={errors?.value?.message}
        className={classes.attributeValue}
        onChange={(atrValue) => onChange({ ...value, value: atrValue })}
      />
    </FormGroup>
  );
};
