import * as React from 'react';

import type { ICreatableDropDownOption } from '@botco/library';
import { CUSTOM_ATTRIBUTE, DEFAULT_ATTRIBUTE } from '~/constants/attributes';
import { useAttributeContext } from '~/hooks/useAttributeContext';
import {
  isReservedAttributeName,
  transformAttributes,
} from '~/utils/attributeUtils';

import { AttributesListProps } from './AttributeList.types';

const filterByText = (text?: string) => (option: ICreatableDropDownOption) => {
  if (!text) return true;
  return option.label.toLowerCase().startsWith(text.toLowerCase());
};

type Options = Pick<
  AttributesListProps,
  | 'anchorEl'
  | 'agentId'
  | 'filter'
  | 'onClose'
  | 'onSelect'
  | 'disableCreate'
  | 'additionalAttributes'
>;

export const useAttributeList = ({
  anchorEl,
  filter,
  onClose,
  onSelect,
  disableCreate,
  additionalAttributes,
}: Options) => {
  const [hoveredIndex, setHoveredIndex] = React.useState(0);

  const isReserved = React.useMemo(
    () => isReservedAttributeName(filter),
    [filter]
  );

  const isOpen = Boolean(anchorEl);
  const { attributes, createAttribute } = useAttributeContext();

  const hasFilterAttribute = React.useCallback(
    (opt: ICreatableDropDownOption) => filterByText(filter)(opt),
    [filter]
  );

  const indexOffset = React.useMemo(() => {
    if (filter && !disableCreate) {
      const exists = attributes.find(
        ({ cust_attr_name }) => cust_attr_name === filter
      );
      return exists ? 0 : 1;
    }
    return 0;
  }, [filter, attributes, disableCreate]);

  const options = React.useMemo(
    () => transformAttributes(attributes, { additionalAttributes }),
    [attributes, additionalAttributes]
  );

  const defaultAttributes = React.useMemo(
    () =>
      options.filter(
        (attribute) =>
          attribute.group === DEFAULT_ATTRIBUTE && hasFilterAttribute(attribute)
      ),
    [options, hasFilterAttribute]
  );

  const customAttributes = React.useMemo(
    () =>
      options.filter(
        (attribute) =>
          attribute.group === CUSTOM_ATTRIBUTE && hasFilterAttribute(attribute)
      ),
    [options, hasFilterAttribute]
  );

  const handleSelect = React.useCallback(
    (option: ICreatableDropDownOption) =>
      async (event?: React.MouseEvent<HTMLLIElement>) => {
        if (isReservedAttributeName(option.label)) return;
        if (event) {
          event.preventDefault();
        }
        onClose?.();
        setHoveredIndex(0);

        const attribute = attributes.find(
          ({ cust_attr_id }) => `${cust_attr_id}` === option.value
        );

        if (!attribute) {
          const newAttribute = await createAttribute(option.label);
          onSelect?.(newAttribute);
          return;
        }

        onSelect?.(attribute);
      },
    [attributes, createAttribute, onClose, onSelect]
  );

  const maxLength = defaultAttributes.length + customAttributes.length;

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isOpen) return;

      if (event.key === 'Enter') {
        event.preventDefault();
        if (hoveredIndex === 0 && indexOffset === 1) {
          handleSelect({
            label: filter!,
          })();
          return;
        }

        const option = [...defaultAttributes, ...customAttributes]?.[
          hoveredIndex - indexOffset
        ];
        if (!option) return;
        handleSelect(option)();
      }

      if (!['ArrowUp', 'ArrowDown'].includes(event.key)) return;
      event.preventDefault();
      event.stopPropagation();

      setHoveredIndex((hovered) => {
        if (event.key === 'ArrowUp') {
          return hovered === 0 ? maxLength - 1 + indexOffset : hovered - 1;
        }
        return hovered === maxLength - 1 + indexOffset ? 0 : hovered + 1;
      });
    };

    document.body.addEventListener('keydown', handleKeyDown);
    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    maxLength,
    isOpen,
    hoveredIndex,
    defaultAttributes,
    customAttributes,
    handleSelect,
    filter,
    indexOffset,
  ]);

  React.useEffect(() => {
    const timeout = window.setTimeout(() => {
      const element = document.querySelector(`[data-index="${hoveredIndex}"]`);
      if (!element) return;
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    });
    return () => {
      window.clearTimeout(timeout);
    };
  }, [hoveredIndex]);

  const handleClose = () => {
    setHoveredIndex(0);
    onClose?.();
  };

  React.useEffect(() => {
    if (!isOpen) return;
    setHoveredIndex(0);
  }, [filter, isOpen]);

  return {
    isOpen: isOpen,
    onCloseMenu: handleClose,
    indexOffset,
    hoveredIndex,
    defaultAttributes,
    customAttributes,
    onSelectItem: handleSelect,
    isReserved,
  };
};
