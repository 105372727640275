// referrals?deploy_id=1533

import { ReferralCode } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  params: {
    deploy_id: number;
  };
};

export const getReferrals = async ({ params }: Options) => {
  return axiosClient.get<ReferralCode[]>(`/referrals`, {
    params,
  });
};
