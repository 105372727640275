import { MatchResult, match as matchFn } from 'path-to-regexp';

import { BotcoRoute, routes } from '~/constants/routes';

export const pathMatching = (
  path: string,
  pathToMatch = window.location.pathname
) => {
  if (path === '/*' || path === '/**' || path === pathToMatch) {
    return true;
  }

  // remove trailing slash
  if (pathToMatch.endsWith('/')) {
    pathToMatch = pathToMatch.slice(0, -1);
  }

  const pathToMatchParts = pathToMatch.split('/');
  const pathParts = path.split('/');

  for (let i = 0; i < pathParts.length; i++) {
    if (pathParts[i] === '**') {
      return true;
    }

    if (pathParts[i] === '*' || pathParts[i].startsWith(':')) {
      continue;
    }

    if (pathParts[i] !== pathToMatchParts[i]) {
      return false;
    }
  }

  return pathParts.length === pathToMatchParts.length;
};

type CurrentRouteRecordType = {
  route: BotcoRoute<any> | null;
  match: MatchResult | null;
};

export const getCurrentRouteRecord = (
  pathname: string
): CurrentRouteRecordType => {
  const { route, match } = Object.values(routes).reduce(
    (acc, curr) => {
      const matchResult = matchFn(curr.path, { decode: decodeURIComponent })(
        pathname
      );

      return matchResult ? { route: curr, match: matchResult } : acc;
    },
    { route: null, match: null } as CurrentRouteRecordType
  );

  return { route, match };
};
