import { MenuItem, MenuItemProps } from '@material-ui/core';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { updateAgent } from '~/redux/actions/conversationDataR';
import { handleErrors, showToast } from '~/utils';

import { AgentType } from '../../../types';

type PropsType = MenuItemProps<
  'li',
  { agent: AgentType; handleClosePopper: () => void }
>;

export const ConvertToSkillMenuItem = (props: PropsType) => {
  const { agent, handleClosePopper, ...menuItemProps } = props;
  const dispatch = useDispatch();

  const handleConvertToTemplate = async () => {
    try {
      await dispatch(
        updateAgent({
          ...agent,
          is_template: true,
        })
      );
      showToast('success', 'Chatbot successfully converted into template');
    } catch (error) {
      handleErrors(
        error,
        'There was a failure while converting the chatbot into template'
      );
    }

    handleClosePopper();
  };

  return (
    <MenuItem {...menuItemProps} button onClick={handleConvertToTemplate}>
      Convert to skill
    </MenuItem>
  );
};
