import { AttributeChip } from './AttributeChip';
import { AttributeFilter } from './AttributeFilter';
import { AttributeProvider } from './AttributeProvider';
import { AttributesInput } from './AttributesInput';
import { AttributeTextEditor } from './AttributeTextEditor';
import { AttributeValuesInput } from './AttributeValuesInput';

export namespace Attributes {
  export const Chip = AttributeChip;
  export const Filter = AttributeFilter;
  export const TextEditor = AttributeTextEditor;
  export const Input = AttributesInput;
  export const ValuesInput = AttributeValuesInput;

  export const Provider = AttributeProvider;
}
