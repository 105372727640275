import { LinkTypes } from '~/constants';

import { BLOCK } from '../../containers/Conversations/NewForm/constants';

export const selectFormEdited = ({ form }) => form.edited;
export const selectFormHasError = ({ form }) => form.hasError;
export const selectFormQuestions = ({ form }) => form.questions;
export const selectFormIntents = ({ form }) => form.intents;
export const selectFormBranches = ({ form }) => form.branches;
export const selectNonDeletedFormBranches = ({ form }) =>
  form.branches.filter((branch) => !branch.deleted);
export const selectSelectedBranch = ({ form }) => form.selectedBranch;
export const selectCurrentBranch = ({ form }) =>
  form.branches.find((branch) => branch.label === form.selectedBranch);

export const selectFormBasics = ({ form }) => ({
  id: form.id,
  name: form.name,
});

export const selectObjectedEntities = ({ form }) => {
  return form.entities.reduce((acc, entity) => {
    acc[entity.index] = entity;
    return acc;
  }, {});
};

export const selectObjectedAttributes = ({ form }) => {
  return form.attributes.reduce((acc, reference) => {
    acc[reference.index] = reference;
    return acc;
  }, {});
};

const selectObjectedIntents = ({ form }) => {
  return form.intents.reduce((acc, intent) => {
    acc[intent.intent_id] = intent;
    return acc;
  }, {});
};

export const selectCurrentBranchQuestions = ({ form }) => {
  return form.questions.filter(
    (question) => question.branch === form.selectedBranch && !question.deleted
  );
};

export const selectInBlocks = ({ form }) => {
  const inBlocks =
    form.connections.sourceIntents
      ?.map((source) => ({
        id: source.id,
        name: source.intent_name,
        type: LinkTypes.BLOCK,
      }))
      .filter((block) => block.name) || [];

  const inForms =
    form.connections.sourceForms?.map((form) => ({
      id: form.form_id,
      name: form.form_name,
      type: LinkTypes.FORM,
    })) || [];

  return [...inForms, ...inBlocks].map((block) => ({
    ...block,
    key: [block.key, block.id].join('-'),
  }));
};

export const selectOutBlocks = ({ form }) => {
  const outBlocks = [];
  const uniqueBlocks = new Set();
  const objectedIntents = selectObjectedIntents({ form });
  const formBranches = selectFormBranches({ form });

  formBranches
    .filter(
      (branch) =>
        !branch.deleted &&
        branch.last_question_block &&
        objectedIntents[branch.last_question_block]
    )
    .forEach((branch) => {
      const intentId = branch.last_question_block;
      outBlocks.push({
        id: intentId,
        name: objectedIntents[intentId].name,
        type: LinkTypes.BLOCK,
        key: [LinkTypes.BLOCK, intentId].join('-'),
      });
    });

  form.questions
    .filter((question) => {
      if (question.deleted) return false;
      const formBranch = formBranches.find(
        (branch) => branch.label === question.branch
      );
      if (!formBranch || formBranch.deleted) return false;
      return true;
    })
    .forEach((question) => {
      (question.customResponses || [])
        .filter((customResponse) => !customResponse.deleted)
        .forEach((customResponse) => {
          if (
            customResponse.type !== BLOCK ||
            !customResponse.link ||
            !customResponse.link.intent_id
          )
            return;

          const intentId = customResponse.link.intent_id;
          outBlocks.push({
            id: intentId,
            name: objectedIntents[intentId].name,
            type: LinkTypes.BLOCK,
            key: [LinkTypes.BLOCK, intentId].join('-'),
          });
        });
    });

  return outBlocks.filter((block) => {
    if (!block || !block.name) return false;
    if (uniqueBlocks.has(block.key)) return false;
    uniqueBlocks.add(block.key);
    return true;
  });
};
