import { makeStyles } from '@material-ui/core';

export const useHiddenMetricsMenuStyles = makeStyles((theme) => ({
  infoIcon: {
    color: theme.palette.primary.main,

    '& svg': {
      width: 20,
      height: 20,
    },
  },
  menuHeader: {
    width: '100%',
    padding: '0px 20px',
    color: (theme.palette as any).purple[900],
  },
  searchWrapper: {
    width: '100%',
    padding: '8px 16px 24px',
  },
  searchInput: {
    height: 35,
  },
  menuItem: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: (theme.palette as any).primary[300],
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),
    },
  },
  truncate: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  submenu: {
    paddingLeft: 20,
  },
  submenuItem: {
    borderLeft: `1px solid ${(theme.palette as any).purple[600]}`,
  },
  expandIcon: {
    color: theme.palette.grey[900],
    width: 16,
    height: 16,
  },
  fullWidth: {
    width: '100%',
  },
}));
