import { axiosClient } from '../axiosClient';
import { DataSubmitMapping } from '../deployment/types';

type Params = {
  agentId: number;
  deployId?: number;
};

export const getTargetConfigurations = async ({
  agentId,
  deployId,
}: Params) => {
  return axiosClient.get<DataSubmitMapping[]>(`/target-configuration`, {
    params: {
      agent_id: agentId,
      deploy_id: deployId,
    },
  });
};
