import { Chip, ChipProps } from '@botco/library';
import { defaultAttributes } from '~/constants/attributes';

type PropsType = Pick<
  ChipProps,
  | 'deletable'
  | 'onDelete'
  | 'className'
  | 'dataTestId'
  | 'selected'
  | 'size'
  | 'onClick'
> & {
  attribute: string;
};

export const AttributeChip = ({
  attribute,
  size = 'small',
  ...props
}: PropsType) => {
  const isADefaultAttribute = defaultAttributes.includes(attribute);

  return (
    <Chip
      {...props}
      size={size}
      color={isADefaultAttribute ? 'secondary' : 'default'}
    >
      {attribute}
    </Chip>
  );
};
