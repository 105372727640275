import * as yup from 'yup';

import { ApiJsonTypesEnum } from '../../../constants/options';

export const JSONTemplateFormSchema = yup.object({
  type: yup
    .mixed<ApiJsonTypesEnum>()
    .oneOf(
      Object.values(ApiJsonTypesEnum).filter(
        (v) => v !== ApiJsonTypesEnum.None
      ),
      'JSON Type is required'
    )
    .default(ApiJsonTypesEnum.None)
    .required(),
  template: yup.string().required(),
});

export type JSONTemplateFormSchema = yup.InferType<
  typeof JSONTemplateFormSchema
>;
