import { Entity } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  agentId: number;
};

export const getAgentEntities = ({ agentId }: Options) => {
  return axiosClient.get<Entity[]>(`/agents/${agentId}/entities`);
};
