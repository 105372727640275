import type { AgentApis } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  agentId: number;
  apiId: number;
  params?: {
    generate_token: boolean;
  };
};

export const getAgentApiDetails = ({ agentId, apiId, params }: Options) => {
  return axiosClient.get<AgentApis>(`/agents/${agentId}/apis/${apiId}`, {
    params,
  });
};
