import { Box, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { Card, Button } from '@botco/library';
import { selectUserDetails } from '~/redux/selectors/conversationsDataR';

import ModalSummary from '../../ModalSummary/ModalSummary';

interface PropsType {
  className?: string;
}

export const AgentsListCreateCard = ({ className }: PropsType) => {
  const theme = useTheme();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const userDetails = useSelector(selectUserDetails);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <Card height={250} className={className}>
        <AddIcon
          style={{
            fontSize: '72px',
            margin: '20px 0',
            color: theme.palette.primary.main,
          }}
        />
        <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
          <Button
            fullWidth
            variant="outlined"
            onClick={handleOpenModal}
            data-testid="create-new-agent-button"
          >
            CREATE NEW
          </Button>
        </Box>
      </Card>
      {isModalOpen && (
        <ModalSummary
          avatar={userDetails.user_avatar}
          modalOpen={isModalOpen}
          closed={handleCloseModal}
        />
      )}
    </>
  );
};
