import findLast from 'lodash.findlast';
import { customAlphabet } from 'nanoid';
import { handleActions } from 'redux-actions';

import {
  FORM_BRANCH,
  BLOCK,
} from '~/containers/Conversations/NewForm/constants';

import {
  selectObjectedAttributes,
  selectObjectedEntities,
} from '../selectors/form';
import { CUSTOM_ENTITY } from '../types/entities';
import * as constants from '../types/form';


export const mainBranch = {
  index: 1,
  label: 'Main Branch',
  last_question_type: null,
  last_question_block: null,
  last_question_branch: null,
};

export const initialState = {
  status: 'INIT',
  id: null,
  name: '',
  questions: [],
  entities: [],
  attributes: [],
  intents: [],
  connections: {
    sourceIntents: [],
    sourceForms: [],
  },
  edited: false,
  branches: [mainBranch],
  selectedBranch: mainBranch.label,
  error: null,
  hasError: false,
  updated: false,
};

const getQuestionName = () => {
  const nanoid = customAlphabet(
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
    10
  );
  return `q${nanoid()}q`; // name should start & end with a letter
};

const validateForm = (form) => {
  let { branches, questions } = form;
  const entities = selectObjectedEntities({ form });
  const attributes = selectObjectedAttributes({ form });

  questions = questions.map((question) => {
    const { deleted, entityIndex, attributeIndex } = question;
    if (deleted) return { ...question, hasError: false };

    if (
      typeof entityIndex === 'undefined' ||
      typeof attributeIndex === 'undefined'
    ) {
      return { ...question, hasError: true };
    }

    const selectedEntity = entities[entityIndex];
    const selectedAttribute = attributes[attributeIndex];

    const invalidCustomResponse = question.customResponses?.find(
      (customResponse) => {
        if (customResponse.deleted) return false;
        if (!customResponse.name) return true;
        if (!customResponse.type && !question.allButtonsNoLink) return true;
        if (customResponse.type === BLOCK && !customResponse.link) return true;
        if (customResponse.type === FORM_BRANCH && !customResponse.link)
          return true;
        return false;
      }
    );

    const hasError = Boolean(
      !selectedEntity || !selectedAttribute || invalidCustomResponse
    );

    return {
      ...question,
      hasError,
    };
  });

  branches = branches.map((branch) => {
    if (branch.deleted) return { ...branch, hasError: false };
    const liveQuestions = questions.filter(
      (question) => question.branch === branch.label && !question.deleted
    );
    const invalidQuestion = liveQuestions.find((question) => question.hasError);
    // prettier-ignore
    const lastQuestion = liveQuestions.length ? liveQuestions[liveQuestions.length - 1] : null;

    const allButtonsNoLink = lastQuestion?.allButtonsNoLink;
    const noButtons = !(lastQuestion?.customResponses || []).length;
    const needsContineTo = allButtonsNoLink || noButtons;

    if (!needsContineTo && !branch.last_question_type) {
      branch.last_question_type = null;
      branch.last_question_block = null;
      branch.last_question_branch = null;
      branch.updated = true;
    }

    const hasError =
      (needsContineTo &&
        !branch.last_question_block &&
        typeof branch.last_question_branch !== 'number') ||
      !!invalidQuestion ||
      !liveQuestions.length;

    return {
      ...branch,
      hasError,
      last_question_type: lastQuestion ? branch.last_question_type : null,
      last_question_block: lastQuestion ? branch.last_question_block : null,
      last_question_branch: lastQuestion ? branch.last_question_branch : null,
      errorText: !liveQuestions.length ? 'Needs at least one question.' : null,
    };
  });

  const hasError = Boolean(branches.find((branch) => branch.hasError));

  return {
    ...form,
    branches,
    questions,
    hasError,
  };
};

export const formReducer = handleActions(
  {
    [constants.INIT_FORM]: () => ({
      ...initialState,
      edited: false,
    }),
    [constants.UPDATE_FORM]: (
      state,
      { payload: { id, name, branches, questions } }
    ) => ({
      ...state,
      id,
      name,
      branches,
      questions,
      edited: false,
    }),
    [constants.UPDATE_FORM_BASICS]: (state, { payload: { id, name } }) => ({
      ...state,
      id,
      name,
      edited: false,
    }),
    [constants.ADD_FORM_QUESTION]: (state, { payload }) => {
      return validateForm({
        ...state,
        questions: [
          ...state.questions,
          {
            ...payload,
            name: getQuestionName(),
            index: state.questions.length,
          },
        ],
        edited: true,
      });
    },
    [constants.UPDATE_FORM_QUESTION_PROMPT]: (
      state,
      { payload: { prompt, questionIndex } }
    ) =>
      validateForm({
        ...state,
        questions: state.questions.map((question) =>
          question.index === questionIndex
            ? { ...question, prompt, updated: true }
            : question
        ),
        edited: true,
      }),
    [constants.UPDATE_FORM_QUESTION_FALLBACK_PROMPT]: (
      state,
      { payload: { fallback_prompt, questionIndex } }
    ) =>
      validateForm({
        ...state,
        questions: state.questions.map((question) =>
          question.index === questionIndex
            ? { ...question, fallback_prompt, updated: true }
            : question
        ),
        edited: true,
      }),
    [constants.INIT_FORM_ENTITIES]: (state, { payload }) => ({
      ...state,
      entities: payload,
    }),
    [constants.INIT_FORM_INTENTS]: (state, { payload }) => ({
      ...state,
      intents: payload,
    }),
    [constants.INIT_FORM_ATTRIBUTES]: (state, { payload }) => ({
      ...state,
      attributes: payload,
    }),
    [constants.INIT_FORM_CONNECTIONS]: (state, { payload }) => ({
      ...state,
      connections: payload,
    }),
    [constants.ADD_CUSTOM_ENTITY]: (
      state,
      { payload: { entity, questionIndex } }
    ) => {
      const index = state.questions.findIndex(
        (question) => question.index === questionIndex
      );
      return validateForm({
        ...state,
        entities: [
          ...state.entities,
          {
            index: state.entities.length,
            name: entity,
            label: entity,
            entity_id: null,
            entity_type: CUSTOM_ENTITY,
          },
        ],
        questions: [
          ...state.questions.slice(0, index),
          {
            ...state.questions[index],
            customResponses: [
              ...(state.questions[index]?.customResponses?.map((item) => ({
                ...item,
                updated: true,
              })) ?? []),
            ],
            entityIndex: state.entities.length,
            updated: true,
            name: getQuestionName(),
          },
          ...state.questions.slice(index + 1),
        ],
        edited: true,
      });
    },
    [constants.ADD_ATTRIBUTE]: (
      state,
      { payload: { attribute, questionIndex } }
    ) => {
      const index = state.questions.findIndex(
        (question) => question.index === questionIndex
      );
      return validateForm({
        ...state,
        attributes: [
          ...state.attributes,
          {
            index: state.attributes.length + 1,
            name: attribute,
            label: attribute,
            attr_id: null,
            attr_type: 2,
          },
        ],
        questions: [
          ...state.questions.slice(0, index),
          {
            ...state.questions[index],
            attributeIndex: state.attributes.length + 1,
            updated: true,
          },
          ...state.questions.slice(index + 1),
        ],
        edited: true,
      });
    },
    [constants.SET_ENTITY_VALUE]: (
      state,
      { payload: { questionIndex, entityIndex } }
    ) => {
      const index = state.questions.findIndex(
        (question) => question.index === questionIndex
      );
      return validateForm({
        ...state,
        questions: [
          ...state.questions.slice(0, index),
          {
            ...state.questions[index],
            entityIndex,
            updated: true,
          },
          ...state.questions.slice(index + 1),
        ],
        edited: true,
      });
    },
    [constants.SET_ATTRIBUTE_VALUE]: (
      state,
      { payload: { questionIndex, attributeIndex } }
    ) => {
      const index = state.questions.findIndex(
        (question) => question.index === questionIndex
      );
      return validateForm({
        ...state,
        questions: [
          ...state.questions.slice(0, index),
          {
            ...state.questions[index],
            attributeIndex,
            updated: true,
          },
          ...state.questions.slice(index + 1),
        ],
        edited: true,
      });
    },
    [constants.DELETE_FORM_QUESTION]: (state, { payload: questionIndex }) => {
      const index = state.questions.findIndex(
        (question) => question.index === questionIndex
      );
      return validateForm({
        ...state,
        questions: [
          ...state.questions.slice(0, index),
          {
            ...state.questions[index],
            deleted: true,
          },
          ...state.questions.slice(index + 1),
        ],
        edited: true,
      });
    },
    [constants.FORM_QUESTION_MOVE_DOWN]: (
      state,
      { payload: questionIndex }
    ) => {
      const currentIndex = state.questions.findIndex(
        (question) => question.index === questionIndex
      );
      if (currentIndex < 0) return state;
      const currentQuestion = state.questions[currentIndex];
      const nextQuestion = state.questions.find(
        (question, nextIndex) =>
          question.branch === currentQuestion.branch &&
          !question.deleted &&
          nextIndex > currentIndex
      );
      if (!nextQuestion) return state;

      return validateForm({
        ...state,
        questions: state.questions.map((each) => {
          if (each === currentQuestion) {
            return { ...nextQuestion, updated: true };
          } else if (each === nextQuestion) {
            return { ...currentQuestion, updated: true };
          }
          return each;
        }),
        edited: true,
      });
    },
    [constants.FORM_QUESTION_MOVE_UP]: (state, { payload: questionIndex }) => {
      const currentIndex = state.questions.findIndex(
        (question) => question.index === questionIndex
      );
      if (currentIndex < 0) return state;
      const currentQuestion = state.questions[currentIndex];
      const previousQuestion = findLast(
        state.questions,
        (question, previousIndex) =>
          question.branch === currentQuestion.branch &&
          !question.deleted &&
          previousIndex < currentIndex
      );
      if (!previousQuestion) return state;

      return validateForm({
        ...state,
        questions: state.questions.map((each) => {
          if (each === currentQuestion) {
            return { ...previousQuestion, updated: true };
          } else if (each === previousQuestion) {
            return { ...currentQuestion, updated: true };
          }
          return each;
        }),
        edited: true,
      });
    },
    [constants.UPDATE_QUESTION_RESPONSES]: (
      state,
      { payload: { question, customResponses } }
    ) => {
      const questionIndex = state.questions.findIndex(
        (each) => each.index === question.index
      );
      if (questionIndex < 0) return state;

      return validateForm({
        ...state,
        questions: state.questions.map((q, i) =>
          questionIndex === i ? { ...question, customResponses } : q
        ),
        edited: true,
      });
    },
    [constants.UPDATE_QUESTION_SINGLE_RESPONSE]: (
      state,
      { payload: { question, response, responseIndex } }
    ) => {
      const currentQuestion = state.questions.find(
        (each) => each.index === question.index
      );
      if (!currentQuestion) return state;

      const updatedQuestion = {
        ...question,
        customResponses: currentQuestion.customResponses.map((cr, crI) =>
          crI === responseIndex ? response : cr
        ),
      };

      return validateForm({
        ...state,
        questions: state.questions.map((q, i) =>
          currentQuestion.index === i ? updatedQuestion : q
        ),
        edited: true,
      });
    },
    [constants.ADD_FORM_BRANCH]: (state, { payload: branch }) =>
      validateForm({
        ...state,
        branches: [
          ...state.branches,
          {
            index: state.branches.length,
            label: branch,
            value: branch,
            last_question_type: null,
            last_question_block: null,
            last_question_branch: null,
          },
        ],
        edited: true,
      }),
    [constants.CHANGE_FORM_BRANCH_LAST_QUESTION_RESPONSE]: (
      state,
      {
        payload: {
          last_question_type,
          last_question_block,
          last_question_branch,
        },
      }
    ) =>
      validateForm({
        ...state,
        branches: state.branches.map((branch) => {
          if (branch.label === state.selectedBranch) {
            return {
              ...branch,
              last_question_type,
              last_question_block,
              last_question_branch,
              updated: true,
            };
          }
          return branch;
        }),
        edited: true,
      }),
    [constants.DELETE_FORM_BRANCH]: (state, { payload: branchLabel }) => {
      const branches = state.branches;

      return validateForm({
        ...state,
        branches: branches.map((branch) => {
          if (branch.label !== branchLabel) return branch;
          return { ...branch, deleted: true };
        }),
        selectedBranch:
          branchLabel === state.selectedBranch
            ? (branches[0] || mainBranch).label
            : state.selectedBranch,
        edited: true,
      });
    },
    [constants.ADD_FORM_INTENT]: (state, { payload: intent }) =>
      validateForm({
        ...state,
        intents: [...state.intents, intent],
      }),
    [constants.SELECT_ACTIVE_BRANCH]: (state, { payload: branch }) =>
      validateForm({
        ...state,
        selectedBranch: branch,
      }),
  },
  initialState
);
