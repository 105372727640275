import type { AxiosResponse } from 'axios';

import { Intent } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  agentId: number;
  intent: Pick<
    Intent,
    | 'id'
    | 'category_id'
    | 'intent_name'
    | 'intent_context_ins'
    | 'intent_context_outs'
  >;
};

export const updateAgentIntent = ({
  agentId,
  intent,
}: Options): Promise<AxiosResponse<Intent>> => {
  return axiosClient.put(`/agents/${agentId}/intents/${intent.id}`, intent);
};
