import * as React from 'react';

import { AttributeContext } from '~/components/Attributes/AttributeProvider';

export const useAttributeContext = () => {
  const context = React.useContext(AttributeContext);

  if (!context) {
    throw new Error(
      'useAttributeContext must be used within a AttributeProvider'
    );
  }

  return context;
};
