import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Button, Card, TextInput, Typography } from '@botco/library';
import botcoLogo from '~/assets/img/botco.png';
import { getToken } from '~/utils';

import * as S from './Login.styles';
import { LoginSchema, LoginSchemaType } from './Login.types';
import { useLogin } from './useLogin';
import { Footer } from '../Conversations/Footer';


export const Login = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = React.useState(false);

  const { error, isLoading, getRedirectUrl, onSubmit } = useLogin();

  const formMethods = useForm<LoginSchemaType>({
    resolver: yupResolver(LoginSchema),
  });

  React.useEffect(() => {
    const token = getToken();
    if (token) {
      history.push(getRedirectUrl(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowPassword = () => {
    setShowPassword((curr) => !curr);
  };

  return (
    <S.Container maxWidth="md">
      <div>
        <img src={botcoLogo} alt="logo" height={50} />
      </div>
      <S.FormContainer>
        <S.FormWrapper>
          <Typography variant="h3" align="center" color="primary">
            Hello, Welcome to Botco.ai
          </Typography>
          <Box p="10px" mb={3}>
            <Typography variant="h4" align="center">
              Sign in to begin creating a bot using Botco.ai.
            </Typography>
          </Box>
          <Card>
            <S.Form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Typography variant="h3" align="center" color="primary">
                SIGN IN
              </Typography>
              <Controller
                control={formMethods.control}
                name="email"
                render={({ field, fieldState }) => (
                  <TextInput
                    label="Email"
                    fullWidth
                    name={field.name ?? ''}
                    onChange={field.onChange}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                    placeholder="you@yourcompany.com"
                    inputProps={{ autoFocus: true }}
                  />
                )}
              />
              <Controller
                control={formMethods.control}
                name="password"
                render={({ field, fieldState }) => (
                  <TextInput
                    fullWidth
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    name={field.name ?? ''}
                    onChange={field.onChange}
                    endIcon={
                      <IconButton size="small" onClick={handleShowPassword}>
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    }
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                    placeholder="Enter password"
                  />
                )}
              />
              {error && <Typography color="error">{error}</Typography>}
              <Box mt={1}>
                <Button type="submit" disabled={isLoading} fullWidth>
                  {isLoading ? <CircularProgress size={20} /> : 'Login'}
                </Button>
              </Box>
            </S.Form>
          </Card>
        </S.FormWrapper>
      </S.FormContainer>
      <Footer />
    </S.Container>
  );
};
