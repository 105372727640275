import { DialogTitle, DialogContentText } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

type PropsType = {
  open: boolean;
  onLogout: () => void;
};

export const IdleDialog = ({ open, onLogout }: PropsType) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Session Expired!!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your session has expired. You will be redirected to Login page.
        </DialogContentText>
        <DialogActions style={{ justifyContent: 'center' }}>
          <button className="button" onClick={onLogout}>
            OK
          </button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
