import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractPathParamsFromPath,
  ExtractOkResponseFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/v2/agents/{agentId}/forms/{formId}']['get'];
type UrlParams = ExtractPathParamsFromPath<PathUrl>;
type Response = ExtractOkResponseFromPath<PathUrl>;

export const getAgentFormById = ({ agentId, formId }: UrlParams) => {
  return axiosClient.get<Response>(`/v2/agents/${agentId}/forms/${formId}`);
};
