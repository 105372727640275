import { IconButton, makeStyles, Input } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Typography } from '@botco/library';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { updateAgent } from '~/redux/actions/conversationDataR';

import { AgentType } from '../../types';

type PropsType = {
  agent: AgentType;
  isEditing: boolean;
  toggleIsEditing: () => void;
};

export const AgentName = ({ agent, isEditing, toggleIsEditing }: PropsType) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [name, setName] = React.useState(agent?.agent_name ?? '');
  const { setSelectChatbot, selectedAgent } = useSelectedChatbot();

  const isWhiteSpaceString = (value: string) => !/\S/.test(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 || e.key === 'Enter') {
      handleSave();
    }
  };

  const handleSave = () => {
    if (!name || isWhiteSpaceString(name)) return;

    if (name !== agent?.agent_name) {
      dispatch(
        updateAgent(
          { ...agent, agent_name: name.trim() },
          (data: AgentType) => {
            toggleIsEditing();
            if (selectedAgent?.id !== data.id) return;
            setSelectChatbot(data);
          }
        )
      );
    } else {
      toggleIsEditing();
    }
  };

  if (isEditing) {
    return (
      <div className={classes.editingWrapper}>
        <Input
          fullWidth
          autoFocus
          name="agentName"
          classes={{ input: classes.input }}
          onChange={handleChange}
          value={name}
          onKeyDown={onKeyDown}
          endAdornment={
            <IconButton
              className={classes.icon}
              disabled={!name || isWhiteSpaceString(name)}
              onClick={handleSave}
            >
              <DoneIcon />
            </IconButton>
          }
        />
      </div>
    );
  }

  return (
    <Typography fontWeight="600" className={classes.agentName}>
      {name}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  agentName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  editingWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: theme.spacing(0, 1),
  },
  input: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
  },
  icon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));
