import { makeStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import * as React from 'react';

import { InfoIcon, Tooltip } from '@botco/library';
import { DEFAULT_ATTRIBUTE } from '~/constants/attributes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  groupHeader: {
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
  tooltip: {
    maxWidth: theme.spacing(25),
  },
  infoIcon: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    display: 'flex',
    '& svg': {
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
  },
}));

export const AttributeGroup = React.forwardRef(
  (
    props: Omit<AutocompleteRenderGroupParams, 'key'>,
    ref: React.Ref<HTMLUListElement>
  ) => {
    const classes = useStyles();
    return (
      <ul ref={ref} className={classes.root}>
        <ListItem>
          <Typography variant="body2" className={classes.groupHeader}>
            {props.group}
          </Typography>
          {props.group === DEFAULT_ATTRIBUTE && (
            <Tooltip
              title=""
              description="Default attributes are attributes that are created by default on the back end."
              classes={{ popper: classes.tooltip }}
            >
              <InfoIcon fontSize="small" className={classes.infoIcon} />
            </Tooltip>
          )}
        </ListItem>
        {props.children}
      </ul>
    );
  }
);

AttributeGroup.displayName = 'AttributeGroup';
