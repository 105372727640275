import { makeStyles, Typography } from '@material-ui/core';

import AccessDeniedIcon from './assets/access-denied.svg?react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium as number,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const AccessDenied = () => {
  const classes = useStyles();

  return (
    <div className={classes.container} data-testid="access-denied-container">
      <AccessDeniedIcon />
      <Typography className={classes.title}>Access Denied</Typography>
      <Typography>
        You do not have access to this data. Please contact your admin for
        permissions
      </Typography>
    </div>
  );
};
