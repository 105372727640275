import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractOkResponseFromPath,
  ExtractPathParamsFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/v2/agents/{agentId}/forms/{formId}/connections']['get'];
type Response = ExtractOkResponseFromPath<PathUrl>;
type Request = ExtractPathParamsFromPath<PathUrl>;

export const getAgentFormConnections = ({ agentId, formId }: Request) => {
  return axiosClient.get<Response>(
    `/v2/agents/${agentId}/forms/${formId}/connections`
  );
};
