import { TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';

import { Table, TableCell, Typography } from '@botco/library';
import { TableSkeleton } from '~/components/TableSkeleton';
import { routes } from '~/constants/routes';
import { useQueryServiceApi } from '~/hooks/useQueryServiceApi';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { QueryServiceMetricsConfig } from '~/types/query-service-helpers';
import { formatCurrency } from '~/utils/numberUtils';

import { DashboardCard } from '../components/Card/DashboardCard';
import { useDashboardQueryServiceContext } from '../Provider/DasboardQueryServiceProvider';

export const DashboardGoalCard = () => {
  const { conditions } = useDashboardQueryServiceContext();
  const { selectedAgent } = useSelectedChatbot();

  const { isLoading, data } = useQueryServiceApi(
    ['dashboard', 'v2', conditions, 'Goals'],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: conditions!,
          projection: [QueryServiceMetricsConfig.Goals],
        },
      }),
    { enabled: Boolean(conditions) }
  );

  const getContactsLink = (goal: string) => {
    return `${routes.contacts.get(selectedAgent?.id!, {
      options: {
        filters: { goals: [goal] },
      },
    })}`;
  };

  return (
    <>
      <DashboardCard title="GOALS">
        <Table
          getKey={(item) => item.tag!}
          data={data?.goals?.content?.counts_by_tag ?? []}
          variant="transparent"
          headerColumns={
            <>
              <TableCell>Goals</TableCell>
              <TableCell align="center">Count</TableCell>
              <TableCell align="center">Value ($)</TableCell>
            </>
          }
          renderRow={(item) => (
            <>
              <TableCell>
                <Link to={getContactsLink(item.tag!)}>
                  <Typography color="primary" fontWeight={500}>
                    {item.tag}
                  </Typography>
                </Link>
              </TableCell>
              <TableCell align="center">
                <Typography color="black">{item.count}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography color="black">
                  {formatCurrency(item.value)}
                </Typography>
              </TableCell>
            </>
          )}
        >
          {isLoading && <TableSkeleton rows={2} columns={3} />}
          <TableRow>
            <TableCell style={{ fontWeight: 700, borderBottom: 0 }}>
              TOTAL
            </TableCell>
            <TableCell
              align="center"
              style={{ fontWeight: 700, borderBottom: 0 }}
            >
              {isLoading ? <Skeleton /> : data?.goals?.count}
            </TableCell>
            <TableCell
              align="center"
              style={{ fontWeight: 700, borderBottom: 0 }}
            >
              {isLoading ? <Skeleton /> : formatCurrency(data?.goals?.value)}
            </TableCell>
          </TableRow>
        </Table>
      </DashboardCard>
    </>
  );
};
