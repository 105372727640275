///agents/5575/attributes
import type { AxiosResponse } from 'axios';

import { Attribute } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  agentId: number;
  attribute: string;
};

export const createAgentAttribute = ({ agentId, attribute }: Options) => {
  return axiosClient.post<Partial<Attribute>, AxiosResponse<Attribute>>(
    `/agents/${agentId}/attributes`,
    {
      agent_id: agentId,
      cust_attr_name: attribute,
      cust_attr_type: 12,
    }
  );
};
