import axios from 'axios';
import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (axios.isAxiosError(error) && error.code === '404') return false;
        else if (failureCount < 2) return true;
        return false;
      },
    },
  },
});
