import { makeStyles } from '@material-ui/core';
import * as React from 'react';

import { Typography } from '@botco/library';

type Props = {
  date: string;
  index: number;
};

const useStyles = makeStyles((theme) => ({
  date: {
    display: 'table',
    margin: '0 auto 8px auto',
    color: theme.palette.common.white,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(3),
    width: 'fit-content',
    transition: 'opacity 0.3s ease-in-out, position 0.3s ease-in-out',
  },
  sticky: {
    position: 'sticky',
    top: 8,
    left: '50%',
    zIndex: 1,
    transform: 'translateX(-50%)',
  },
}));

export const TranscriptStickyDate = ({ date, index }: Props) => {
  const scrollTimeout = React.useRef<NodeJS.Timeout | null>(null);
  const dateRef = React.useRef<HTMLSpanElement | null>(null);
  const classes = useStyles();

  React.useEffect(() => {
    const transcriptContainer = document.querySelector(
      'div[data-testid="transcripts-container"]'
    );

    if (!transcriptContainer) return;

    const handleScroll = () => {
      const current = dateRef.current;
      const next = document.getElementById(`date-${index + 1}`);

      if (current) {
        // make date sticky when user starts scrolling
        current.classList.add(classes.sticky);
      }

      if (current && next) {
        const containerRect = transcriptContainer.getBoundingClientRect();
        const currentRect = current.getBoundingClientRect();
        const nextRect = next.getBoundingClientRect();

        const currentTop = currentRect.top;
        const nextTop = nextRect.top - containerRect.top;

        // hide date when it reaches the next date
        if (nextTop <= currentTop) {
          current.style.opacity = '0';
        } else {
          current.style.opacity = '1';
        }
      }

      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      scrollTimeout.current = setTimeout(() => {
        if (!current) return;
        // move date to where it was before when the user stops scrolling
        current.classList.remove(classes.sticky);
      }, 2000);
    };

    transcriptContainer.addEventListener('scroll', handleScroll);
    return () =>
      transcriptContainer.removeEventListener('scroll', handleScroll);
  }, [index, classes.sticky]);

  return (
    <Typography
      ref={dateRef}
      id={`date-${index}`}
      className={classes.date}
      variant="caption"
    >
      {date}
    </Typography>
  );
};
