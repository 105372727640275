import { Box, useTheme } from '@material-ui/core';
import * as React from 'react';

import { Table, TableCell, Select, Typography } from '@botco/library';
import { TableSkeleton } from '~/components/TableSkeleton';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useQueryServiceApi } from '~/hooks/useQueryServiceApi';

import { TrafficSourceRow } from './components/TrafficSourceRow';
import {
  utmSources,
  UTMSourceSchemaType,
  UTMSourceSchema,
} from './TrafficSource.types';
import { DashboardCard } from '../components/Card/DashboardCard';
import { useDashboardQueryServiceContext } from '../Provider/DasboardQueryServiceProvider';

export const TrafficSource = () => {
  const theme = useTheme();
  const user = useCurrentUser();
  const [filteredBy, setFilteredBy] = React.useState<
    UTMSourceSchemaType | undefined
  >();

  const { conditions } = useDashboardQueryServiceContext();

  const { data, isLoading } = useQueryServiceApi(
    ['queryService', 'attributesValues', conditions, filteredBy],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: conditions!,
          projection: [
            {
              metric: 'attributes',
              views: ['counts_by_value'],
              options: {
                'metric/include_names': filteredBy!,
              },
            },
            {
              metric: 'goals',
              views: ['counts_by_tag'],
            },
          ],
        },
      }),
    {
      enabled: user.hasPIIRole && Boolean(filteredBy),
    }
  );

  const goals = data?.goals?.content?.counts_by_tag?.map((t) => t.tag!) ?? [];
  const attributeValues = data?.attributes?.content?.counts_by_value ?? [];

  return (
    <DashboardCard title="Traffic Source">
      <Table
        data={attributeValues}
        getKey={(row) => `${row.value}-${row.count}`}
        variant="transparent"
        headerColumns={
          <>
            <TableCell width={theme.spacing(33)}>
              <Select
                fullWidth
                placeholder="Choose an option"
                disabled={isLoading}
                options={utmSources.slice()}
                current={filteredBy ?? ''}
                onChange={(value) =>
                  setFilteredBy(UTMSourceSchema.cast(value) ?? '_utm_term')
                }
              />
            </TableCell>
            <TableCell align="center">Unique Chatbot Views</TableCell>
            <TableCell align="center">Engaged Users</TableCell>
            <TableCell>Engagement Rate</TableCell>
            <TableCell align="center">Success Rate</TableCell>
            {goals.map((goal) => (
              <TableCell align="center" key={goal}>
                {goal}
              </TableCell>
            ))}
            <TableCell align="center"> Calls</TableCell>
          </>
        }
        renderRow={({ value }) => (
          <TrafficSourceRow
            key={value}
            value={value!}
            goals={goals}
            filteredBy={filteredBy ?? '_utm_term'}
          />
        )}
      >
        {isLoading && <TableSkeleton columns={6 + goals.length} />}
      </Table>
      {!isLoading && attributeValues.length === 0 && (
        <Box mt={2}>
          <Typography
            align="center"
            fontStyle={filteredBy ? 'initial' : 'italic'}
          >
            {filteredBy ? 'No Data Available' : 'Choose an option'}
          </Typography>
        </Box>
      )}
    </DashboardCard>
  );
};
