import { paths } from '~/__generated__/botco-api-schema';
import { ExtractPathParamsFromPath } from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/agents/{agentId}/forms/{formId}']['delete'];
type UrlParams = ExtractPathParamsFromPath<PathUrl>;

type Options = {
  params: UrlParams;
};

export const deleteAgentForm = ({ params }: Options) => {
  return axiosClient.delete(`/agents/${params.agentId}/forms/${params.formId}`);
};
