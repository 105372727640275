import {
  AttributeConditionDto,
  MetricViewsDto,
  SessionTranscriptMessageDto,
} from '~/__generated__/query-service';
import { Transcript } from '~/utils/http/transcript/types';

export const AttributeConditions = {
  eq: 'Equals',
  lk: 'Contains',
  nlk: 'Does not contain',
  ne: 'Not Equals',
  lt: 'Less Than',
  lte: 'Less Than or Equal To',
  gt: 'Greater Than',
  gte: 'Greater Than or Equal To',
  in: 'In',
  nin: 'Not In',
  ex: 'Exists',
  nex: 'Not Exists',
} satisfies Record<AttributeConditionDto['operator'], string>;

export const AttributeConditionsOptions = Object.entries(AttributeConditions)
  .filter(([_, value]) =>
    [
      AttributeConditions.eq,
      AttributeConditions.lk,
      AttributeConditions.nlk,
    ].includes(value)
  ) // TODO: for now we just support equals, like & not like remove this line to support all operators
  .map(([value, label]) => ({ value, label }));

export const QueryServiceMetricsConfig = {
  SessionTranscripts: {
    metric: 'sessions',
    views: ['transcripts'],
    options: {
      'metric/engaged': 'true',
    },
  },
  EngagedUsers: {
    metric: 'users',
    views: ['counts_by_date'],
    options: {
      'metric/engaged': 'true',
    },
  },
  Views: {
    metric: 'widget_views',
    views: ['counts_by_date'],
    options: {
      'metric/unique': 'false',
    },
  },
  UniqueViews: {
    metric: 'widget_views',
    views: ['counts_by_date'],
    options: {
      'metric/unique': 'true',
    },
  },
  Goals: {
    metric: 'goals',
    views: ['values_by_date', 'counts_by_tag'],
  },
  GoalsCount: {
    metric: 'goals',
    views: ['counts_by_date'],
  },
  DataSubmits: {
    metric: 'data_submits',
    views: ['counts_by_date', 'counts_by_tag'],
  },
  LiveChats: {
    metric: 'live_chats',
    views: ['counts_by_date'],
  },
  EngagementRate: {
    metric: 'calculations',
    views: ['engagement_rate'],
  },
  SuccessRate: {
    metric: 'calculations',
    views: ['success_rate'],
  },
  AverageSessionDuration: {
    metric: 'calculations',
    views: ['average_session_duration'],
  },
  Calls: {
    metric: 'widget_clicks',
    views: ['counts_by_date', 'counts_by_type'],
    options: {
      'metric/click_type': 'phone_number',
    },
  },
  ButtonClicks: {
    metric: 'widget_clicks',
    views: ['counts_by_text_reference'],
  },
  Blocks: {
    metric: 'blocks',
    views: ['counts_by_name'],
  },
  Contacts: {
    metric: 'users',
    views: ['contacts'],
  },
  Attributes: {
    metric: 'attributes',
    views: ['counts_by_name_value'],
  },
  UserTags: {
    metric: 'user_tags',
    views: ['counts_by_tag_label'],
  },
} satisfies Record<string, MetricViewsDto>;

export type QueryServiceMetricsConfigKey =
  keyof typeof QueryServiceMetricsConfig;

export const qsTranscriptMessageDtoToTranscript = ({
  content,
  event_id,
  sent_at,
  sent_from,
  chat_channel,
  type,
}: SessionTranscriptMessageDto) =>
  ({
    id: event_id,
    sender: sent_from === 'user' ? 'user' : 'bot',
    payload: [content],
    timestamp: sent_at,
    type,
    channel: chat_channel,
  }) satisfies Transcript;
