import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  tabsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    gap: theme.spacing(5),
    paddingTop: theme.spacing(3),
  },
  tab: {
    width: 120,
    height: 32,
  },
  searchBar: {
    width: '50%',
    height: '40px',
    borderRadius: 8,
    margin: '0 auto',
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cardContainer: {
    width: '25%',
    padding: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[5],
    height: 250,
  },
  avatar: {
    width: 150,
    height: 150,
    marginTop: theme.spacing(2),
  },
  cardText: {
    width: '80%',
  },
}));

export const AgentListSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.tabsContainer}>
        <Skeleton variant="text" className={classes.tab} />
        <Skeleton variant="text" className={classes.tab} />
        <Skeleton variant="text" className={classes.tab} />
      </div>
      <Skeleton
        className={classes.searchBar}
        variant="rect"
        animation={false}
      />
      <div className={classes.cards}>
        {[...Array(8)].map((_, index) => (
          <div key={`card-${index}`} className={classes.cardContainer}>
            <div className={classes.card}>
              <Skeleton variant="circle" className={classes.avatar} />
              <Skeleton variant="text" className={classes.cardText} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
