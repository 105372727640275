import { Box } from '@material-ui/core';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button, Typography } from '@botco/library';
import { routes } from '~/constants/routes';
import { useQueryServiceApi } from '~/hooks/useQueryServiceApi';
import { QueryServiceMetricsConfig } from '~/types/query-service-helpers';

import { PopularBlocksChart } from './components/PopularBlocksChart';
import { PopularBlocksSkeleton } from './components/PopularBlocksSkeleton';
import { DashboardCard } from '../components/Card/DashboardCard';
import { useDashboardQueryServiceContext } from '../Provider/DasboardQueryServiceProvider';

export const DashboardPopularBlocks: React.FC = () => {
  const history = useHistory();
  const { conversation_id } = useParams<{ conversation_id: string }>();
  const { conditions } = useDashboardQueryServiceContext();

  const { isLoading, data } = useQueryServiceApi(
    ['dashboard', 'v2', conditions, 'Blocks'],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: conditions!,
          projection: [
            {
              ...QueryServiceMetricsConfig.Blocks,
              options: { 'metric/limit': '5' },
            },
          ],
        },
      }),
    { enabled: Boolean(conditions) }
  );

  const total = data?.blocks?.content?.counts_by_name?.reduce(
    (acc, { count }) => acc + (count ?? 0),
    0
  );

  const result =
    data?.blocks?.content?.counts_by_name?.map((d) => ({
      name: d.name,
      average: Number(((100 * (d.count ?? 0)) / (total || 1)).toFixed(2)),
    })) ?? [];

  const handleViewMore = () =>
    history.push(routes.dashboardBlocks.get(Number(conversation_id)));

  return (
    <DashboardCard title="Popular Blocks">
      {isLoading && <PopularBlocksSkeleton />}
      {!isLoading && (
        <>
          {result.map(({ name, average }) => (
            <PopularBlocksChart key={name} value={average} title={name ?? ''} />
          ))}
          {result.length > 0 && (
            <Box display="flex" justifyContent="center">
              <Button variant="text" onClick={handleViewMore}>
                View More
              </Button>
            </Box>
          )}
          {result.length === 0 && (
            <Typography align="center">No Data Available</Typography>
          )}
        </>
      )}
    </DashboardCard>
  );
};
