import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import * as React from 'react';

import { Typography } from '@botco/library';
import { SpinnerLoader } from '~/components/SpinnerLoader';
import { formatSessionId } from '~/utils';
import type { Transcript as TranscriptDTO } from '~/utils/http/transcript/types';

import { TranscriptItem } from './components/Item';
import { TranscriptStickyDate } from './components/StickyDate';
import { TranscriptItemSkeleton } from './components/TranscriptItemSkeleton';
import { useTranscript } from './useTranscript';

const useStyles = makeStyles((theme) => ({
  divider: {
    height: 2,
    background: theme.palette.primary.main,
    marginTop: theme.spacing(),
    width: 185,
  },
  invertScroll: {
    display: 'flex',
    flexDirection: 'column-reverse',
    overflowY: 'auto',
  },
}));

type IdProps = {
  sessionEventId: string | undefined;
};

type UserProps = {
  userId: string;
};

type DataProps = {
  sessionId?: string;
  data: TranscriptDTO[];
  loading?: boolean;
};

type Props = {
  className?: string;
  deployId?: number;
  displayTitle?: boolean;
  minWidth?: string;
} & (IdProps | DataProps | UserProps);

export const Transcript = ({
  className,
  deployId,
  displayTitle = true,
  minWidth = '450px',
  ...props
}: Props) => {
  const classes = useStyles();

  const userId = 'userId' in props ? props.userId : null;
  const sessionEventId =
    'sessionEventId' in props ? props.sessionEventId : undefined;
  const sessionId = 'sessionId' in props ? props.sessionId : null;
  const loading = 'loading' in props ? props.loading : false;
  const initialData = 'data' in props ? props.data : [];

  const {
    avatar,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    loadingData,
    transcripts,
  } = useTranscript({
    deployId,
    sessionEventId,
    userId,
    initialData,
  });

  const isLoading = loadingData || loading;

  React.useEffect(() => {
    const container = document.querySelector(
      'div[data-testid="transcripts-container"]'
    );

    if (!container) return;

    const handleScroll = () => {
      if (isLoading || isFetchingNextPage || !hasNextPage) return;

      const threshold = 100;
      const maxScrollTop = container.scrollHeight - container.clientHeight;
      if (Math.abs(container.scrollTop) >= maxScrollTop - threshold) {
        fetchNextPage();
      }
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, isLoading]);

  const noTranscripts = Object.keys(transcripts).length === 0;

  return (
    <Box
      data-testid="transcripts-container"
      className={`${classes.invertScroll} ${className}`}
      minWidth={minWidth}
    >
      {displayTitle && (
        <Box my={2} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" color="primary">
            Transcript
          </Typography>
          {sessionId && (
            <Box mt={1}>
              <Typography fontWeight="500">
                Conversation ID: {formatSessionId(sessionId)}
              </Typography>
            </Box>
          )}
          <Divider className={classes.divider} variant="middle" />
        </Box>
      )}
      <Box px={4} mt={2} tabIndex={0} role="log">
        {isFetchingNextPage && (
          <>
            <TranscriptItemSkeleton />
            <TranscriptItemSkeleton align="right" />
            <TranscriptItemSkeleton />
          </>
        )}
        {Object.entries(transcripts)
          .reverse()
          .map(([date, transcripts], index) => (
            <Box key={date}>
              <TranscriptStickyDate index={index} date={date} />
              {transcripts
                .reverse()
                .map(({ id, payload, sender, timestamp, type, channel }) => (
                  <div role="listbox" key={id}>
                    {payload.filter(Boolean).map((text) => (
                      <TranscriptItem
                        type={type}
                        sender={sender}
                        text={text}
                        key={text}
                        timestamp={timestamp}
                        avatar={avatar}
                        channel={channel}
                      />
                    ))}
                  </div>
                ))}
            </Box>
          ))}
        {isLoading && (
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SpinnerLoader />
          </Box>
        )}
        {!isLoading && noTranscripts && (
          <Typography align="center">No transcripts available</Typography>
        )}
      </Box>
    </Box>
  );
};
