import { theme } from '@botco/library';
import type { ChartConfigType } from '~/containers/Dashboard/types';
import { QueryServiceMetricsConfigKey } from '~/types/query-service-helpers';
import type { OverviewMetric } from '~/utils/http/dashboard/types';

import { METRICS_CARDS } from '../../constants';

const round = (value: number) => {
  return Math.round(value * 100) / 100;
};

const localeFormatDate = (item: OverviewMetric['details'][number]): string => {
  if (!item.date) return '';
  const [year, month, day] = item.date.split('-').map(Number);
  const date = new Date(year, month - 1, day);

  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
  });
};

type Params = {
  currentMetric: QueryServiceMetricsConfigKey;
  data: OverviewMetric['details'];
  previousData?: OverviewMetric['details'];
  showTitle?: boolean;
  color?: string;
};

export const getOverviewMetricsChartConfig = ({
  currentMetric,
  data,
  previousData,
  showTitle,
  color,
}: Params): ChartConfigType => {
  const card = METRICS_CARDS.find((d) => d.id === currentMetric);
  const title = showTitle ? (card?.title ?? 'Users') : '';

  const isPersonalDashboard = Boolean(color);

  const config: ChartConfigType = {
    options: {
      noData: {
        text: undefined,
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
        },
      },
      markers: {
        size: 4,
        shape: isPersonalDashboard ? ['circle', 'line'] : ['circle'],
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
      },
      title: {
        text: title,
        align: 'center',
        style: {
          fontSize: '16',
          fontWeight: theme.typography.fontWeightMedium as number,
        },
      },
      chart: {
        fontFamily: theme.typography.fontFamily,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 4,
        dashArray: isPersonalDashboard ? [0, 8] : [0],
      },
      grid: {
        row: {
          colors: ['transparent'],
          opacity: 0.5,
        },
      },
      xaxis: {
        tickPlacement: 'on',
        categories: (() => {
          const rows = data?.map(localeFormatDate) ?? [];

          if (!previousData?.length) return rows;

          const previousRows = previousData?.map(localeFormatDate) ?? [];

          return rows.map((row, index) => [row, previousRows[index]]);
        })(),
        type: 'category',
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 100,
          offsetX: 0,
          offsetY: 0,
          format: undefined,
        },
      },
      colors: color
        ? [color]
        : [theme.palette.secondary.main, theme.palette.primary.main],
      yaxis: {
        labels: {
          formatter: (value) => {
            if (!card) return `${value}`;
            if ('formatFn' in card) {
              return card.formatFn?.(value) ?? `${value}`;
            }
            if ('isPercentage' in card && card.isPercentage) {
              return `${value}%`;
            }
            return `${value}`;
          },
        },
      },
    },
    series: [
      {
        name: previousData ? 'Current period' : title,
        data: data?.map((i) => round(i.value)) ?? [],
      },
      ...(previousData
        ? [
            {
              name: `Previous period`,
              data: previousData?.map((i) => round(i.value)) ?? [],
            },
          ]
        : []),
    ],
  };

  return config;
};
