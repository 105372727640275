import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { Button, TextInput } from '@botco/library';
import { Attributes } from '~/components/Attributes';
import { Attribute } from '~/utils/http/agent/types';

type PropsType = {
  modal: boolean;
  value: { attribute?: Partial<Attribute>; jsonPath: string };
  onClose: () => void;
  onUpdate: (name: string, value: any) => void;
  onSave: () => void;
};

export const ApiCardAttributeModal = ({
  modal,
  value: { attribute, jsonPath },
  onClose,
  onUpdate,
  onSave,
}: PropsType) => {
  const classes = useStyles();
  const { conversation_id: agentId } = useParams<{ conversation_id: string }>();

  return (
    <Dialog
      maxWidth="lg"
      open={modal}
      onClose={onClose}
      classes={{ paper: classes.modalPaper }}
    >
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Attributes.Input
              agentId={Number(agentId)}
              dataTestId="attribute"
              value={attribute}
              label="Attribute"
              placeholder="Select or Type Attribute"
              onChange={(value) => onUpdate('attribute', value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              fullWidth
              label="JSON Path"
              value={jsonPath}
              placeholder="JSON path"
              onChange={(e) => onUpdate('jsonPath', e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.modalActions}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    width: theme.spacing(75),
  },
  modalActions: {
    justifyContent: 'center',
  },
}));
