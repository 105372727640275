import LogRocket from 'logrocket';
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from 'redux';
import thunk from 'redux-thunk';

import { constants } from '~/config';

import { allReducers } from './reducers';

const middleware = applyMiddleware(thunk, LogRocket.reduxMiddleware());

const composeEnhancers =
  (constants.app_env === 'dev' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const store = createStore(allReducers, composeEnhancers(middleware));
