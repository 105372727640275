import * as React from 'react';
import { BaseRange, createEditor, Descendant, Editor, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { ReactEditor, withReact } from 'slate-react';

import { TextEditor } from '@botco/library';
import { isValidAttributeName } from '~/utils/attributeUtils';
import { Attribute } from '~/utils/http/agent/types';

import { AttributeTextEditorProps } from './AttributeTextEditor.types';
import {
  buildChipElement,
  serializeDescendants,
  stringToDescendats,
  getAttributeAndTargetNodeFromEditor,
  buildTextElement,
} from './AttributeTextEditor.utils';

type Options = Pick<AttributeTextEditorProps, 'initialValue' | 'onChange'>;

const HIDDEN_DIV_HEIGHT_OFFSET = 24 as const;

export const useAttributeTextEditor = ({ initialValue, onChange }: Options) => {
  const hiddenDiv = React.useRef<HTMLDivElement>(null);
  const value = React.useRef(stringToDescendats(initialValue));

  const [isInvalidAttributeName, setIsInvalidAttributeName] =
    React.useState(false);
  const [target, setTarget] = React.useState<BaseRange | null>(null);
  const [search, setSearch] = React.useState('');
  const [editor] = React.useState(
    TextEditor.utils.withChip(withReact(withHistory(createEditor())))
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickAddAttribute = () => {
    Transforms.insertText(editor, '${');
    ReactEditor.focus(editor);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setTarget(null);
    setSearch('');
  };

  const handleChange = (value: Descendant[]) => {
    const { attribute, targetNode } =
      getAttributeAndTargetNodeFromEditor(editor);

    if (targetNode) {
      setTarget(targetNode);
      setSearch(attribute);
      return;
    }

    handleCloseMenu();
    onChange?.(serializeDescendants(value));
  };

  React.useEffect(() => {
    if (target) {
      const el = hiddenDiv.current;
      if (!el) return;

      const domRange = ReactEditor.toDOMRange(editor, target);
      if (!domRange) return;

      const rect = domRange.getBoundingClientRect();
      if (!rect) return;

      el!.style.top = `${rect.top + HIDDEN_DIV_HEIGHT_OFFSET}px`;
      el!.style.left = `${rect.left}px`;
    }
  }, [editor, search, target]);

  const handleInsertAttribute = (attribute: Partial<Attribute>) => {
    const text = `$\{${attribute.cust_attr_name}}`;

    if (target) {
      Transforms.select(editor, target);
      Transforms.insertNodes(editor, [
        buildTextElement(' '),
        buildChipElement(text),
        buildTextElement(' '),
      ]);
      Transforms.move(editor);
      handleCloseMenu();
      return;
    }

    const getSelection = () => {
      const selection = editor.selection;
      if (!selection) {
        Transforms.select(editor, Editor.end(editor, []));
        return editor.selection;
      }

      return selection;
    };

    const selection = getSelection();
    if (!selection) {
      return;
    }

    const range = Editor.range(editor, selection);
    const point = range ? range.anchor : selection.anchor;
    Transforms.insertNodes(editor, [buildChipElement(text)], { at: point });
    Transforms.move(editor);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!target) return;
    setIsInvalidAttributeName(false);

    switch (event.key) {
      case 'Escape':
        handleCloseMenu();
        break;
      case 'ArrowLeft':
      case 'ArrowRight':
        event.preventDefault();
        break;
    }
    if (event.key.length > 1) {
      return;
    }

    const nextSearch = `${search}${event.key}`;

    if (isValidAttributeName(nextSearch)) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    setIsInvalidAttributeName(true);
  };

  return {
    editor,
    onEditorChange: handleChange,
    value: value.current,
    onAddAttributeClicked: handleClickAddAttribute,
    onCloseMenu: handleCloseMenu,
    anchorEl,
    onInsertAttribute: handleInsertAttribute,
    hiddenDiv,
    target,
    search,
    onKeyDown: handleKeyDown,
    isInvalidAttributeName,
  };
};
