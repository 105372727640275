import { handleActions } from 'redux-actions';

import { apiSuccess, apiFailure } from '../apiStatus';
import {
  GET_AGENT_ENTITIES,
  CREATE_AGENT_ENTITY,
  UPDATE_AGENT_ENTITY,
  DELETE_AGENT_ENTITY,
  GET_AGENT_ENTITY_ITEMS,
} from '../types/entities';

const initialState = {
  status: 'INIT',
  agentEntities: [],
  entityItems: [],
  error: null,
};

export const entitiesReducer = handleActions(
  {
    [apiSuccess(GET_AGENT_ENTITIES)]: (state, { payload: { data } }) => ({
      ...state,
      status: apiSuccess(GET_AGENT_ENTITIES),
      agentEntities: data,
      error: null,
    }),
    [apiFailure(GET_AGENT_ENTITIES)]: (state, { payload }) => ({
      ...state,
      status: apiFailure(GET_AGENT_ENTITIES),
      error: payload,
    }),
    [apiSuccess(CREATE_AGENT_ENTITY)]: (state, { payload: { data } }) => ({
      ...state,
      status: apiSuccess(CREATE_AGENT_ENTITY),
      agentEntities: [...state.agentEntities, data],
      error: null,
    }),
    [apiFailure(CREATE_AGENT_ENTITY)]: (state, { payload }) => ({
      ...state,
      status: apiFailure(CREATE_AGENT_ENTITY),
      error: payload,
    }),
    [apiSuccess(UPDATE_AGENT_ENTITY)]: (state, { payload: { data } }) => {
      let agentEntities = state.agentEntities;
      const entityIndex = agentEntities.findIndex(
        (entity) => entity.entity_id === data.entity_id
      );
      agentEntities = [
        ...agentEntities.slice(0, entityIndex),
        data,
        ...agentEntities.slice(entityIndex + 1),
      ];

      return {
        ...state,
        status: apiSuccess(UPDATE_AGENT_ENTITY),
        agentEntities,
        error: null,
      };
    },
    [apiFailure(UPDATE_AGENT_ENTITY)]: (state, { payload }) => ({
      ...state,
      status: apiFailure(UPDATE_AGENT_ENTITY),
      error: payload,
    }),
    [apiSuccess(DELETE_AGENT_ENTITY)]: (state, { payload: { data } }) => ({
      ...state,
      status: apiSuccess(DELETE_AGENT_ENTITY),
      agentEntities: state.agentEntities.filter(
        (agentEntity) => agentEntity.entity_id !== data.id
      ),
      error: null,
    }),
    [apiFailure(DELETE_AGENT_ENTITY)]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [apiSuccess(GET_AGENT_ENTITY_ITEMS)]: (state, { payload: { data } }) => ({
      ...state,
      status: apiSuccess(GET_AGENT_ENTITY_ITEMS),
      entityItems: data,
      error: null,
    }),
    [apiFailure(GET_AGENT_ENTITY_ITEMS)]: (state, { payload }) => ({
      ...state,
      status: apiFailure(GET_AGENT_ENTITY_ITEMS),
      error: payload,
    }),
  },
  initialState
);
