import { Intent } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  agentId: number;
};

export const getAgentIntents = ({ agentId }: Options) => {
  return axiosClient.get<Intent[]>(`/agents/${agentId}/intents`);
};
