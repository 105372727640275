import {
  ClickAwayListener,
  Grow,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectUserDetails } from '~/redux/selectors/conversationsDataR';
import { isInAccount0 } from '~/utils/userUtils';

import { ConvertToSkillMenuItem } from './ConvertToSkillMenuItem/ConvertToSkillMenuItem';
import { DeleteAgentMenuItem } from './DeleteAgentMenuItem/DeleteAgentMenuItem';
import { AgentType } from '../../types';

type PropsType = {
  agent: AgentType;
  templateHasChildren: boolean;
  onEdit: () => void;
  handleUnselectChatbot: () => void;
};

export const AgentsListCardMenu = (props: PropsType) => {
  const { agent, templateHasChildren } = props;

  const classes = useStyles();
  const userDetails = useSelector(selectUserDetails);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopper = () => setAnchorEl(null);

  const handleEdit = () => {
    props.onEdit();
    handleClosePopper();
  };

  return (
    <>
      <IconButton
        size="small"
        aria-owns={anchorEl ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleOpenPopper}
        className={classes.menuIcon}
        data-testid="agents-list-card-menu-button"
      >
        <MoreVertIcon />
      </IconButton>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList className={classes.list}>
                  <MenuItem onClick={handleEdit} className={classes.menuItem}>
                    Edit
                  </MenuItem>
                  {isInAccount0(userDetails) && !agent.is_template && (
                    <ConvertToSkillMenuItem
                      agent={agent}
                      className={classes.menuItem}
                      handleClosePopper={handleClosePopper}
                    />
                  )}
                  {(!agent.is_template || !templateHasChildren) && (
                    <DeleteAgentMenuItem
                      agent={agent}
                      className={classes.menuItem}
                      handleUnselectChatbot={props.handleUnselectChatbot}
                    />
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    color: theme.palette.grey[600],
    position: 'absolute',
    top: 0,
    right: 0,
  },
  paper: {
    border: '1px solid #545556',
    borderRadius: '0',
  },
  list: {
    paddingTop: '4px',
    paddingBottom: '4px',
    fontFamily: 'Montserrat',
  },
  menuItem: {
    float: 'none',
    padding: '0px 30px',
    fontSize: '14px',
  },
}));
