import { subDays } from 'date-fns/subDays';

import { convertDateString } from '~/utils';
import * as yup from '~/utils/yup';

const DEFAULT_DAY_RANGE = 7;

const booleanTransform = (value: any) => {
  if (typeof value === 'string') {
    return value === 'true';
  }
  return value;
};

export const DashboardFiltersSchema = yup.object({
  attributes: yup.attributes(),
  deployId: yup.safeNumber().nullable(),
  deployType: yup.safeNumber().nullable(),
  startTime: yup
    .string()
    .defined()
    .default(() => convertDateString(subDays(new Date(), DEFAULT_DAY_RANGE))),
  endTime: yup
    .string()
    .defined()
    .default(() => convertDateString(new Date(), { isEndDate: true })),
  compare: yup.boolean().defined().default(false).transform(booleanTransform),
  viewAll: yup.boolean().defined().default(false).transform(booleanTransform),
  sessionId: yup.string().nullable(),
});

export const toUrlParams = (filters: Partial<DashboardFiltersSchemaType>) => {
  const entries = Object.entries(filters)
    .filter(
      ([key, value]) =>
        (value !== undefined && value !== null) ||
        (key === 'attributes' &&
          !filters.attributes?.conditions?.filter((c) => Boolean(c.value))
            .length)
    )
    .map(([key, value]) => {
      if (typeof value === 'object') {
        return [key, JSON.stringify(value)];
      }
      return [key, value?.toString()];
    }) as [string, string][];

  return Object.fromEntries(entries);
};

export type DashboardFiltersSchemaType = yup.InferType<
  typeof DashboardFiltersSchema
>;

export type DataOnViewAll = {
  agentId?: number | null;
  deployId?: number | null;
};

export type GetDataOnViewAll = <T extends DataOnViewAll = any>(data: T) => T;
