import { LinkTypes, ChoiceTypes } from '~/constants';

export const arrayMoveMutate = (array, from, to) => {
  const startIndex = to < 0 ? array.length + to : to;
  const [item] = [...array.splice(from, 1)];
  array.splice(startIndex, 0, item);
  return array;
};

export const reorderList = (list = [], index, direction) => {
  return arrayMoveMutate(
    [...list],
    index,
    direction === 'down' ? index + 1 : index - 1
  ).map((item, index) => {
    item.action_order = index;
    return item;
  });
};

export const generateGotoButtonData = (
  action_intent_id,
  block_title,
  type,
  choice_title = '',
  isForm,
  choice_order
) => {
  const choice = {
    action_data: block_title,
    action_form_id: null,
    action_intent_id,
    action_type: LinkTypes.BLOCK,
    action_choice_id: null,
    choice_order: choice_order ?? 1,
    choice_title: choice_title,
    image_url: null,
  };

  if (isForm) {
    choice.action_form_id = action_intent_id;
    choice.action_intent_id = null;
    choice.action_type = LinkTypes.FORM;
  }

  switch (type) {
    case 'if':
    case ChoiceTypes.IF:
      choice.choice_type = ChoiceTypes.IF;
      break;
    case 'else':
    case ChoiceTypes.ELSE:
      choice.choice_type = ChoiceTypes.ELSE;
      break;
    case 'null':
    case ChoiceTypes.NULL:
      choice.choice_type = ChoiceTypes.NULL;
      break;
    default:
      break;
  }
  return choice;
};
