import type { TextEditor } from '@botco/library';

export type AttributeTextEditorProps = Omit<
  React.ComponentProps<typeof TextEditor.Editor>,
  'editor' | 'onChange'
> & {
  agentId: number;
  initialValue: string;
  onChange?: (value: string) => void;
  additionalAttributes?: string[];
};

export const ATTRIBUTE_MATCH_REGEX = /^\$\{([a-z_][a-z0-9_]*)/;
