import { Link, makeStyles } from '@material-ui/core';

import { Typography } from '@botco/library';
import { WidgetClickCountByTextReferenceDto } from '~/__generated__/query-service';
import * as yup from '~/utils/yup';

type Props = {
  item: WidgetClickCountByTextReferenceDto;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  title: {
    userSelect: 'none',
  },
}));

export const DashboardButtonTooltip = ({ item }: Props) => {
  const classes = useStyles();
  const isValidUrl = yup.string().url().isValidSync(item.reference);

  return (
    <div className={classes.wrapper}>
      <Typography fontWeight="600" color="black" className={classes.title}>
        Destination:
      </Typography>
      {isValidUrl ? (
        <Link href={item.reference} target="_blank">
          {item.reference}
        </Link>
      ) : (
        <Typography color="black">{item.reference}</Typography>
      )}
    </div>
  );
};
