import { makeStyles, TableCell } from '@material-ui/core';

import { Table, Typography } from '@botco/library';
import { UserTagLabelsDto } from '~/__generated__/query-service';

const useStyles = makeStyles((theme) => ({
  table: {
    maxHeight: theme.spacing(28),
  },
  tag: {
    textTransform: 'capitalize',
  },
  emptyMessage: {
    padding: theme.spacing(2),
  },
}));

type Props = {
  tags: UserTagLabelsDto[];
};

export const ContactTagsTable = ({ tags }: Props) => {
  const classes = useStyles();

  return (
    <Table
      variant="plain"
      className={classes.table}
      data={tags}
      getKey={(tag) => tag.tag?.toString() ?? ''}
      headerColumns={
        <>
          <TableCell>Tags</TableCell>
          <TableCell>Label</TableCell>
        </>
      }
      renderRow={({ tag, labels }) => (
        <>
          <TableCell>
            <Typography className={classes.tag} color="black">
              {tag}
            </Typography>
          </TableCell>
          <TableCell>
            {labels?.map((label) => (
              <Typography key={label} color="black">
                {label}
              </Typography>
            ))}
          </TableCell>
        </>
      )}
    >
      {tags.length === 0 && (
        <tr>
          <td colSpan={8} className={classes.emptyMessage}>
            <Typography color="black" align="center">
              No Tags for this user.
            </Typography>
          </td>
        </tr>
      )}
    </Table>
  );
};
