export const INIT_FORM = 'INIT_FORM';

export const UPDATE_FORM = 'UPDATE_FORM';
export const UPDATE_FORM_BASICS = 'UPDATE_FORM_BASICS';
export const ADD_FORM_QUESTION = 'ADD_FORM_QUESTION';
export const UPDATE_FORM_QUESTION_PROMPT = 'UPDATE_FORM_QUESTION_PROMPT';
export const UPDATE_FORM_QUESTION_FALLBACK_PROMPT =
  'UPDATE_FORM_QUESTION_FALLBACK_PROMPT';
export const ADD_CUSTOM_ENTITY = 'ADD_CUSTOM_ENTITY';
export const ADD_ATTRIBUTE = 'ADD_ATTRIBUTE';
export const SET_ENTITY_VALUE = 'SET_ENTITY_VALUE';
export const SET_ATTRIBUTE_VALUE = 'SET_ATTRIBUTE_VALUE';
export const DELETE_FORM_QUESTION = 'DELETE_FORM_QUESTION';
export const FORM_QUESTION_MOVE_UP = 'FORM_QUESTION_MOVE_UP';
export const FORM_QUESTION_MOVE_DOWN = 'FORM_QUESTION_MOVE_DOWN';

export const ADD_FORM_BRANCH = 'ADD_FORM_BRANCH';
export const DELETE_FORM_BRANCH = 'DELETE_FORM_BRANCH';
export const ADD_FORM_INTENT = 'ADD_FORM_INTENT';
export const SELECT_ACTIVE_BRANCH = 'SELECT_ACTIVE_BRANCH';
export const UPDATE_QUESTION_RESPONSES = 'UPDATE_QUESTION_RESPONSES';
export const UPDATE_QUESTION_SINGLE_RESPONSE =
  'UPDATE_QUESTION_SINGLE_RESPONSE';

export const INIT_FORM_ENTITIES = 'INIT_FORM_ENTITIES';
export const INIT_FORM_INTENTS = 'INIT_FORM_INTENTS';
export const INIT_FORM_ATTRIBUTES = 'INIT_FORM_ATTRIBUTES';
export const INIT_FORM_CONNECTIONS = 'INIT_FORM_CONNECTIONS';

export const CHANGE_FORM_BRANCH_LAST_QUESTION_RESPONSE =
  'CHANGE_FORM_BRANCH_LAST_QUESTION_RESPONSE';
