import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractRequestBodyFromPath,
  ExtractOkResponseFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/instastack/llm_ft_add_knowledge']['post'];
type Body = ExtractRequestBodyFromPath<PathUrl>;
type Response = ExtractOkResponseFromPath<PathUrl>;

export async function postAITraining(body: Body) {
  return axiosClient.post<Response>('/instastack/llm_ft_add_knowledge', body);
}
