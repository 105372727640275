import { components } from '~/__generated__/botco-api-schema';

import { API_RESPONSE_TYPES } from './constants/options';

export type IntentApiAction = components['schemas']['IntentApiActionDto'];

export type IntentApiJsonTemplate = components['schemas']['IntentApiTemplate'];

export type IntentApiParam = components['schemas']['IntentApiParam'];

export type ApiResponseTypes = EnumValueType<typeof API_RESPONSE_TYPES>;

export const ApiUsageTypeEnum = {
  SUCCESS: 1,
  FAILURE: 2,
} as const;

export type ApiUsageTypeEnum = EnumValueType<typeof ApiUsageTypeEnum>;
