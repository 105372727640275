import * as yup from 'yup';

export const LoginSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export type LoginSchemaType = yup.InferType<typeof LoginSchema>;

export type AccessTokenDataType = {
  account_id: number;
  exp: number;
  iat: number;
  iss: string;
  roles: string[];
  sub: string;
  user_id: number;
};
