import { ICreatableDropDownOption } from '@botco/library';
import { useApi } from '~/hooks/useApi';
import { AttributeConditions } from '~/types/query-service-helpers';
import { isValidAgentId } from '~/utils/agentUtils';

type Params = {
  agentId: number;
  attributeId: number;
  operator: keyof typeof AttributeConditions;
};

export const useAttributeValuesInput = ({
  agentId,
  attributeId,
  operator,
}: Params) => {
  const { data, isLoading } = useApi(
    ['AttributesValues', { agentId, attributeId }],
    (Api) =>
      Api.agent.getAgentAttributeValues({
        params: { agentId, custAttrId: attributeId },
      }),
    {
      enabled:
        operator === 'eq' &&
        isValidAgentId(agentId) &&
        typeof attributeId === 'number' &&
        attributeId > 0,
    }
  );

  const options: ICreatableDropDownOption[] =
    data?.values?.map((d) => ({
      label: d.item_name,
      value: d.item_name,
    })) ?? [];

  return { type: data?.type ?? 'unknown', options, isLoading };
};
