import { styled } from '@material-ui/core';
import MuiContainer from '@material-ui/core/Container';

export const Container = styled(MuiContainer)(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

export const FormContainer = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  marginTop: '6%',
}));

export const FormWrapper = styled('div')(({ theme }) => ({
  maxWidth: '550px',
  width: '100%',
  padding: theme.spacing(3),
}));

export const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));
