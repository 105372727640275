import { Box, Popover, Typography, makeStyles } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import { differenceInMonths } from 'date-fns/differenceInMonths';
import * as React from 'react';

import { Button, Checkbox, DateRange, DateRangePicker } from '@botco/library';
import { useQueryParams } from '~/hooks/useQueryParams';
import { convertDateString, formatDateString } from '~/utils';
import { showToast } from '~/utils/errorHandlers';

const MAX_ALLOWED_RANGE_IN_MONTHS = 6;

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    justifyContent: 'space-between',
    borderColor: theme.palette.grey['600'],
  },
  calendar: {
    marginLeft: theme.spacing(1),
  },
}));

type ValueType = {
  startTime: string;
  endTime: string;
  compare: boolean;
};

type Props = {
  value: ValueType;
  onChange: (value: ValueType) => void;
};

export const DashboardDatePicker = ({ value, onChange }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>();

  const params = useQueryParams();
  const unlimitedDate = React.useRef<boolean>(
    JSON.parse(params.get('unlimited_dates') ?? 'false')
  );

  const [dateRange, setDateRange] = React.useState<DateRange>({
    endDate: new Date(value.endTime),
    startDate: new Date(value.startTime),
  });

  const [compare, setCompare] = React.useState<boolean>(Boolean(value.compare));

  const handleClose = () => setAnchorEl(undefined);

  const handleChange = (range: DateRange) => setDateRange(range);

  const handleDone = () => {
    if (!dateRange.startDate || !dateRange.endDate) {
      showToast('warn', 'Please select valid dates');
      handleClose();
      return;
    }

    const differenceInDates = differenceInMonths(
      dateRange.endDate,
      dateRange.startDate
    );

    if (
      !unlimitedDate.current &&
      differenceInDates > MAX_ALLOWED_RANGE_IN_MONTHS
    ) {
      showToast('warn', 'Maximum allowed duration is 6 months');
      handleClose();
      return;
    }

    onChange({
      startTime: convertDateString(dateRange.startDate),
      endTime: convertDateString(dateRange.endDate, { isEndDate: true }),
      compare,
    });

    handleClose();
  };

  const displayStartTime = formatDateString(value.startTime);
  const displayEndTime = formatDateString(value.endTime);

  return (
    <>
      <Button
        variant="outlined"
        color="grey"
        className={classes.button}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Typography color="inherit">
          {`${displayStartTime} - ${displayEndTime}`}
        </Typography>
        <CalendarIcon
          fontSize="small"
          color="inherit"
          className={classes.calendar}
        />
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        PaperProps={{
          elevation: 1,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DateRangePicker
          open
          toggle={handleClose}
          onChange={handleChange}
          onDone={handleDone}
          onCancel={handleClose}
          initialDateRange={dateRange}
        >
          <Box px={2}>
            <Checkbox
              label={<Typography>Compare</Typography>}
              checked={compare}
              onChange={(e) => setCompare(e.target.checked)}
            />
          </Box>
        </DateRangePicker>
      </Popover>
    </>
  );
};
