import { TokenStore } from './tokenStore';

export class LocalTokenStore extends TokenStore {
  constructor() {
    super();
  }

  get accessToken() {
    return localStorage.getItem('token');
  }

  set accessToken(val) {
    if (val === null || val === 'null') {
      localStorage.removeItem('token');
    } else {
      localStorage.setItem('token', val);
    }
  }

  get refreshToken() {
    return localStorage.getItem('refreshtoken');
  }

  set refreshToken(val) {
    if (val === null || val === 'null') localStorage.removeItem('refreshtoken');
    else localStorage.setItem('refreshtoken', val);
  }

  initTokens() {
    this.accessToken = null;
    this.refreshToken = null;
  }
}
