import { AxiosResponse } from 'axios';

import { paths } from '~/__generated__/botco-api-schema';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/campaigns/importcsv']['post'];
type Body = PathUrl['requestBody']['content']['multipart/form-data'];
type Response = PathUrl['responses']['200']['content']['application/json'];

type Options = {
  body: Omit<Required<Body>, 'file'> & { file: File };
};

export const createCampaign = ({ body }: Options) => {
  const formData = new FormData();
  formData.append('file', body.file);
  formData.append('camp_name', body.camp_name);
  formData.append('content_template', body.content_template);
  formData.append('deploy_id', body.deploy_id.toString());

  return axiosClient.post<FormData, AxiosResponse<Response>>(
    '/campaigns/importcsv',
    body,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
};
