import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractOkResponseFromPath,
  ExtractRequestBodyFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/faq/generate']['post'];
type Response = ExtractOkResponseFromPath<PathUrl>;
type Body = ExtractRequestBodyFromPath<PathUrl>;

type Request = {
  body: Body;
};

export const generateFAQs = ({ body }: Request) => {
  return axiosClient.post<Response>('/faq/generate', body);
};
