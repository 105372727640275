import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Typography } from '@botco/library';
import DocumentFileAiIcon from '~/assets/icons/document-file-ai.svg?react';
import { routes } from '~/constants/routes';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';

import * as S from './InstaStackSideNavItem.styles';
import { SideNavItem } from '../../SideNavItem';

export const InstaStackSideNavItem: React.FC = () => {
  const history = useHistory();
  const { selectedAgent } = useSelectedChatbot();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (route: string) => () => {
    setAnchorEl(() => null);
    history.push(route);
  };

  const handlePopoverOpen = (e: React.MouseEvent<HTMLDivElement>) =>
    setAnchorEl(e.currentTarget);

  const handlePopoverClose = () => setAnchorEl(null);

  return (
    <>
      <SideNavItem
        title={routes.instaStackData.title}
        data-testid="side-nav-insta-stack-data"
        component="button"
        aria-controls="instastack-menu"
        aria-haspopup="true"
        disableTooltip
        active={new RegExp(/insta-stack/i).test(history.location.pathname)}
        onMouseEnter={handlePopoverOpen}
      >
        <DocumentFileAiIcon />
      </SideNavItem>
      <S.Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="left-start"
        onMouseLeave={handlePopoverClose}
      >
        <S.Paper>
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <div>
              <Box
                display="flex"
                alignItems="center"
                style={{ gap: 8 }}
                color="#302A6F"
              >
                <DocumentFileAiIcon width="27px" height="27px" />
                <Typography fontWeight="600">Instastack</Typography>
              </Box>
              <S.MenuList autoFocusItem id="menu-list-grow">
                <S.MenuItem
                  onClick={handleClick(
                    routes.instaStackData.get(selectedAgent?.id!)
                  )}
                >
                  Knowledge Base
                </S.MenuItem>
                <S.MenuItem
                  onClick={handleClick(
                    routes.instaStackAIPlayground.get(selectedAgent?.id!)
                  )}
                >
                  AI Playground
                </S.MenuItem>
              </S.MenuList>
            </div>
          </ClickAwayListener>
        </S.Paper>
      </S.Popper>
    </>
  );
};
