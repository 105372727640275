import { GetContactParameters, Contact } from './types';
import { axiosClient } from '../axiosClient';

export const getContacts = (params: GetContactParameters) => {
  const { export_tag, ...rest } = params;
  const exportTags = export_tag?.map((et) => `export_tag=${et}`).join('&');

  return axiosClient.get<Contact[]>(`/customers?${exportTags}`, {
    params: rest,
  });
};
