import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { Chip, type ICreatableDropDownOption } from '@botco/library';
import { CUSTOM_ATTRIBUTE } from '~/constants/attributes';
import { isReservedAttributeName } from '~/utils/attributeUtils';

const useStyles = makeStyles((theme) => ({
  constraintTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  constraintList: {
    listStyle: 'inside',
  },
}));

type PropsType = ICreatableDropDownOption & {
  showInvalidAttributeMessage?: boolean;
};

const InvalidAttributeMessage = () => {
  const classes = useStyles();
  return (
    <Box pb={2} data-testid="attribute-option-rules">
      <Typography variant="body1" className={classes.constraintTitle}>
        Constraints
      </Typography>
      <ul className={classes.constraintList}>
        <li>
          Attribute name must start with a letter or the underscore character
        </li>
        <li>Attribute name cannot start with a number</li>
        <li>Attribute name must always be lowercase</li>
      </ul>
    </Box>
  );
};

export const AttributeOption = ({
  showInvalidAttributeMessage,
  ...props
}: PropsType) => {
  const classes = useStyles();

  if ('value' in props) {
    return (
      <Chip
        size="small"
        color={props.group === CUSTOM_ATTRIBUTE ? 'default' : 'secondary'}
      >
        {props.label}
      </Chip>
    );
  }

  if (isReservedAttributeName(props.label)) {
    return (
      <>
        <Box data-testid="reserved-attribute-wrapper">
          {showInvalidAttributeMessage && <InvalidAttributeMessage />}
          <Typography variant="body1" className={classes.constraintTitle}>
            Restricted Name
          </Typography>
          <Typography variant="body2">
            Please use a different attribute name or add more characters.
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <Box>
      {showInvalidAttributeMessage && <InvalidAttributeMessage />}+ Create&nbsp;
      <Chip
        size="small"
        color="default"
        dataTestId="attribute-option-new-attribute"
      >
        {props.label}
      </Chip>
      &nbsp; Attribute
    </Box>
  );
};
