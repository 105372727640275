import { Paper, styled } from '@material-ui/core';

import { Typography as BotcoTypography, Theme } from '@botco/library';

export const Bubble = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  maxWidth: theme.spacing(35),
  width: 'fit-content',
  padding: theme.spacing(2),

  '&.receiver': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginLeft: 'auto',
  },

  '&.sms': {
    backgroundColor: theme.palette.success.main,
  },
}));

export const BubbleWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  minWidth: '160px',
  paddingBottom: theme.spacing(2),
}));

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  $sender: string;
}

export const Container = styled(
  ({ $sender: _$sender, ...props }: ContainerProps) => <div {...props} />
)<Theme, ContainerProps>(({ theme, $sender }) => ({
  position: 'relative',
  width: $sender === 'bot' ? 'fit-content' : '',
  marginBottom: theme.spacing(2.5),
}));

export const MessageTypography = styled(BotcoTypography)(() => ({
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
}));

export const Typography = styled(BotcoTypography)(({ theme }) => ({
  fontSize: '12px',
  position: 'absolute',
  right: theme.spacing(1),
  bottom: 0,
  whiteSpace: 'nowrap',
}));

export const Avatar = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: theme.spacing(5),
  minWidth: theme.spacing(5),
  height: theme.spacing(5),
  minHeight: theme.spacing(5),
  background: '0 0',
  margin: 0,
  padding: 0,
  position: 'absolute',
  display: 'inline-block',
  overflow: 'hidden',
  color: theme.palette.common.white,
  left: theme.spacing(-2),
  bottom: theme.spacing(-1),
}));

export const Img = styled('img')(() => ({
  display: 'block',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));
