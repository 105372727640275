import { BotcoAPI } from '@botco/api';
import { constants } from '~/config';

import { ALL_INTENTS, SET_SELECTED_AGENT } from '../types/conversationNew';

const newApiCall = new BotcoAPI(constants.api_url);

export const getIntentsByAgentId = (agent_id) => {
  if (!agent_id) return { type: ALL_INTENTS, payload: [] };
  return async (dispatch) => {
    try {
      const { data } = await newApiCall.getIntents(agent_id);
      return dispatch({ type: ALL_INTENTS, payload: data });
    } catch (error) {
      return dispatch({ type: ALL_INTENTS, payload: [] });
    }
  };
};

export const setSelectedAgent = (agent) => {
  return {
    type: SET_SELECTED_AGENT,
    payload: agent,
  };
};
