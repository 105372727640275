import { AxiosResponse } from 'axios';

import { axiosClient } from '../axiosClient';

export const uploadFile = (data: FormData) => {
  return axiosClient.post<FormData, AxiosResponse<{ location: string }>>('/assets/upload', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

}