import type { AxiosError, AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { Api } from '~/utils/http/api';

type Options<T> = Pick<
  UseQueryOptions<T, AxiosError<any>>,
  | 'initialData'
  | 'enabled'
  | 'retry'
  | 'onError'
  | 'onSettled'
  | 'onSuccess'
  | 'cacheTime'
  | 'keepPreviousData'
  | 'refetchInterval'
  | 'select'
>;

export const useApi = <T>(
  queryKey: UseQueryOptions<T, AxiosError<any>>['queryKey'],
  fn: (api: typeof Api) => Promise<AxiosResponse<T> | AxiosResponse<unknown>[]>,
  options?: Options<T>
) => {
  return useQuery({
    ...options,
    queryKey,
    queryFn: () =>
      fn(Api).then((result) =>
        Array.isArray(result) ? result.map(({ data }) => data) : result.data
      ) as any,
  });
};
