import { Typography, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { Collaborator } from '~/components/Collaborator';
import { getCurrentRouteRecord } from '~/utils';

import { SelectedChatbotButton } from './SelectedChatbotButton/SelectedChatbotButton';

export const HEADER_PORTAL_TARGET_ID = 'header-buttons-container';

export const Header = () => {
  const classes = useStyles();
  const location = useLocation();

  const { route } = React.useMemo(
    () => getCurrentRouteRecord(location.pathname),
    [location.pathname]
  );

  const showsChatbotButton = (() => {
    if (!route) return false;

    if (!('hideSelectChatbotButton' in route)) return true;

    return !route.hideSelectChatbotButton;
  })();

  return (
    <header data-testid="app-header" className={classes.header}>
      <Typography variant="h4" className={classes.title}>
        {route?.title}
      </Typography>
      <div className={classes.buttons}>
        <Collaborator.Button />
        <div id={HEADER_PORTAL_TARGET_ID} className={classes.buttons} />
        {showsChatbotButton && <SelectedChatbotButton />}
      </div>
    </header>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    display: 'flex',
    height: theme.spacing(7.5),
    justifyContent: 'space-between',
    padding: theme.spacing(2, 6),
    zIndex: 2,
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  title: {
    color: (theme.palette as any).purple.main,
  },
}));
