import { Deployment } from './types';
import { axiosClient } from '../axiosClient';

type Params = {
  agent_id?: number;
  runtime_tenant_id?: boolean;
};

export const getDeployments = async (params?: Params) => {
  return axiosClient.get<Deployment[]>('/deployments', {
    params,
  });
};
