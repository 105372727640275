import { paths } from '~/__generated__/botco-api-schema';
import { ExtractQueryParamsFromPath } from '~/types/open-api-helpers';

import { Campaign } from './types';
import { axiosClient } from '../axiosClient';

type PathUrl = paths['/campaigns']['get'];
type Params = ExtractQueryParamsFromPath<PathUrl>;

type Options = {
  params: Params;
};

export const getCampaigns = ({ params }: Options) => {
  return axiosClient.get<Campaign[]>('/campaigns', { params });
};
