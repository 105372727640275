import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractCreatedResponseFromPath,
  ExtractPathParamsFromPath,
  ExtractRequestBodyFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/agents/{agentId}/intents']['post'];
type UrlParams = ExtractPathParamsFromPath<PathUrl>;
type Body = ExtractRequestBodyFromPath<PathUrl>;
type Response = ExtractCreatedResponseFromPath<PathUrl>;

type Options = {
  params: UrlParams;
  body: Body;
};

export const createAgentIntent = ({ params, body }: Options) => {
  return axiosClient.post<Response>(`/agents/${params.agentId}/intents`, body);
};
