import { ContactAttribute } from './types';
import { axiosClient } from '../axiosClient';

type Params = {
  contactId: number;
};

export const getContactAttributes = (params: Params) => {
  return axiosClient.get<ContactAttribute[]>(
    `/customers/${params.contactId}/attributes`
  );
};
