import { paths } from '~/__generated__/botco-api-schema';
import { ExtractOkResponseFromPath } from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/agents']['get'];
type Response = ExtractOkResponseFromPath<PathUrl>;
type Request = {
  is_account_zero: boolean;
};

export function getAgents(params: Request = { is_account_zero: false }) {
  return axiosClient.get<Response>('/agents', {
    params,
  });
}
