import {
  usePinCommentButton,
  useSidebarCommentsButton,
} from '@collabkit/react';
import { Badge, IconButton, makeStyles, Menu } from '@material-ui/core';
import * as React from 'react';

import { SelectItem } from '@botco/library';

import CircleIcon from '../assets/circle.svg?react';
import CommentIcon from '../assets/comment-icon.svg?react';

export const CollaboratorButton = () => {
  const classes = useStyles();
  const { onClick } = usePinCommentButton();
  const { onClick: sidebarCommentsOnClick, hasUnread } =
    useSidebarCommentsButton();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleAddComment = (e: React.MouseEvent) => {
    onClick(e);
    handleClose();
  };

  const handleViewAllComment = (e: React.MouseEvent) => {
    sidebarCommentsOnClick(e);
    handleClose();
  };

  return (
    <>
      <IconButton
        className={classes.icon}
        disableRipple
        aria-label="comments"
        aria-haspopup="true"
        aria-controls="collab-menu"
        onClick={handleClick}
      >
        <Badge
          overlap="circular"
          invisible={!hasUnread}
          badgeContent={<CircleIcon />}
        >
          <CommentIcon />
        </Badge>
      </IconButton>
      <Menu
        id="collab-menu"
        elevation={2}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        classes={{ list: classes.menu }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <SelectItem onClick={handleAddComment}>Drop a comment</SelectItem>
        <SelectItem onClick={handleViewAllComment}>
          View all comments
        </SelectItem>
      </Menu>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    padding: 0,
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(23),
  },
}));
