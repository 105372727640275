import { format as formatDate, startOfDay, endOfDay } from 'date-fns';

export const convertDateString = (
  date: Date | string | null,
  options: { isEndDate?: boolean } = {}
) => {
  if (!date) return '';
  const parsedDate = typeof date === 'string' ? new Date(date) : date;
  const utcDate = Date.UTC(
    parsedDate.getUTCFullYear(),
    parsedDate.getUTCMonth(),
    parsedDate.getUTCDate(),
    parsedDate.getUTCHours(),
    parsedDate.getUTCMinutes(),
    parsedDate.getUTCSeconds(),
    parsedDate.getUTCMilliseconds()
  );

  if (options?.isEndDate) {
    return endOfDay(utcDate).toISOString();
  }

  return startOfDay(utcDate).toISOString();
};

export const formatDateString = (
  date: Date | string | null,
  format = 'd MMM yyy'
) => {
  if (!date) return '';
  const parsedDate = typeof date === 'string' ? new Date(date) : date;
  return formatDate(endOfDay(parsedDate), format);
};
