import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import * as React from 'react';

import * as S from './APICardButton.styles';

type PropsType = React.PropsWithChildren<{
  onDelete: () => void;
}>;

export const APICardButton = (props: PropsType) => {
  return (
    <S.Wrapper>
      {props.children}
      <IconButton
        size="small"
        className="deleteIconButton"
        onClick={props.onDelete}
      >
        <Delete />
      </IconButton>
    </S.Wrapper>
  );
};
