import {
  CreatableDropDown,
  ICreatableDropDownOption,
  TextInput,
} from '@botco/library';
import { AttributeConditions } from '~/types/query-service-helpers';

import { useAttributeValuesInput } from './useAttributeValuesInput';

type Props = {
  agentId: number;
  attributeId: number;
  operator: keyof typeof AttributeConditions;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  label?: string;
  placeholder?: string;
  showError?: boolean;
  value?: string;
  onChange?: (value?: string) => void;
};

export const AttributeValuesInput = ({
  agentId,
  attributeId,
  className,
  dataTestId,
  disabled,
  error,
  helperText,
  label = '',
  placeholder,
  showError,
  value,
  onChange,
  operator,
}: Props) => {
  const { type, options } = useAttributeValuesInput({
    agentId,
    attributeId,
    operator,
  });

  if (type === 'finite' && operator === 'eq') {
    const currentOption =
      options.find((o) => o.value === value) ??
      ({} as ICreatableDropDownOption);

    return (
      <CreatableDropDown
        label={label}
        dataTest={dataTestId}
        required
        className={className}
        current={currentOption}
        options={options}
        error={error}
        placeholder={currentOption?.value ? '' : placeholder}
        showError={showError}
        helperText={helperText}
        disabled={disabled}
        disableCreateOption
        isValidNewOption={() => true}
        onCreateOption={() => ({})}
        onChange={(option) => onChange?.(option.value ?? '')}
      />
    );
  }

  return (
    <TextInput
      label={label}
      data-testid={dataTestId}
      error={error}
      className={className}
      helperText={helperText}
      value={value ?? ''}
      disabled={disabled}
      placeholder={placeholder}
      onChange={(e) => onChange?.(e.target.value ?? '')}
    />
  );
};
