import { AgentType } from '~/containers/Conversations/ConversationsList/types';

const BOTCO_CONVERSATIONS = 'botco:conversations';

const getConversations = (): Record<number, number> => {
  try {
    return JSON.parse(localStorage.getItem(BOTCO_CONVERSATIONS) ?? '{}');
  } catch (_) {
    return {};
  }
};

export const getDefaultChatbot = (accountId: number): number | null => {
  const conversations = getConversations();
  return conversations[accountId] ?? null;
};

export const saveDefaultChatbot = (agent: AgentType): void => {
  const conversations = getConversations();
  localStorage.setItem(
    BOTCO_CONVERSATIONS,
    JSON.stringify({
      ...conversations,
      [agent.account_id]: agent.id,
    })
  );
};

export const unsetDefaultChatbot = (accountId: number) => {
  const conversations = getConversations();
  delete conversations[accountId];
  localStorage.setItem(BOTCO_CONVERSATIONS, JSON.stringify(conversations));
};
