import type { paths } from '~/__generated__/botco-api-schema';
import type { ExtractRequestBodyFromPath } from '~/types/open-api-helpers';

import { InstaChatResponse } from './types';
import { axiosClient } from '../axiosClient';

type PathUrl = paths['/instastack/query_gen']['post'];
type Body = ExtractRequestBodyFromPath<PathUrl>;

type Options = {
  body: Body;
};

export const postInstaChatRequest = ({ body }: Options) => {
  return axiosClient.post<InstaChatResponse>('/instastack/query_gen', body);
};
