import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Button, Typography, theme } from '@botco/library';
import {
  addApiCardTemplate,
  removeApiCardTemplate,
} from '~/redux/actions/conversationDataR';

import { APIJSONTemplateModal } from './Modal';
import { JSONTemplateFormSchema } from './Modal/APIJSONTemplateModal.types';
import type {
  ApiUsageTypeEnum,
  IntentApiJsonTemplate,
} from '../../APICard.types';
import { APICardButton } from '../APICardButton';

type PropsType = {
  apiUsageType: ApiUsageTypeEnum;
  jsonTemplates: IntentApiJsonTemplate[];
  orderIndex: number;
};

export const APIJSONTemplate = ({
  apiUsageType,
  orderIndex,
  jsonTemplates: allJsonTemplates,
}: PropsType) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const jsonTemplates = allJsonTemplates.filter(
    (t) => t.template_usage_type === apiUsageType
  );

  const handleOpenModal = (index = -1) => {
    setError('');
    setOpen(true);
    setSelectedIndex(index);
  };

  const handleAdd = (data: JSONTemplateFormSchema) => {
    setError('');
    const existingTemplates = jsonTemplates.some(
      (t, idx) => idx !== selectedIndex && t.template_location === data.type
    );

    if (existingTemplates) {
      setError('Please use different JSON Type');
      return;
    }

    dispatch(
      addApiCardTemplate(
        orderIndex,
        selectedIndex,
        data.type,
        data.template,
        apiUsageType
      )
    );

    setOpen(false);
    setSelectedIndex(-1);
  };

  const handleRemoveTemplate = (index: number) => {
    dispatch(removeApiCardTemplate(orderIndex, index));
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Json Templates
        </Typography>
        <Typography variant="caption" color="textPrimary">
          Create Json Templates to format API&apos;s response
        </Typography>
      </Grid>
      <Box mb={2} borderTop={`1px solid ${theme.palette.grey[400]}`}>
        {jsonTemplates.map((p, idx) => (
          <APICardButton
            key={`${idx}-${p.template_location}`}
            onDelete={() => handleRemoveTemplate(idx)}
          >
            <Button variant="text" onClick={() => handleOpenModal(idx)}>
              <Typography variant="caption" fontWeight="500">
                {p.template_location}
              </Typography>
            </Button>
          </APICardButton>
        ))}
      </Box>
      {jsonTemplates.length < 2 && (
        <Grid container item xs={12} justifyContent="center">
          <Button variant="text" onClick={() => handleOpenModal()}>
            + Add JSON Templates
          </Button>
        </Grid>
      )}

      {open && (
        <APIJSONTemplateModal
          open
          error={error}
          jsonTemplate={jsonTemplates[selectedIndex]}
          onClose={() => setOpen(false)}
          onAddTemplate={handleAdd}
        />
      )}
    </>
  );
};
