import { DialogTitle, makeStyles } from '@material-ui/core';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { type TransitionProps } from '@material-ui/core/transitions';
import * as React from 'react';

import {
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
} from '@botco/library';

import { SpinnerLoader } from '../SpinnerLoader';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps,
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type MuiDialogProps = Pick<
  DialogProps,
  'open' | 'onClose' | 'fullScreen' | 'fullWidth' | 'className' | 'maxWidth'
>;

type CustomButtonProps = {
  text: string;
  color?: ButtonProps['color'];
  variant?: ButtonProps['variant'];
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

interface Props extends MuiDialogProps {
  confirmButton?: string | CustomButtonProps;
  cancelButton?: string | CustomButtonProps;
  title?:
    | string
    | {
        text: string;
        color: TypographyProps['color'];
        variant?: TypographyProps['variant'];
      };
}

export const ConfirmationDialog = ({
  open,
  onClose,
  maxWidth = 'xs',
  children,
  confirmButton = 'Confirm',
  cancelButton = 'Cancel',
  title = 'Are you sure?',
  fullWidth = true,
  fullScreen = false,
}: React.PropsWithChildren<Props>) => {
  const classes = useStyles();

  const confirmButtonConfig: CustomButtonProps =
    typeof confirmButton === 'string' ? { text: confirmButton } : confirmButton;

  const cancelButtonConfig: CustomButtonProps =
    typeof cancelButton === 'string'
      ? { text: cancelButton, color: 'grey' }
      : cancelButton;

  const titleConfig =
    typeof title === 'string'
      ? { text: title, color: 'primary' as const, variant: 'h3' as const }
      : {
          text: title.text,
          color: title.color ?? 'primary',
          variant: title.variant ?? 'h3',
        };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClose?.(e, 'backdropClick');
    cancelButtonConfig.onClick?.(e);
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      aria-labelledby="responsive-dialog-title"
      maxWidth={maxWidth}
    >
      <DialogTitle disableTypography>
        <Typography
          align="center"
          color={titleConfig.color}
          variant={titleConfig.variant}
        >
          {titleConfig.text}
        </Typography>
      </DialogTitle>
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions className={classes.actions}>
        <Button
          type="button"
          variant={confirmButtonConfig.variant}
          onClick={confirmButtonConfig.onClick}
          color={confirmButtonConfig.color}
          disabled={
            confirmButtonConfig.disabled || confirmButtonConfig.isLoading
          }
        >
          {confirmButtonConfig.isLoading ? (
            <SpinnerLoader loaderStyle={{ width: 16, height: 16 }} />
          ) : (
            confirmButtonConfig.text
          )}
        </Button>
        <Button
          variant="outlined"
          color={cancelButtonConfig.color ?? 'grey'}
          onClick={handleCancel}
        >
          {cancelButtonConfig.text}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2.5),
  },
}));
