import { makeStyles } from '@material-ui/core';

import { Table, TableCell, Typography } from '@botco/library';
import { UserContactDataSubmitDto } from '~/__generated__/query-service';

type Props = {
  dataSubmits: UserContactDataSubmitDto[];
};

const useStyles = makeStyles((theme) => ({
  table: {
    maxHeight: theme.spacing(28),
  },
}));

export const ContactDataSubmitTable = ({ dataSubmits }: Props) => {
  const classes = useStyles();

  const isEmptyDataSubmits = !dataSubmits || dataSubmits.length === 0;

  const defDataSubmit: UserContactDataSubmitDto = {
    chatbot_event_id: '-',
    data_submit_tag: '-',
    session_id: '-',
  };

  return (
    <Table
      hover
      variant="plain"
      className={classes.table}
      data={isEmptyDataSubmits ? [defDataSubmit] : dataSubmits}
      getKey={(ds) => ds.chatbot_event_id!}
      headerColumns={
        <>
          <TableCell>Data Submits</TableCell>
        </>
      }
      renderRow={(ds) => {
        return (
          <>
            <TableCell>
              <Typography color="black">{ds.data_submit_tag}</Typography>
            </TableCell>
          </>
        );
      }}
    />
  );
};
