import { Attribute } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  agentId: number;
};

export const getAgentAttributes = ({ agentId }: Options) => {
  return axiosClient.get<Attribute[]>(`/agents/${agentId}/attributes`);
};
