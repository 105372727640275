import { TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';

import { Table, TableCell, Typography } from '@botco/library';
import { TableSkeleton } from '~/components/TableSkeleton';
import { routes } from '~/constants/routes';
import { useQueryServiceApi } from '~/hooks/useQueryServiceApi';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { QueryServiceMetricsConfig } from '~/types/query-service-helpers';

import { DashboardCard } from '../components/Card/DashboardCard';
import { useDashboardQueryServiceContext } from '../Provider/DasboardQueryServiceProvider';

export const DataSubmit = () => {
  const { conditions } = useDashboardQueryServiceContext();
  const { selectedAgent } = useSelectedChatbot();

  const { isLoading, data } = useQueryServiceApi(
    ['dashboard', 'v2', conditions, 'DataSubmits'],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: conditions!,
          projection: [QueryServiceMetricsConfig.DataSubmits],
        },
      }),
    {
      enabled: Boolean(conditions),
    }
  );

  const getContactsLink = (dataSubmit: string) => {
    return `${routes.contacts.get(selectedAgent?.id!, {
      options: {
        filters: { dataSubmit: [dataSubmit] },
      },
    })}`;
  };

  return (
    <DashboardCard title="DATA SUBMITS">
      <Table
        data={data?.data_submits?.content?.counts_by_tag ?? []}
        getKey={(item) => item.tag!}
        variant="transparent"
        headerColumns={
          <>
            <TableCell>Data Submits</TableCell>
            <TableCell align="center">Count</TableCell>
          </>
        }
        renderRow={(item) => (
          <>
            <TableCell>
              <Link to={getContactsLink(item.tag!)}>
                <Typography color="primary" fontWeight={500}>
                  {item.tag}
                </Typography>
              </Link>
            </TableCell>
            <TableCell align="center">
              <Typography color="black">{item.count}</Typography>
            </TableCell>
          </>
        )}
      >
        {isLoading && <TableSkeleton rows={2} columns={2} />}
        <TableRow>
          <TableCell style={{ fontWeight: 700, borderBottom: 0 }}>
            TOTAL
          </TableCell>
          <TableCell
            align="center"
            style={{ fontWeight: 700, borderBottom: 0 }}
          >
            {isLoading ? <Skeleton /> : data?.data_submits?.count}
          </TableCell>
        </TableRow>
      </Table>
    </DashboardCard>
  );
};
