import type { paths } from '~/__generated__/botco-api-schema';
import type {
  ExtractOkResponseFromPath,
  ExtractRequestBodyFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/instastack/document']['put'];
// @ts-ignore
type Response = ExtractOkResponseFromPath<PathUrl>;
// @ts-ignore
type Body = ExtractRequestBodyFromPath<PathUrl>;

type Options = {
  body: Body;
};

export const updateInstastackDocument = ({ body }: Options) => {
  return axiosClient.put<Response>('/instastack/document', body);
};
