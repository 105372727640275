import ReactApexChart from 'react-apexcharts';

import { theme } from '@botco/library';

type Props = {
  total: number;
  data: Array<{ name: string; count: number; average: number }>;
};

export const DashboardBlocksChart: React.FC<Props> = ({ data, total }) => {
  const COLORS = ['#302A6F', '#E74991', '#F284B7', '#68629D', '#7AA7FF'];

  const top4Data = data
    .slice(0, 4)
    .map((d) => ({ label: d.name, series: d.average }));

  const others = data
    .slice(4)
    .reduce((acc, curr) => acc + (curr.count || 0), 0);

  const chartData =
    others > 0
      ? [
          ...top4Data,
          {
            label: 'Others',
            series: Number(((100 * (others ?? 0)) / (total || 1)).toFixed(2)),
          },
        ]
      : top4Data;

  const options: ApexCharts.ApexOptions = {
    colors: COLORS,
    chart: {
      type: 'donut',
      fontFamily: theme.typography.fontFamily,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontWeight: 700,
              formatter: () => (total ?? 0).toString(),
            },
          },
        },
      },
    },
    labels: chartData.map((d) => d.label ?? ''),
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '16px',
        fontWeight: 600,
      },
    },
    legend: {
      fontSize: '16px',
      fontWeight: 600,
      onItemHover: {
        highlightDataSeries: false,
      },
      labels: {
        colors: '#61636C',
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={chartData.map((d) => d.series)}
      type="donut"
    />
  );
};
