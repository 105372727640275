import type { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { CancelablePromise } from '~/__generated__/query-service';
import { QueryServiceApi } from '~/utils/http/api';

type Options<T> = Pick<
  UseQueryOptions<T, AxiosError<any>>,
  | 'initialData'
  | 'enabled'
  | 'retry'
  | 'onError'
  | 'onSettled'
  | 'onSuccess'
  | 'cacheTime'
  | 'keepPreviousData'
>;

export const useQueryServiceApi = <T>(
  queryKey: UseQueryOptions<T, AxiosError<any>>['queryKey'],
  fn: (api: typeof QueryServiceApi) => CancelablePromise<T> | Promise<T>,
  options?: Options<T>
) => {
  return useQuery({
    ...options,
    queryKey,
    queryFn: ({ signal }) => {
      const req = fn(QueryServiceApi);

      if (signal && 'cancel' in req) {
        signal.addEventListener('abort', () => {
          req.cancel();
        });
      }

      return req.then((result) => result);
    },
  });
};
