import { axiosClient } from '../axiosClient';

type Options = {
  agentId: number;
  intentId: number;
  inputId: number;
};

export const deleteAgentIntentInput = ({
  agentId,
  intentId,
  inputId,
}: Options) => {
  return axiosClient.delete(
    `/agents/${agentId}/intents/${intentId}/inputs/${inputId}`
  );
};
