import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import * as React from 'react';

import { theme } from '@botco/library';
import { DashboardOverviewMetricsChart } from '~/containers/Dashboard/OverView/components/MetricsChart';
import { METRICS_CARDS } from '~/containers/Dashboard/OverView/constants';

import { PersonalMetricOverviewType } from '../../usePersonalDashboardOverview';
import { usePersonalDashboardOverviewMetricData } from '../../usePersonalDashboardOverviewMetricData';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    borderRadius: theme.spacing(1),
    width: '100%',
    height: theme.spacing(50),
  },
}));

type Props = {
  overviewMetric: PersonalMetricOverviewType;
};

export const PersonalDashboardOverviewChart = ({ overviewMetric }: Props) => {
  const classes = useStyles();

  const metricConfig = React.useMemo(
    () =>
      METRICS_CARDS.find(
        (m) => m.id === overviewMetric.id || m.id === overviewMetric.parentId
      )!,
    [overviewMetric.id, overviewMetric.parentId]
  );

  const { compare, comparedData, data, loading } =
    usePersonalDashboardOverviewMetricData({
      metricId: metricConfig.id,
      metricTag: overviewMetric.parentId ? overviewMetric.title : undefined,
    });

  if (loading) {
    return (
      <Skeleton animation="pulse" variant="rect" className={classes.skeleton} />
    );
  }

  return (
    <DashboardOverviewMetricsChart
      showTitle={false}
      currentMetric={metricConfig.id}
      previousData={
        compare ? metricConfig.getCurrentData(comparedData).details : undefined
      }
      data={metricConfig.getCurrentData(data).details}
      color={theme.palette.secondary.main}
    />
  );
};
