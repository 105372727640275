import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractCreatedResponseFromPath,
  ExtractQueryParamsFromPath,
  ExtractRequestBodyFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/agents']['post'];
type Response = ExtractCreatedResponseFromPath<PathUrl>;
type Query = ExtractQueryParamsFromPath<PathUrl>;
type Body = ExtractRequestBodyFromPath<PathUrl>;

type Request = {
  body: Body;
  params: Query;
};

export function createAgent({ body, params }: Request) {
  return axiosClient.post<Response>('/agents', body, { params });
}
