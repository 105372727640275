import { components } from '~/__generated__/botco-api-schema';

export enum AgentsListTabs {
  All = 'all',
  InProgress = 'inprogress',
  Published = 'published',
  Templates = 'templates',
}

export type AgentType = Omit<components['schemas']['Agent'], 'id'> & {
  id: number;
};
