import { styled } from '@material-ui/core';

export const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: theme.spacing(5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  borderBottom: `1px solid ${theme.palette.grey[400]}`,

  '& .deleteIconButton': {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    visibility: 'hidden',
  },

  '&:hover': {
    '& .deleteIconButton': {
      visibility: 'visible',
    },
  },
}));
