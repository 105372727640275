import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractOkResponseFromPath,
  ExtractPathParamsFromPath,
  ExtractQueryParamsFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type Path =
  paths['/trainingsv2/fallback/{clusteringId}/{clusterNumber}']['get'];
type Response = ExtractOkResponseFromPath<Path>;
type PathParams = ExtractPathParamsFromPath<Path>;
type QueryParams = ExtractQueryParamsFromPath<Path>;

type Params = {
  pathParams: PathParams;
  queryParams: QueryParams;
};

export async function getTrainingFallbackDetails({
  pathParams: { clusterNumber, clusteringId },
  queryParams,
}: Params) {
  return axiosClient.get<Response>(
    `/trainingsv2/fallback/${clusteringId}/${clusterNumber}`,
    { params: queryParams }
  );
}
