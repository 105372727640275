import type { AxiosResponse } from 'axios';

import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractOkResponseFromPath,
  ExtractPathParamsFromPath,
  ExtractRequestBodyFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/v2/agents/{agentId}/forms/{formId}']['put'];
type UrlParams = ExtractPathParamsFromPath<PathUrl>;
type Response = ExtractOkResponseFromPath<PathUrl>;
type Body = ExtractRequestBodyFromPath<PathUrl>;

type Request = {
  urlParams: UrlParams;
  payload: Body;
};

export const updateAgentForm = ({ payload, urlParams }: Request) => {
  return axiosClient.put<Request['payload'], AxiosResponse<Response>>(
    `/v2/agents/${urlParams.agentId}/forms/${urlParams.formId}`,
    payload
  );
};
