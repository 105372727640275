import { useTheme, makeStyles, Box } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { format as formatDate } from 'date-fns/format';

import {
  Table,
  TableCell,
  TableProps,
  Tooltip,
  Typography,
} from '@botco/library';
import {
  UserContactDataSubmitDto,
  UserContactGoalDto,
  UserContactInfoDto,
} from '~/__generated__/query-service';
import StarsIcon from '~/assets/icons/stars.svg?react';
import { TableSkeleton } from '~/components/TableSkeleton';

const useStyles = makeStyles((theme) => ({
  table: {
    maxHeight: '100%',
    height: '100%',
  },
  loader: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  loaderWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  loaderInitialContent: {
    width: theme.spacing(22),
    height: theme.spacing(22),
  },
  underline: {
    textDecoration: 'underline',
  },
  tagHeader: {
    display: 'flex',
    textTransform: 'capitalize',
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
}));

type Props = {
  contacts: UserContactInfoDto[];
  isLoading?: boolean;
  children?: React.ReactNode;
  onClick: (contact: UserContactInfoDto) => void;
  selectedContactId?: string | null;
  variant?: TableProps<UserContactInfoDto>['variant'];
};

export const ContactTable = ({
  contacts,
  isLoading,
  onClick,
  children,
  selectedContactId,
  variant,
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();

  const getFullName = (contact: UserContactInfoDto) =>
    [contact.first_name, contact.last_name].filter(Boolean).join(' ');

  const getName = (item: UserContactGoalDto | UserContactDataSubmitDto) => {
    if ('goal_tag' in item) {
      return item.goal_tag;
    }

    if ('data_submit_tag' in item) {
      return item.data_submit_tag;
    }

    return '';
  };

  const getShortCell = (
    contact: UserContactInfoDto,
    key: 'data_submits' | 'goals'
  ) => {
    const data = contact[key] ?? [];

    if (!data?.length) return '';
    const [first, ...rest] = data;

    return (
      <Tooltip
        title={
          <>
            {data.map((d) => (
              <Typography key={d.chatbot_event_id}>{getName(d)}</Typography>
            ))}
          </>
        }
        {...(rest.length === 0 ? { open: false } : {})}
      >
        <Typography color="black">
          {getName(first)}{' '}
          <span className={classes.underline}>
            {rest?.length ? `+${rest.length}` : ''}
          </span>
        </Typography>
      </Tooltip>
    );
  };

  const contactWithTags = contacts.find((c) => c.tag_labels?.length);

  return (
    <Table
      hover
      variant={variant}
      data={contacts}
      getKey={({ id }) => id!}
      className={classes.table}
      isSelected={({ id }) => id === selectedContactId}
      onRowClicked={(item) => onClick(item)}
      headerColumns={
        <>
          <TableCell width={theme.spacing(18)} align="center">
            Date
          </TableCell>
          <TableCell align="center">User / Bot</TableCell>
          <TableCell align="center">Goals</TableCell>
          <TableCell width={theme.spacing(42)} align="center">
            Data Submit
          </TableCell>
          <TableCell width={theme.spacing(35)} align="center">
            Name
          </TableCell>
          <TableCell width={theme.spacing(35)} align="center">
            Email
          </TableCell>
          {contactWithTags?.tag_labels?.map(({ tag }) => (
            <TableCell key={tag} align="center">
              <div className={classes.tagHeader}>
                {tag}
                <StarsIcon />
              </div>
            </TableCell>
          ))}
          <TableCell align="right" width={40}></TableCell>
        </>
      }
      renderRow={(contact) => (
        <>
          <TableCell align="center">
            <Tooltip
              placement="right"
              interactive
              title={
                <Typography variant="body1">
                  <strong>Time:</strong>{' '}
                  {formatDate(
                    new Date(contact.last_message_sent_at!),
                    'hh:mm:ss'
                  )}
                </Typography>
              }
            >
              <Typography color="black">
                {formatDate(
                  new Date(contact.last_message_sent_at!),
                  'yyyy/M/dd'
                )}
              </Typography>
            </Tooltip>
          </TableCell>
          <TableCell align="center">
            <Typography color="black">
              {contact.user_message_count} / {contact.chatbot_message_count}
            </Typography>
          </TableCell>
          <TableCell align="center">{getShortCell(contact, 'goals')}</TableCell>
          <TableCell align="center">
            {getShortCell(contact, 'data_submits')}
          </TableCell>
          <TableCell align="center">
            <Typography color="black">{getFullName(contact)}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography color="black">
              {contact.email?.toLocaleLowerCase()}
            </Typography>
          </TableCell>
          {contact?.tag_labels?.map(({ tag, labels }) => (
            <TableCell key={tag} align="center">
              {labels?.map((label) => (
                <Typography key={label} color="black">
                  {label}
                </Typography>
              ))}
            </TableCell>
          ))}
          <TableCell>
            <KeyboardArrowRightIcon color="action" />
          </TableCell>
        </>
      )}
    >
      {isLoading && <TableSkeleton columns={7} rows={10} />}
      {!isLoading && contacts.length === 0 && (
        <tr>
          <td colSpan={8}>
            <Box mt={2}>
              <Typography color="black" align="center">
                No Users Available.
              </Typography>
            </Box>
          </td>
        </tr>
      )}
      {children}
    </Table>
  );
};
