import Axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import * as React from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';


import { routes } from '~/constants/routes';
import { useQueryParams } from '~/hooks/useQueryParams';
import { handleErrors } from '~/utils';
import Api from '~/utils/http';
import { getDefaultChatbot } from '~/utils/storageHelpers';

import type { AccessTokenDataType, LoginSchemaType } from './Login.types';

export const useLogin = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const [error, setError] = React.useState('');

  const getRedirectUrl = (accessToken: string): string => {
    const tokenData = jwtDecode<AccessTokenDataType>(accessToken);
    const defaultAgent = getDefaultChatbot(tokenData.account_id);

    const hasOnlyInstastack =
      tokenData.roles?.length === 1 &&
      tokenData.roles?.[0]?.toLowerCase() === 'instastack';

    if (hasOnlyInstastack) {
      return routes.instaStackData.get(defaultAgent);
    }

    const redirectUrl = queryParams.get('redirectUrl')
      ? new URL(`${window.location.origin}${queryParams.get('redirectUrl')}`)
      : null;

    redirectUrl?.searchParams.append('referrer', 'login');

    if (redirectUrl) {
      return `${redirectUrl.pathname}?${redirectUrl.searchParams.toString()}`;
    }

    return defaultAgent
      ? routes.dashboard.get(defaultAgent)
      : routes.chatbots.get();
  };

  const loginMutation = useMutation({
    mutationFn: (data: LoginSchemaType) =>
      Api.auth.login({
        body: { username: data.email!, password: data.password! },
      }),
    onSuccess: ({ data }) => {
      if (!data.access_token) {
        setError('Please provide a valid password and email');
        return;
      }

      localStorage.setItem('token', data.access_token);
      history.push(getRedirectUrl(data.access_token));
    },
    onError: (error) => {
      const isInvalidCredentialsError = (() => {
        if (!Axios.isAxiosError(error)) return false;

        if (error.response?.status === 401) return true;

        const { message } = error.response?.data as { message: string };
        return message === 'INVALID_CREDENTIALS';
      })();

      if (isInvalidCredentialsError) {
        setError('Please provide a valid password and email');
      } else {
        handleErrors(error);
      }
    },
  });

  const onSubmit = (data: LoginSchemaType) => {
    setError('');
    loginMutation.mutateAsync(data);
  };

  return {
    error,
    isLoading: loginMutation.isLoading,
    getRedirectUrl,
    onSubmit,
  };
};
