import { TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import * as React from 'react';

import { TableCell } from '@botco/library';

type Props = {
  /**
   * Number of rows to show
   * @default 3
   */
  rows?: number;
  /**
   * Number of columns to show
   * @default 3
   */
  columns?: number;
};

export const TableSkeleton: React.FC<Props> = ({ columns = 3, rows = 3 }) => {
  const columnsArray = Array.isArray(columns) ? columns : [...Array(columns)];

  const cells = columnsArray.map((_, idx) => (
    <TableCell key={`table-skeleton-cell-${idx}`}>
      <Skeleton role="progressbar" animation="pulse" />
    </TableCell>
  ));

  return (
    <>
      {[...Array(rows)].map((_, idx) => (
        <TableRow key={`table-skeleton-row-${idx}`}>{cells}</TableRow>
      ))}
    </>
  );
};
