
import type { paths } from '~/__generated__/botco-api-schema';
import type { ExtractRequestBodyFromPath } from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/instastack/document/upload/']['delete'];
type Body = ExtractRequestBodyFromPath<PathUrl>;

type Request = {
  body: Body;
};

export const deleteInstaStackDocument = ({ body }: Request) => {
  return axiosClient.delete('/instastack/document/upload/', { data: body });
};
