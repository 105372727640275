import axios from 'axios';

import { constants } from '~/config';

import type { PersonalDashboardMetric } from '../types';

type Params = {
  accountId: number;
  goals: string[];
  dataSubmits: string[];
};

export const getPersonalDashboardMetrics = ({
  accountId,
  goals,
  dataSubmits,
}: Params) =>
  axios.post<PersonalDashboardMetric[]>(
    'https://getdashboarddata-c6qhepyr5q-uc.a.run.app/',
    { goals, dataSubmits },
    { params: { orgID: accountId, env: constants.app_env } }
  );
