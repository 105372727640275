import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { Select, TextInput } from '@botco/library';
import { updateApiCardAction } from '~/redux/actions/conversationDataR';
import { IntentAction } from '~/utils/http/agent/types';

import { APIMethodItems } from '../../constants/options';

type PropsType = {
  action: IntentAction['api_action'] | undefined;
  orderIndex: number;
};

export const APIActionForm = (props: PropsType) => {
  const dispatch = useDispatch();

  const { allAPIs } = useSelector(({ conversationsDataR }) => ({
    allAPIs: conversationsDataR.allAPIs,
  }));

  const handleChange = (name: string, value: string | undefined) => {
    if (name === 'api_id') {
      const api = allAPIs.find((api) => `${api.api_id}` === `${value}`);
      dispatch(
        updateApiCardAction(props.orderIndex, {
          ...props.action,
          [name]: value,
          api_action_method: api?.api_method,
        })
      );

      return;
    }

    dispatch(
      updateApiCardAction(props.orderIndex, {
        ...props.action,
        [name]: value,
      })
    );
  };

  const {
    api_id = '',
    api_action_uri = '',
    api_action_body = '',
    api_action_method = '',
  } = props.action || {};

  const apiItems = allAPIs.map((api) => ({
    value: `${api.api_id}`,
    label: api.api_name,
  }));

  return (
    <Box display="flex" flexDirection="column" style={{ gap: '16px' }}>
      <Select
        fullWidth
        label="API ID"
        placeholder="Please Select API ID"
        options={apiItems}
        current={api_id?.toString()}
        onChange={(value) => handleChange('api_id', value)}
      />
      <Select
        fullWidth
        label="API Method"
        options={APIMethodItems}
        current={api_action_method}
        disabled={!api_id}
        placeholder="Please Select API Method"
        onChange={(value) => handleChange('api_action_method', value)}
      />
      <TextInput
        label="API Url"
        multiline
        fullWidth
        rows={5}
        name="api_action_uri"
        id="api_action_uri"
        placeholder="http://"
        value={api_action_uri || ''}
        onChange={(e) => handleChange('api_action_uri', e.target.value)}
      />
      <TextInput
        label="API Body"
        multiline
        fullWidth
        rows={5}
        name="api_action_body"
        id="api_action_body"
        value={api_action_body || ''}
        onChange={(e) => handleChange('api_action_body', e.target.value)}
      />
    </Box>
  );
};
