import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractPathParamsFromPath,
  ExtractOkResponseFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type Path = paths['/agents/{agentId}/intents/{intentId}/actions'];
type PathUrl = Path['get'];
type UrlParams = ExtractPathParamsFromPath<PathUrl>;
type Response = ExtractOkResponseFromPath<PathUrl>;

export const getAgentIntentActionsById = ({ agentId, intentId }: UrlParams) => {
  return axiosClient.get<Response>(
    `/agents/${agentId}/intents/${intentId}/actions`
  );
};
