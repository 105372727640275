import { Deployment, DeployType } from '~/utils/http/deployment/types';

import { DashboardFiltersSchemaType } from './DashboardProvider.types';

const STORAGE_KEY = 'botco:dashboard-filters' as const;

export const getDeploymentOptions = (
  deployments: Deployment[] = [],
  deployType?: number | null
) => {
  if (!deployments) return [];

  const filter = (deployment: Deployment) =>
    deployType ? deployment.deploy_type === deployType : true;

  const map = (deployment: Deployment) => ({
    value: deployment.deploy_id.toString(),
    label:
      deployment.deploy_type === DeployType.Facebook
        ? deployment.facebook_page_name!
        : deployment.deploy_name,
  });

  return deployments.filter(filter).map(map);
};

const getFilters = () => {
  try {
    return JSON.parse(sessionStorage.getItem(STORAGE_KEY) ?? '{}');
  } catch (_) {
    return {};
  }
};

export const getFiltersFromStorage = (
  accountId: number
): DashboardFiltersSchemaType | null => {
  const filters = getFilters();
  return filters[accountId] ?? null;
};

export const saveFiltersToStorage = (
  accountId: number,
  filters: DashboardFiltersSchemaType
) => {
  const savedFilters = getFilters();

  sessionStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      ...savedFilters,
      [accountId]: filters,
    })
  );
};
