import ReactApexChart from 'react-apexcharts';

import { QueryServiceMetricsConfigKey } from '~/types/query-service-helpers';
import { OverviewMetric } from '~/utils/http/dashboard/types';

import { getOverviewMetricsChartConfig } from './config';

type PropsType = {
  currentMetric: QueryServiceMetricsConfigKey;
  data: OverviewMetric['details'];
  previousData?: OverviewMetric['details'];
  showTitle?: boolean;
  color?: string;
};

export const DashboardOverviewMetricsChart = ({
  currentMetric,
  data,
  previousData,
  showTitle = true,
  color,
}: PropsType) => {
  const overviewChartConfig = getOverviewMetricsChartConfig({
    currentMetric,
    data,
    previousData,
    showTitle,
    color,
  });

  return (
    <ReactApexChart
      key={JSON.stringify([
        currentMetric,
        data,
        previousData,
        overviewChartConfig,
      ])}
      options={overviewChartConfig.options}
      series={overviewChartConfig.series}
      type="line"
      height="350"
    />
  );
};
