import { PaginatedResponse } from '@botco/api';
import { paths } from '~/__generated__/botco-api-schema';
import { ExtractQueryParamsFromPath } from '~/types/open-api-helpers';

import { InstaStackDocument } from './types';
import { axiosClient } from '../axiosClient';

type PathUrl = paths['/instastack/document/upload/']['get'];
export type GetInstaStackDocumentParams =
  ExtractQueryParamsFromPath<PathUrl> & {
    /**
     * @description Apply a filter to the documents based on their source: either uploaded by the user or generated by the webcrawler.<br><br>
     *
     * parsed: only return documents that has been generated by the webcrawler<br>
     * notParsed: only return documents that has been uploaded by the user
     */
    parseStatus?: 'parsed' | 'notParsed';
  };

export const getInstaStackDocuments = ({
  formats,
  ...rest
}: GetInstaStackDocumentParams = {}) => {
  return axiosClient.get<PaginatedResponse<InstaStackDocument[]>>(
    '/instastack/document/upload',
    { params: { ...rest, formats: formats?.join(',') } }
  );
};
