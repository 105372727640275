import {
  Popper as MuiPopper,
  Paper as MuiPaper,
  MenuList as MuiMenuList,
  MenuItem as MuiMenuItem,
  styled,
} from '@material-ui/core';
import { ColorPartial } from '@material-ui/core/styles/createPalette';

export const Popper = styled(MuiPopper)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
  background: theme.palette.common.white,
  left: '-48px !important',
  borderRadius: 10,
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  width: '100%',
  padding: '10px 10px 10px 16px',
  color: theme.palette.grey['900'],
  float: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '&:hover,&:focus,&.Mui-selected': {
    backgroundColor: (theme.palette.primary as ColorPartial)['300'],
  },
  '&.Mui-selected,&.Mui-selected > p': {
    fontWeight: theme.typography.fontWeightMedium as number,
  },
}));

export const Paper = styled(MuiPaper)(({ theme }) => ({
  background: theme.palette.common.white,
  padding: theme.spacing(1.25, 2),
  boxShadow: 'none',
}));

export const MenuList = styled(MuiMenuList)(({ theme }) => ({
  padding: 0,
  paddingLeft: theme.spacing(1),
  marginTop: theme.spacing(2),
  borderLeft: `1.5px solid ${(theme.palette as any).purple[400]}`,
}));
