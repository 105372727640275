import { handleActions } from 'redux-actions';

import { apiSuccess, apiFailure } from '../apiStatus';
import {
  CREATE_DEPLOYMENT,
  GET_AGENT_DEPLOYMENTS,
  GET_FACEBOOK_MENUS,
  CREATE_FACEBOOK_MENU,
  CREATE_FACEBOOK_MENU_ITEM,
  UPDATE_FACEBOOK_MENU_ITEM,
  DELETE_FACEBOOK_MENU_ITEM,
  DELETE_FACEBOOK_MENU_ALL_ITEMS,
} from '../types/deployments';

const initialState = {
  status: 'INIT',
  deployments: [],
  fbMenu: {},
  fbMenuItems: [],
  error: null,
};

export const deploymentsReducer = handleActions(
  {
    [apiSuccess(CREATE_DEPLOYMENT)]: (state, { payload: { data } }) => ({
      ...state,
      status: apiSuccess(CREATE_DEPLOYMENT),
      deployments: [...state.deployments, data],
      error: null,
    }),
    [apiFailure(CREATE_DEPLOYMENT)]: (state, { payload }) => ({
      ...state,
      status: apiFailure(CREATE_DEPLOYMENT),
      error: payload,
    }),
    [apiSuccess(GET_AGENT_DEPLOYMENTS)]: (state, { payload: { data } }) => ({
      ...state,
      status: apiSuccess(GET_AGENT_DEPLOYMENTS),
      deployments: data,
      error: null,
    }),
    [apiFailure(GET_AGENT_DEPLOYMENTS)]: (state, { payload }) => ({
      ...state,
      status: apiFailure(GET_AGENT_DEPLOYMENTS),
      error: payload,
    }),
    [apiSuccess(GET_FACEBOOK_MENUS)]: (state, { payload: { data } }) => ({
      ...state,
      status: apiSuccess(GET_FACEBOOK_MENUS),
      fbMenu: data && data.length ? data[0] : {},
      fbMenuItems: data && data.length ? data[0].fb_menu_items : [],
      error: null,
    }),
    [apiFailure(GET_FACEBOOK_MENUS)]: (state, { payload }) => ({
      ...state,
      status: apiFailure(GET_FACEBOOK_MENUS),
      error: payload,
    }),
    [apiSuccess(CREATE_FACEBOOK_MENU)]: (state, { payload: { data } }) => ({
      ...state,
      status: apiSuccess(CREATE_FACEBOOK_MENU),
      fbMenu: data,
      fbMenuItems: [],
      error: null,
    }),
    [apiFailure(CREATE_FACEBOOK_MENU)]: (state, { payload }) => ({
      ...state,
      status: apiFailure(CREATE_FACEBOOK_MENU),
      error: payload,
    }),
    [apiSuccess(CREATE_FACEBOOK_MENU_ITEM)]: (
      state,
      { payload: { data } }
    ) => ({
      ...state,
      status: apiSuccess(CREATE_FACEBOOK_MENU_ITEM),
      fbMenuItems: [...state.fbMenuItems, data],
    }),
    [apiFailure(CREATE_FACEBOOK_MENU_ITEM)]: (state, { payload }) => ({
      ...state,
      status: apiFailure(CREATE_FACEBOOK_MENU_ITEM),
      error: payload,
    }),
    [apiSuccess(UPDATE_FACEBOOK_MENU_ITEM)]: (
      state,
      { payload: { data } }
    ) => ({
      ...state,
      status: apiSuccess(UPDATE_FACEBOOK_MENU_ITEM),
      fbMenuItems: state.fbMenuItems.map((each) =>
        each.fb_menu_item_id === data.fb_menu_item_id ? data : each
      ),
    }),
    [apiFailure(UPDATE_FACEBOOK_MENU_ITEM)]: (state, { payload }) => ({
      ...state,
      status: apiFailure(UPDATE_FACEBOOK_MENU_ITEM),
      error: payload,
    }),
    [apiSuccess(DELETE_FACEBOOK_MENU_ITEM)]: (
      state,
      { payload: { data } }
    ) => ({
      ...state,
      status: apiSuccess(DELETE_FACEBOOK_MENU_ITEM),
      fbMenuItems: state.fbMenuItems.filter(
        (fbMenuItem) => fbMenuItem.fb_menu_item_id !== data.id
      ),
    }),
    [apiFailure(DELETE_FACEBOOK_MENU_ITEM)]: (state, { payload }) => ({
      ...state,
      status: apiFailure(DELETE_FACEBOOK_MENU_ITEM),
      error: payload,
    }),
    [apiSuccess(DELETE_FACEBOOK_MENU_ALL_ITEMS)]: (
      state,
      { payload: { data } }
    ) => ({
      ...state,
      status: apiSuccess(DELETE_FACEBOOK_MENU_ALL_ITEMS),
      fbMenuItems: state.fbMenuItems.filter(
        (fbMenuItem) => fbMenuItem.fb_menu_id !== data.id
      ),
    }),
    [apiFailure(DELETE_FACEBOOK_MENU_ALL_ITEMS)]: (state, { payload }) => ({
      ...state,
      status: apiFailure(DELETE_FACEBOOK_MENU_ALL_ITEMS),
      error: payload,
    }),
  },
  initialState
);
