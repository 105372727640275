import { AiPreviewResponse } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  prompt: string;
};

export const setAiPreview = ({ prompt }: Options) => {
  return axiosClient.post<AiPreviewResponse>('/set-ai-preview', {
    model: 'gpt-3.5-turbo',
    question: prompt,
    temperature: 0.7,
    topP: 0.9,
    maxTokens: 250,
  });
};
