import type { AxiosResponse } from 'axios';

import type { CreateDeployment, Deployment } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  payload: CreateDeployment;
};

export const createDeployment = ({ payload }: Options) => {
  return axiosClient.post<CreateDeployment, AxiosResponse<Deployment>>(
    '/deployments',
    payload
  );
};
