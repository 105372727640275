import Typography from '@material-ui/core/Typography';

import { Button, TextEditor } from '@botco/library';

import { AttributeTextEditorProps } from './AttributeTextEditor.types';
import { useAttributeTextEditor } from './useAttributeTextEditor';
import { AttributesList } from '../AttributesList';

export const AttributeTextEditor = ({
  onChange,
  initialValue,
  agentId,
  additionalAttributes,
  ...props
}: AttributeTextEditorProps) => {
  const {
    editor,
    onEditorChange,
    value,
    onAddAttributeClicked,
    onCloseMenu,
    anchorEl,
    onInsertAttribute,
    hiddenDiv,
    target,
    search,
    onKeyDown,
    isInvalidAttributeName,
  } = useAttributeTextEditor({
    onChange,
    initialValue,
  });

  return (
    <TextEditor.Editor
      {...props}
      editor={editor}
      onChange={onEditorChange}
      value={value}
      onKeyDown={onKeyDown}
    >
      <TextEditor.Actions>
        <Button
          data-testid="insert-attribute-button"
          variant="text"
          onClick={onAddAttributeClicked}
        >
          <Typography variant="caption">Add Attribute</Typography>
        </Button>
        <AttributesList
          filter={search}
          agentId={agentId}
          onClose={onCloseMenu}
          enableHover={Boolean(target)}
          anchorEl={target ? hiddenDiv.current : anchorEl}
          onSelect={onInsertAttribute}
          isInvalidAttributeName={isInvalidAttributeName}
          disableCreate
          additionalAttributes={additionalAttributes}
        />
        <div
          ref={hiddenDiv}
          style={{
            height: 0,
            width: 0,
            position: 'fixed',
          }}
          data-testid="hidden-div"
        />
      </TextEditor.Actions>
    </TextEditor.Editor>
  );
};
