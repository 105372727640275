import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AgentType } from '~/containers/Conversations/ConversationsList/types';
import { setSelectedAgent } from '~/redux/actions/conversationNew';
import {
  saveDefaultChatbot,
  unsetDefaultChatbot,
} from '~/utils/storageHelpers';

import { useCurrentUser } from './useCurrentUser';

export const useSelectedChatbot = () => {
  const dispatch = useDispatch();
  const user = useCurrentUser();

  const selectedAgent: AgentType | null = useSelector(
    ({ conversationData }) => conversationData.selectedAgent
  );

  const setSelectChatbot = React.useCallback(
    (agent: AgentType) => {
      dispatch(setSelectedAgent(agent));
      saveDefaultChatbot(agent);
    },
    [dispatch]
  );

  const unsetSelectedChatbot = React.useCallback(() => {
    dispatch(setSelectedAgent(null));
    unsetDefaultChatbot(user.account_id);
  }, [dispatch, user.account_id]);

  return {
    selectedAgent,
    unsetSelectedChatbot,
    setSelectChatbot,
  };
};
