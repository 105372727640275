import { constants } from '~/config';

export const initFBSDK = () => {
  const timerID = setInterval(() => {
    const FB = window.FB;
    if (!FB) return;

    window.FB.init({
      appId: constants.fbappid,
      cookie: true,
      xfbml: true,
      version: constants.fbapivers,
    });

    clearInterval(timerID);
  }, 1000);
};
