import { IntentInputs } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  agentId: number;
  intentId: number;
  inputs: IntentInputs[];
};

export const updateAgentIntentInputs = ({
  agentId,
  intentId,
  inputs,
}: Options) => {
  return axiosClient.put<IntentInputs[]>(
    `/agents/${agentId}/intents/${intentId}/inputs`,
    inputs
  );
};
