import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractOkResponseFromPath,
  ExtractPathParamsFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/agents/{agentId}/intents/{intentId}/connections']['get'];
type Response = ExtractOkResponseFromPath<PathUrl>;
type Request = ExtractPathParamsFromPath<PathUrl>;

export const getAgentIntentConnections = ({ agentId, intentId }: Request) => {
  return axiosClient.get<Response>(
    `/agents/${agentId}/intents/${intentId}/connections`
  );
};
