//`/deployments/exchange-fb-token`

import { AxiosResponse } from 'axios';

import { axiosClient } from '../axiosClient';

type Request = {
  facebook_access_token: string;
  facebook_page_id: string;
  facebook_user_id: string;
};

type Response = {
  longlived_page_access_token: string;
};

export const exchangeFacebookToken = async (data: Request) => {
  return axiosClient.post<Request, AxiosResponse<Response>>(
    `/deployments/exchange-fb-token`,
    data
  );
};
