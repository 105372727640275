import { Paper, makeStyles } from '@material-ui/core';

import { Typography } from '@botco/library';
import { Transcript } from '~/components/Transcript';

type Props = { contactId: string };

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: theme.spacing(16),
  },
  title: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    padding: theme.spacing(2),
    backgroundColor: '#fafafa',
  },
}));

export const ContactTranscripts = ({ contactId }: Props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography fontWeight="700" align="center" className={classes.title}>
        Transcripts
      </Typography>
      <Transcript userId={contactId} displayTitle={false} minWidth="unset" />
    </Paper>
  );
};
