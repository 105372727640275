/* eslint-disable no-redeclare */

export const ApiJsonTypesEnum = {
  None: '0',
  Text: 'TEXT',
  Attachment: 'ATTACHMENT',
  QuickReply: 'QUICKREPLIES',
  Button: 'BUTTONS',
} as const;

export type ApiJsonTypesEnum = EnumValueType<typeof ApiJsonTypesEnum>;

export const ApiJSONTypeChoicesItems = [
  { value: ApiJsonTypesEnum.None, label: 'Select JSON Type' },
  { value: ApiJsonTypesEnum.Text, label: 'Text' },
  { value: ApiJsonTypesEnum.Attachment, label: 'Attachment' },
  { value: ApiJsonTypesEnum.QuickReply, label: 'Quick Reply' },
  { value: ApiJsonTypesEnum.Button, label: 'Button' },
];

export const API_METHOD_TYPES = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
} as const;

export const APIMethodItems = [
  { value: API_METHOD_TYPES.GET, label: 'GET' },
  { value: API_METHOD_TYPES.POST, label: 'POST' },
  { value: API_METHOD_TYPES.PUT, label: 'PUT' },
];

export const API_RESPONSE_TYPES = {
  JSON_TEMPLATES: 'JSON_TEMPLATES',
  SET_ATTRIBUTE: 'SET_ATTRIBUTE',
} as const;

export type API_RESPONSE_TYPES = EnumValueType<typeof API_RESPONSE_TYPES>;

export const APIResponseChoiceItems = [
  { value: API_RESPONSE_TYPES.JSON_TEMPLATES, label: 'Json Templates' },
  { value: API_RESPONSE_TYPES.SET_ATTRIBUTE, label: 'Set Attribute' },
];
