import { Box, Grid, GridProps, makeStyles, useTheme } from '@material-ui/core';

import { Typography } from '@botco/library';

type PropsType = {
  children: React.ReactNode;
  title: string;
  className?: string;
  gridProps?: GridProps;
};

const useStyles = makeStyles((theme) => ({
  label: {
    flex: 1,
    paddingTop: theme.spacing(1),
  },
}));

export const FilterFormItem = ({
  children,
  title,
  className,
  gridProps,
}: PropsType) => {
  const classes = useStyles();
  const theme = useTheme();
  const width = theme.spacing(16);

  return (
    <Grid item xs={12} sm={6} {...gridProps}>
      <Box display="flex" className={className}>
        <Box minWidth={width} width={width} maxWidth={width}>
          <Typography className={classes.label} fontWeight="600">
            {title}:
          </Typography>
        </Box>
        {children}
      </Box>
    </Grid>
  );
};
