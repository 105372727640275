export type PaginatedResult<T> = {
  data: T[];
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
};

export const paginate = <T>(
  items: T[],
  currentPage: number = 1,
  itemsPerPage: number = 10
): PaginatedResult<T> => {
  const totalItems = items.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedItems = items.slice(startIndex, endIndex);

  return {
    data: paginatedItems,
    currentPage,
    itemsPerPage,
    totalItems,
    totalPages,
  };
};
