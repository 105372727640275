import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractOkResponseFromPath,
  ExtractQueryParamsFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/trainings']['get'];
type Response = ExtractOkResponseFromPath<PathUrl>;
type Request = ExtractQueryParamsFromPath<PathUrl>;

export async function getTrainings({
  start_time,
  // agent_id,
  deploy_id,
  page,
  size,
  confidence,
}: Request) {
  return axiosClient.get<Response>('/trainings', {
    params: {
      start_time,
      // agent_id,
      page,
      deploy_id,
      size,
      confidence,
    },
  });
}
