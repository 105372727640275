import { ContactDataSubmitApiRequest } from './types';
import { axiosClient } from '../axiosClient';

type Params = {
  contactId: number;
  exportId: number;
};

export const getContactApiRequests = (params: Params) => {
  return axiosClient.get<ContactDataSubmitApiRequest[]>(
    `/customers/${params.contactId}/exports/${params.exportId}`
  );
};
