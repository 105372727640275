import '@collabkit/react/dist/style.css';
import './collaborator.css';

import { CollabKitProvider } from '@collabkit/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { constants } from '~/config';
import { selectUserDetails } from '~/redux/selectors/conversationsDataR';

type Props = React.PropsWithChildren<{}>;

export const CollaboratorProvider = ({ children }: Props) => {
  const history = useHistory();
  const userDetails = useSelector(selectUserDetails);

  const isProd = ['stage', 'production'].includes(constants.app_env);
  const ENV = isProd ? 'production' : constants.app_env;

  return (
    <CollabKitProvider
      appId={constants.collabKitAppId}
      apiKey={constants.collabKitApiKey}
      user={{
        id: userDetails.user_id?.toString(),
        userId: userDetails.user_id?.toString(),
        name: userDetails.userName,
        email: userDetails.email,
        avatar: userDetails.user_avatar,
      }}
      workspace={{
        id: `botco_${ENV}_${userDetails.account_id}`,
        name: `Botco ${isProd ? '' : ENV} ${userDetails.account_id}`,
      }}
      mentionableUsers="allWorkspace"
      onPinHover={() => {}}
      onPinAttach={() => {
        return JSON.stringify({
          pathname: history.location.pathname,
          search: history.location.search,
        });
      }}
      onPinClick={(props) => {
        try {
          if (!props.meta) return;
          const meta = JSON.parse(props.meta) as any | null;
          if (meta?.pathname) {
            history.push({ pathname: meta.pathname, search: meta.search });
          }
        } catch (e) {
          /* empty */
        }
      }}
      onPinUnhover={() => {}}
      onPinDeselect={() => {}}
    >
      {children}
    </CollabKitProvider>
  );
};
