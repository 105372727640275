/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { QueryParamsDto } from '../models/QueryParamsDto';
import type { QueryResultsDto } from '../models/QueryResultsDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class QueryService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Query
   * Query endpoint
   * @returns QueryResultsDto OK
   * @throws ApiError
   */
  public query({
    requestBody,
  }: {
    requestBody?: QueryParamsDto,
  }): CancelablePromise<QueryResultsDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/query',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
