import type { paths } from '~/__generated__/botco-api-schema';
import type { ExtractPathParamsFromPath } from '~/types/open-api-helpers';

import type { Deployment } from './types';
import { axiosClient } from '../axiosClient';

type PathUrl = paths['/deployments/{deployId}/start']['put'];
type UrlParams = ExtractPathParamsFromPath<PathUrl>;

type Options = {
  params: UrlParams;
};

export const startDeployment = ({ params }: Options) => {
  return axiosClient.put<Deployment>(`/deployments/${params.deployId}/start`);
};
