import { isValidAgentId } from '~/utils/agentUtils';

import type { ReduxState } from '../reducers/types';

export const selectAgents = ({ conversationsDataR }: ReduxState) =>
  conversationsDataR.allAgents?.filter((agent) => isValidAgentId(agent.id)) ??
  [];

export const selectDeploymentsByAccount = ({
  conversationsDataR,
}: ReduxState) => conversationsDataR.allDeploymentsByAccount;

export const selectBlocks = ({ conversationsDataR }: ReduxState) =>
  conversationsDataR.allBlocks;

export const selectForms = ({ conversationsDataR }: ReduxState) =>
  conversationsDataR.formsData;

export const selectIntentCards = ({ conversationsDataR }: ReduxState) =>
  conversationsDataR.intentCardsData;

export const selectUserDetails = ({ conversationsDataR }: ReduxState) =>
  conversationsDataR.loggedInUserDetails;
