import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import * as React from 'react';

import { Button, TextInput } from '@botco/library';

import { IntentApiParam } from '../../APICard.types';

type PropsType = {
  open: boolean;
  parameter?: IntentApiParam;
  handleClose: () => void;
  handleAddParam: (parameter: IntentApiParam) => void;
};

export const APIParameterModal = ({
  open,
  parameter: initialValue,
  handleClose,
  handleAddParam,
}: PropsType) => {
  const classes = useStyles();
  const [parameter, setParameter] = React.useState<IntentApiParam>(
    initialValue ?? { api_param_name: '', api_param_value: '' }
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setParameter((param) => ({ ...param, [name]: value }));
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
      <DialogContent>
        <TextInput
          fullWidth
          name="api_param_name"
          label="Parameter Name (Match String)"
          onChange={handleChange}
          value={parameter.api_param_name}
          placeholder="Name Parameter"
        />
        <Box mt={2}>
          <TextInput
            fullWidth
            name="api_param_value"
            label="Value"
            onChange={handleChange}
            value={parameter.api_param_value}
            placeholder="Enter Value"
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          id="api-card-add-parameter-modal-ok"
          onClick={() => handleAddParam(parameter)}
        >
          Add Parameter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  actions: {
    justifyContent: 'center',
  },
}));
