import {
  AppBar,
  Box,
  makeStyles,
  Popover,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import * as React from 'react';

import { Button, Checkbox } from '@botco/library';
import Slider from '~/assets/icons/slider-horizontal.svg?react';
import { formatDateString } from '~/utils';

import { DashboardFilterForm } from './components/FilterForm';
import { useDashboardContext } from '../Provider/DashboardProvider';

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginTop: theme.spacing(-5),
    position: 'sticky',
    top: theme.spacing(-5),
    borderRadius: theme.spacing(0, 0, 1, 1),
  },
  toolbar: {
    height: theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
    '& label': {
      marginBottom: 0,
    },
  },
  slider: {
    marginLeft: theme.spacing(1),
    color: 'inherit',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  calendar: {
    marginLeft: theme.spacing(1),
  },
}));

export const DashboardFilters = () => {
  const toolbarRef = React.useRef<HTMLDivElement>(null);

  const [filterAnchorEl, setFilterAnchorEl] = React.useState<HTMLDivElement>();

  const classes = useStyles();
  const { filters, setFilters } = useDashboardContext();

  const handleViewAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      viewAll: e.target.checked,
      deployType: null,
      deployId: null,
    });
  };

  const isFiltered = Boolean(
    filters.attributes.conditions.some((c) => Boolean(c.value)) ||
      filters.deployType
  );

  const displayStartTime = formatDateString(filters.startTime);
  const displayEndTime = formatDateString(filters.endTime);

  return (
    <AppBar
      position="relative"
      color="inherit"
      className={classes.appBar}
      elevation={1}
    >
      <Toolbar
        className={classes.toolbar}
        ref={toolbarRef}
        onClick={(e) => {
          if (e.target instanceof HTMLElement) {
            switch (e.target.id) {
              case 'filter-button':
              case 'dashboard-filters-date':
                setFilterAnchorEl(e.currentTarget);
                break;
            }
          }
        }}
      >
        <Checkbox
          label="View All Chatbots"
          checked={filters.viewAll}
          onChange={handleViewAllChange}
        />
        <Box flexGrow={1} />
        <Box display="flex" style={{ gap: 16 }}>
          <Button
            variant="outlined"
            color={isFiltered ? 'primary' : 'grey'}
            id="filter-button"
          >
            <Typography color="inherit" id="filter-button">
              Apply Filters
            </Typography>
            <Slider className={classes.slider} id="filter-button" />
          </Button>
          <Button variant="outlined" color="grey" id="dashboard-filters-date">
            <Typography color="inherit" id="dashboard-filters-date">
              {`${displayStartTime} - ${displayEndTime}`}
            </Typography>
            <CalendarIcon
              fontSize="small"
              color="inherit"
              id="dashboard-filters-date"
              className={classes.calendar}
            />
          </Button>
        </Box>
        {Boolean(filterAnchorEl) && (
          <Popover
            anchorReference="anchorEl"
            anchorEl={filterAnchorEl}
            open={Boolean(filterAnchorEl)}
            onClose={() => setFilterAnchorEl(undefined)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            PaperProps={{
              elevation: 1,
            }}
            TransitionProps={{ appear: true }}
            transformOrigin={{
              vertical: 16,
              horizontal: 'center',
            }}
          >
            <Box width={toolbarRef.current?.clientWidth} pr={2} pl={4.5} py={3}>
              <DashboardFilterForm
                onClose={() => setFilterAnchorEl(undefined)}
              />
            </Box>
          </Popover>
        )}
      </Toolbar>
    </AppBar>
  );
};
