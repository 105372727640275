import type { AgentApis } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  agentId: number;
};

export const getAgentApis = ({ agentId }: Options) => {
  return axiosClient.get<AgentApis[]>(`/agents/${agentId}/apis`);
};
