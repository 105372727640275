import * as yup from 'yup';

import { paths } from '~/__generated__/botco-api-schema';
import { ExtractQueryParamsFromPath } from '~/types/open-api-helpers';

import { ContactDetail } from './types';
import { axiosClient } from '../axiosClient';

type PathUrl = paths['/customers/{customerId}']['get'];
type QueryParams = ExtractQueryParamsFromPath<PathUrl>;

type Params = {
  contactId: string;
  queryParams?: QueryParams;
};

export const getContactDetails = ({ contactId, queryParams }: Params) => {
  if (yup.string().uuid().isValidSync(contactId)) {
    return axiosClient.get<ContactDetail[]>(`/customers/uid/${contactId}`);
  }

  return axiosClient.get<ContactDetail[]>(
    `/customers/${parseInt(contactId, 10)}`,
    { params: queryParams }
  );
};
