import { makeStyles } from '@material-ui/core';
import * as React from 'react';

import { SearchInput, Table, TableCell, Typography } from '@botco/library';
import { AttributeCountByNameValueDto } from '~/__generated__/query-service';
import { Attributes } from '~/components/Attributes';
import { SpinnerLoader } from '~/components/SpinnerLoader';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useQueryServiceApi } from '~/hooks/useQueryServiceApi';
import { QueryServiceMetricsConfig } from '~/types/query-service-helpers';

type Props = {
  contactId: string;
};

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& > div': {
      maxWidth: '200px',
    },
  },
  table: {
    maxHeight: 'unset',
    flex: '1 1 0',
  },
  container: {
    flex: '1 1 0',
    display: 'flex',
    flexDirection: 'column',
  },
  value: {
    wordBreak: 'break-word',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '12px',
  },
  searchColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export const ContactAttributesTable = ({ contactId }: Props) => {
  const user = useCurrentUser();
  const classes = useStyles();
  const [filter, setFilter] = React.useState('');

  const { isLoading, data } = useQueryServiceApi(
    ['contacts', 'attributes', contactId],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: {
            user_id: contactId,
            customer_account: {
              external_id: user.account_id,
            },
          },
          projection: [QueryServiceMetricsConfig.Attributes],
        },
      })
  );

  const attributes = data?.attributes?.content?.counts_by_name_value ?? [];

  const filterData = ({ name, value }: AttributeCountByNameValueDto): boolean =>
    name!.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
    value!.toLocaleLowerCase().includes(filter.toLocaleLowerCase());

  return (
    <div className={classes.container}>
      <Table
        variant="plain"
        className={classes.table}
        isLoading={isLoading}
        loadingComponent={<SpinnerLoader />}
        data={filter ? attributes.filter(filterData) : attributes}
        getKey={(attr) => `${attr.name!}_${attr.value!}`}
        headerColumns={
          <>
            <TableCell>Attribute</TableCell>
            <TableCell>
              <div className={classes.searchColumn}>
                Value
                <div className={classes.filter}>
                  <SearchInput
                    value={filter}
                    name="filter"
                    onChange={(_, value) => setFilter(value)}
                  />
                </div>
              </div>
            </TableCell>
          </>
        }
        renderRow={({ name, value }) => (
          <>
            <TableCell>
              <Attributes.Chip
                dataTestId={`attribute-${name}`}
                attribute={name!}
              />
            </TableCell>
            <TableCell>
              <Typography className={classes.value}>{value}</Typography>
            </TableCell>
          </>
        )}
      />
    </div>
  );
};
