import { constants } from '~/config';

import { firebaseClient } from '../../firebaseClient';
import type { DashboardSettings } from '../types';

type Params = {
  accountId: number;
  agentId: number;
};

export const getDashboardSettings = ({ accountId, agentId }: Params) =>
  firebaseClient.get<DashboardSettings>('/dashboardSettings', {
    params: { orgID: accountId, chatbotID: agentId, env: constants.app_env },
  });
