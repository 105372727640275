import { createStyles, IconButton, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';

import defaultAvatar from '~/assets/img/default-bot-image.png';
import EditImageModal from '~/containers/Conversations/ModalSummary/EditImageModal';

import { AgentType } from '../../types';

type PropsType = {
  agent: AgentType;
  isEditing: boolean;
  onSelectAgent: (agent: AgentType) => void;
  toggleIsEditing: () => void;
};

export const AgentsListAvatar = ({
  agent,
  isEditing,
  onSelectAgent,
  toggleIsEditing,
}: PropsType) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const avatarSrc =
    agent.avatar && agent.avatar !== 'null' && agent.avatar !== 'undefined'
      ? agent.avatar
      : defaultAvatar;

  const handleClick = () => {
    onSelectAgent(agent);
  };

  const handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    toggleIsEditing();
  };

  return (
    <>
      <div
        role="button"
        className={classes.avatar}
        onClick={handleClick}
        data-testid="agent-list-card-avatar"
      >
        <img className={classes.image} alt="avatar" src={avatarSrc} />
        {isEditing && (
          <IconButton
            id="agent-list-card-avatar-edit"
            data-testid="agent-list-card-avatar-edit"
            className={classes.editButton}
            onClick={handleEdit}
            aria-label="Edit Avatar"
          >
            <EditIcon />
          </IconButton>
        )}
      </div>
      {isModalOpen && (
        <EditImageModal
          // @ts-ignore
          openEditImage
          agentDetails={agent}
          closed={handleModalClose}
          avatarImage={agent.avatar}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      margin: theme.spacing(2, 0),
      display: 'inline-block',
      position: 'relative',
      minHeight: 150,
      overflow: 'hidden',
    },
    image: {
      maxHeight: 150,
      borderRadius: '50%',
      objectFit: 'fill',
    },
    editButton: {
      position: 'absolute',
      left: theme.spacing(1),
      bottom: theme.spacing(1),
      width: theme.spacing(4),
      height: theme.spacing(4),
      backgroundColor: theme.palette.grey['400'],

      '&:hover': {
        backgroundColor: theme.palette.grey['400'],
      },

      '& svg': {
        width: 20,
        height: 20,
      },
    },
  })
);
