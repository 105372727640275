import { Drawer, TablePagination } from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { UserContactInfoDto } from '~/__generated__/query-service';
import { AccessDenied } from '~/components/AccessDenied';
import { ContactDetails } from '~/containers/Contacts/ContactDetails';
import { ContactTable } from '~/containers/Contacts/Contacts/components/ContactsTable';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useQueryParams } from '~/hooks/useQueryParams';
import { useQueryServiceApi } from '~/hooks/useQueryServiceApi';
import { QueryServiceMetricsConfig } from '~/types/query-service-helpers';

import { DashboardCard } from '../components/Card/DashboardCard';
import { useDashboardQueryServiceContext } from '../Provider/DasboardQueryServiceProvider';

const PAGE_SIZE = 10;
const SELECTED_CONTACT_QUERY_PARAM = 'contactId';

export const DashboardContacts = () => {
  const user = useCurrentUser();
  const history = useHistory();
  const queryParams = useQueryParams();

  const [page, setPage] = React.useState(0);

  const { conditions } = useDashboardQueryServiceContext();

  const { data, isLoading } = useQueryServiceApi(
    ['dashboard', 'contacts', conditions, page],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: conditions!,
          projection: [
            {
              ...QueryServiceMetricsConfig.Contacts,
              options: {
                'metric/engaged': 'true',
                'contacts/page_number': (page + 1).toString(),
                'contacts/page_items': PAGE_SIZE.toString(),
              },
            },
          ],
        },
      }),
    { enabled: user.hasPIIRole }
  );

  const setSelectedContact = (contact: UserContactInfoDto | null) => {
    if (contact) {
      queryParams.set(SELECTED_CONTACT_QUERY_PARAM, contact.id!);
    } else {
      queryParams.delete(SELECTED_CONTACT_QUERY_PARAM);
    }

    history.replace({ search: queryParams.toString() });
  };

  if (!user.hasPIIRole) {
    return (
      <DashboardCard title="Contacts">
        <AccessDenied />
      </DashboardCard>
    );
  }

  const contacts = data?.users?.content?.contacts?.page_items ?? [];
  const totalCount = data?.users?.content?.contacts?.items_total ?? 0;
  const selectedContactId = queryParams.get(SELECTED_CONTACT_QUERY_PARAM);
  const selectedContact = contacts.find((c) => c.id === selectedContactId);

  return (
    <>
      <DashboardCard title="Users">
        <ContactTable
          variant="transparent"
          contacts={contacts}
          isLoading={isLoading}
          onClick={setSelectedContact}
          selectedContactId={selectedContactId}
        />
        <TablePagination
          rowsPerPageOptions={[]}
          count={totalCount}
          colSpan={5}
          component="div"
          rowsPerPage={PAGE_SIZE}
          page={page}
          onPageChange={(_, page) => setPage(page)}
        />
      </DashboardCard>
      {selectedContactId && (
        <Drawer open anchor="right" onClose={() => setSelectedContact(null)}>
          <ContactDetails
            {...(selectedContact
              ? { contact: selectedContact }
              : { contactId: selectedContactId })}
          />
        </Drawer>
      )}
    </>
  );
};
