import { IntentInputs } from './types';
import { axiosClient } from '../axiosClient';

type Options = {
  agentId: number;
  intentId: number;
  inputs: Pick<IntentInputs, 'input_value'>[];
};

export const createAgentIntentInputs = ({
  agentId,
  intentId,
  inputs,
}: Options) => {
  return axiosClient.post<IntentInputs[]>(
    `/agents/${agentId}/intents/${intentId}/inputs`,
    inputs as IntentInputs[]
  );
};
