import { components } from '~/__generated__/botco-api-schema';

export type DeployAttributes = Required<
  components['schemas']['DeployAttributeDto']
>;

export type Deployment = {
  agent_id: number;
  agent_name: string;
  api_key: string;
  current_version?: any;
  deploy_attributes: DeployAttributes;
  deploy_error: string;
  deploy_id: number;
  deploy_name: string;
  deploy_url: string;
  deploy_progress: number;
  deploy_status: number;
  deploy_type: number;
  facebook_page_access_token?: string;
  facebook_page_id?: string;
  facebook_page_name?: string;
  facebook_version?: any;
  greeting?: string;
  is_sandbox: boolean;
  enable_phi_masking: boolean;
  latest_version: string;
  latest_version_summary?: any;
  outdated: boolean;
  persistent: boolean;
  queue_in_name: string;
  queue_out_name: string;
  request_status: number;
  request_type: number;
  runtime_tenant_id: number;
  update_date?: Date;
  update_version_msg: string;
  version_url: string;
  rules?: Rule;
};

export const DataSubmitMappingTargetType = {
  NOT_CONFIGURED: 0,
  EMAIL: 1,
  CRM: 2,
  CRM_AND_EMAIL: 3,
} as const;

export type DataSubmitMappingTargetTypeValue = EnumValueType<
  typeof DataSubmitMappingTargetType
>;

export type DataSubmitMappingPostEmail = {
  recepient: string[];
  subject: string;
  sender: Readonly<string>;
  body: string;
  userCopy: Pick<DataSubmitMappingPostEmail, 'body' | 'sender' | 'subject'> & {
    recepient: string;
  };
};

type DataSubmitMappingPostCRM = Omit<DataSubmitMappingPostEmail, 'userCopy'>;

export type DataSubmitMapping = {
  id: number;
  name: string;
  prod_authorized_flag?: boolean;
} & (
  | {
      type: typeof DataSubmitMappingTargetType.NOT_CONFIGURED;
      data: {};
    }
  | {
      type:
        | (typeof DataSubmitMappingTargetType)['EMAIL']
        | (typeof DataSubmitMappingTargetType)['CRM_AND_EMAIL'];
      data: DataSubmitMappingPostEmail;
    }
  | {
      type: (typeof DataSubmitMappingTargetType)['CRM'];
      data: DataSubmitMappingPostCRM;
    }
);

export type CreateDeployment = {
  deploy_name: string;
  deploy_url?: string;
  deploy_type: number;
  api_key: string;
  agent_id: number;
  is_sandbox: boolean;
  enable_phi_masking: boolean;
  attribute: {
    api_key: string | null;
    deploy_name: string | null;
    header_title: string | null;
    header_background_color: string | null;
    header_text_color: string | null;
    chat_response_color: string | null;
    chat_response_text_color: string | null;
    logo_url: string | null;
    bot_url: string | null;
    welcome_message: string | null;
    zendesk_api_key?: string | null;
  };
  greeting?: string;
  persistent: boolean;
  target_configuration_entries: DataSubmitMapping[];
  runtime_tenant_id: null;
  facebook_page_id: null | string;
  facebook_page_access_token: null | string;
  facebook_page_name: null | string;
  rules: Rule;
};

export type UpdateDeployment = {
  deploy_name: string;
  deploy_url?: string;
  deploy_type: number;
  api_key: string | null;
  agent_id: number;
  is_sandbox: boolean;
  enable_phi_masking: boolean;
  attribute: {
    api_key?: null | string;
    deploy_name?: null | string;
    header_title?: null | string;
    header_background_color?: null | string;
    header_text_color?: null | string;
    chat_response_color?: null | string;
    chat_response_text_color?: null | string;
    logo_url?: null | string;
    bot_url?: null | string;
    welcome_message?: null | string;
    zendesk_api_key?: null | string;
  };
  greeting?: string | null;
  persistent: boolean;
  runtime_tenant_id: number | null;
  facebook_page_id?: string | null;
  facebook_page_access_token?: string | null;
  facebook_page_name?: string | null;
  rules?: Rule;
};

export type Rule = Record<
  string,
  {
    referral_code: string;
    greeting_message?: string;
  }
>;

export type ReferralCode = {
  deploy_id: number;
  refer_id?: number | null;
  refer_code: string;
  intent_id: number;
  referral_contexts: Array<{
    refer_context_id?: number | null;
    context_name: string;
  }>;
};

export const DeployType = {
  Facebook: 1,
  Webchat: 2,
  SalesIQ: 4,
} as const;

export const DeploymentStatusEnum = {
  NewRecord: 0,
  /**
   * DB Tenant Info allocated / populated
   */
  Sharded: 1,
  /**
   * cloud resources allocated
   */
  Provisioned: 2,
  /**
   * deployment container is built
   */
  Deployed: 3,
  /**
   * deployment has been started
   */
  Started: 4,
} as const;

export const DeploymentRequestType = {
  Deploy: 1,
  Start: 2,
  Stop: 3,
  Delete: 5,
} as const;

export const DeploymentRequestStatus = {
  Idle: 0,
  Loading: 1,
} as const;
