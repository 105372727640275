import 'bootstrap/dist/css/bootstrap.css';
import 'react-image-crop/dist/ReactCrop.css';

import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { base64ToBlob } from 'base64-blob';
import { Component } from 'react';
import { Button } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import { connect } from 'react-redux';

import { BotcoAPI } from '@botco/api';
import spinner from '~/assets/img/35.gif';
import upload from '~/assets/img/UPLOAD.png';
import { constants } from '~/config';
import { updateAgent } from '~/redux/actions/conversationDataR';

const newApiCall = new BotcoAPI(constants.api_url);

class EditImageModal extends Component {
  state = {
    conversation_title: '',
    avatar: '',
    noavtar: true,
    avatarImg: '',
    avatarPreview: '',
    croppedImage: '',
    showSpinner: false,
    saveBtnDisable: true,
    croppedImageUrl: '',
    crop: {
      unit: 'px',
      width: 200,
      height: 200,
      maxWidth: 500,
      aspect: 1 / 1,
    },
  };

  uploadAvatar = async () => {
    const body = new FormData();
    const avatarBlob = await base64ToBlob(this.state.croppedImageUrl);
    body.append('file', avatarBlob);
    const response = await newApiCall.uploadFile(body);
    const { location } = response.data;
    return location;
  };

  editConversationAvatar = async (event) => {
    const { agentDetails, updateAgent, closed } = this.props;
    event.preventDefault();
    this.setState({
      showSpinner: true,
      saveBtnDisable: true,
    });

    const location = await this.uploadAvatar();
    updateAgent({
      ...agentDetails,
      avatar: location,
    });

    document.getElementById('myInput').value = '';
    this.setState({
      showSpinner: false,
      croppedImageUrl: '',
      avatarPreview: '',
      croppedImage: '',
    });
    this.removeImage(event);
    closed();
  };

  removeImage = (event) => {
    event.preventDefault();
    this.setState({
      avatarPreview: '',
      croppedImage: '',
      saveBtnDisable: true,
    });
  };

  onSelectFile = (e) => {
    this.setState({
      crop: {
        unit: 'px',
        width: 200,
        height: 200,
        maxWidth: 500,
        aspect: 1 / 1,
      },
    });
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      (e.target.files[0].type === 'image/png' ||
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/jpg' ||
        e.target.files[0].type === 'image/gif' ||
        e.target.files[0].type === 'image/bmp')
    ) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({
          avatarPreview: reader.result,
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    } else {
      alert('Invalid format');
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    if (crop.width >= 200 && crop.width <= 500) this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl, saveBtnDisable: false });
    }
  }

  getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL('image/jpeg');
    return base64Image;
  }

  closeCreateConvModal = () => {
    document.getElementById('myInput').value = '';
    this.setState(
      {
        croppedImageUrl: '',
        avatarPreview: '',
        saveBtnDisable: true,
      },
      () => this.props.closed()
    );
  };

  render() {
    const avatarCheck = this.props.avatarImage;
    const { crop } = this.state;
    return (
      <Dialog fullWidth open={this.props.openEditImage}>
        <DialogContent>
          <div className="ModalSummary">
            <form
              onSubmit={(e) => {
                this.editConversationAvatar(e);
              }}
              encType="multipart/form-data"
            >
              <h4 className="title">Edit Conversation Image</h4>
              <div className="content">
                <div className="row">
                  <input
                    id="myInput"
                    type="file"
                    ref={(ref) => (this.myInput = ref)}
                    style={{ display: 'none' }}
                    onChange={this.onSelectFile}
                  />
                </div>
                <div className="condition row" style={{ left: 0 }}>
                  {!this.state.avatarPreview &&
                    !avatarCheck &&
                    (this.props.coversationAvatar === 'null' ||
                      this.props.coversationAvatar === null ||
                      this.props.coversationAvatar === '') && (
                      <div className="noAvatarModal"></div>
                    )}
                  {!this.state.avatarPreview &&
                    avatarCheck &&
                    this.props.coversationAvatar &&
                    this.props.coversationAvatar !== 'null' && (
                      <Avatar
                        style={{ left: -10 }}
                        src={this.props.coversationAvatar}
                      ></Avatar>
                    )}
                  <div className="">
                    <img
                      alt="upload"
                      src={upload}
                      className="mr-5 mt-5 pointer"
                      onClick={() => this.myInput.click()}
                    />
                  </div>
                  {/* {this.state.avatarPreview != "" &&
                    <div className="col-md-10">
                      <div className="">
                        <img src={this.state.avatarPreview} className="avatarPreview" />
                      </div>
                    </div>
                  } */}
                  {this.state.avatarPreview == '' && (
                    <div className="col-md-10 react-divs ml-0">
                      <span className="conv_placeholders">
                        (Images should be 200px x 200px,ideally uploaded as a
                        square.)
                      </span>
                    </div>
                  )}
                </div>
                {this.state.avatarPreview !== '' && (
                  <div
                    className="col-12 text-center p-0"
                    style={{ marginTop: '20px' }}
                  >
                    <ReactCrop
                      src={this.state.avatarPreview}
                      crop={crop}
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  </div>
                )}
                <div className="btns row">
                  <div className="text-center botco-fluid">
                    <Button
                      className="botco-outline-button"
                      onClick={() => this.closeCreateConvModal()}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="botco-red-outline-button"
                      type="submit"
                      onClick={this.editConversationAvatar}
                      disabled={this.state.saveBtnDisable}
                    >
                      Save
                    </Button>
                    {this.state.showSpinner ? (
                      <img alt="spinner" src={spinner} />
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

EditImageModal.propTypes = {};

const mapActionsToProps = {
  updateAgent,
};

// eslint-disable-next-line import/no-default-export
export default connect(null, mapActionsToProps)(EditImageModal);
