import '~/assets/css/style.css';
import '~/assets/css/Tabs.css';

import { clsx } from 'clsx';
import * as React from 'react';

const transitionTime = 200;
const transitionStyle = `left ${transitionTime}ms, right ${transitionTime}ms`;

export class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sizes: {},
    };
    this.els = {};
  }
  componentDidMount() {
    this.getSizes();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.children !== this.props.children &&
      prevProps.active !== this.props.active
    ) {
      this.getSizes();
    }
  }

  getSizes() {
    const rootBounds = this.root.getBoundingClientRect();

    const sizes = {};

    Object.keys(this.els).forEach((key) => {
      const el = this.els[key];
      const bounds = el.getBoundingClientRect();

      const left = bounds.left - rootBounds.left;
      const right = rootBounds.right - bounds.right;

      sizes[key] = { left, right };
    });

    this.setState({ sizes });
    return sizes;
  }

  render() {
    const { children, active, onChange } = this.props;
    return (
      <div className="Tabs" ref={(el) => (this.root = el)}>
        {React.Children.map(children, (child, i) => (
          <div
            style={{ fontSize: 18 }}
            className={clsx(
              'Tabs__Tab',
              i === 4 && 'templatesNav',
              child.key === active && 'Tabs__Tab--active'
            )}
            onClick={() => onChange(child.key)}
            ref={(el) => (this.els[child.key] = el)}
          >
            {child}
          </div>
        ))}
        <div className="Tabs__Underline" style={this.getUnderlineStyle()} />
      </div>
    );
  }

  getUnderlineStyle() {
    if (!this.props.active && Object.keys(this.state.sizes).length === 0) {
      return { left: '0', right: '100%' };
    } else if (this.props.active === 'all') {
      return { left: '25px', right: '705px', transition: transitionStyle };
    } else if (this.props.active === 'intents') {
      return { left: '25px', right: '485px', transition: transitionStyle };
    }
    const size = this.state.sizes[this.props.active];

    return {
      left: `${size?.left ?? 0 + 25}px`,
      right: `${size?.right ?? 0 + 25}px`,
      transition: transitionStyle,
    };
  }
}
