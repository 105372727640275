import * as React from 'react';

import type { ICreatableDropDownOption } from '@botco/library';
import { useAttributeContext } from '~/hooks/useAttributeContext';
import { getLastItemInArray } from '~/utils/arrayUtils';
import {
  isReservedAttributeName,
  isValidAttributeName,
  transformAttributes,
} from '~/utils/attributeUtils';

import { AttributeInputProps } from './AttributesInput.types';

type Options = Pick<
  AttributeInputProps,
  'value' | 'onChange' | 'agentId' | 'additionalAttributes'
>;

export const useAttributeInput = ({
  agentId,
  additionalAttributes,
  onChange,
  value,
}: Options) => {
  const [showInvalidAttributeMessage, setShowInvalidAttributeMessage] =
    React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const { attributes, createAttribute } = useAttributeContext();

  const options = React.useMemo(
    () => transformAttributes(attributes, { additionalAttributes }),
    [attributes, additionalAttributes]
  );

  const current = (() => {
    if (!value?.cust_attr_id) {
      return options.find((option) => option.label === value?.cust_attr_name);
    }

    return options.find(
      (option) => `${option.value}` === `${value.cust_attr_id}`
    );
  })();

  const handleSetValue = (option: ICreatableDropDownOption) => {
    if (option.value === null) {
      onChange?.({
        cust_attr_name: option.label,
        agent_id: agentId,
        cust_attr_type: 2,
      });
      return;
    }

    onChange?.(
      attributes.find(
        (attribute) => attribute.cust_attr_id === Number(option.value)
      )
    );
  };

  const handleChange = (
    option:
      | ICreatableDropDownOption
      | [ICreatableDropDownOption, string | ICreatableDropDownOption]
      | ICreatableDropDownOption[]
  ) => {
    if (!Array.isArray(option)) {
      return;
    }
    // Get the last item in the array because it returns the previous and the new value
    const last = getLastItemInArray(option);
    if (!last) {
      onChange?.();
      return;
    }

    if (typeof last === 'string') {
      handleCreate(last);
      return;
    }

    if (!last.value) {
      handleCreate(last.label);
      return;
    }

    handleSetValue(last);
  };

  const handleCreate = async (value: string) => {
    if (isReservedAttributeName(value)) {
      return;
    }

    // If the attribute already exists, set it as the value
    const exists = options.find(
      (option) => Boolean(option.value) && option.label === value
    );
    if (exists) {
      handleSetValue(exists);
      return;
    }

    // Create the attribute
    createAttribute(value).then((attribute) => onChange?.(attribute));
  };

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    if (!event) {
      return;
    }
    const lowerCaseValue = value.toLowerCase();
    if (!isValidAttributeName(lowerCaseValue)) {
      setShowInvalidAttributeMessage(true);
      return;
    }
    setShowInvalidAttributeMessage(false);
    setInputValue(lowerCaseValue);
  };

  return {
    currentValue: current ? [current] : [],
    options,
    onChange: handleChange,
    onCreate: handleCreate,
    inputValue,
    onInputChange: handleInputChange,
    showInvalidAttributeMessage,
  };
};
