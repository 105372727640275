import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { useApi } from '~/hooks/useApi';
import { handleErrors } from '~/utils';
import { isValidAgentId } from '~/utils/agentUtils';
import { Attribute } from '~/utils/http/agent/types';
import { Api } from '~/utils/http/api';

type AttributeProviderProps = {
  agentId: number;
  children: React.ReactNode;
};

type AttributeContextType = {
  attributes: Attribute[];
  createAttribute: (attribute: string) => Promise<Attribute>;
  loading: boolean;
};

export const AttributeContext = React.createContext<AttributeContextType>({
  attributes: [],
  createAttribute: () => Promise.resolve({} as Attribute),
  loading: false,
});

export const AttributeProvider = ({
  children,
  agentId,
}: AttributeProviderProps) => {
  const queryClient = useQueryClient();
  const queryKey = React.useMemo(() => ['attributes', agentId], [agentId]);

  const {
    data: attributes = [],
    refetch,
    isLoading,
  } = useApi(queryKey, (Api) => Api.agent.getAgentAttributes({ agentId }), {
    enabled: isValidAgentId(agentId),
  });

  const createAttribute = useMutation({
    onSuccess: () => {
      refetch();
    },
    onMutate: async (attribute: string) => {
      await queryClient.cancelQueries({ queryKey });

      const previousAttributes =
        queryClient.getQueryData<Attribute[]>(queryKey);

      const option: Attribute = {
        agent_id: agentId,
        cust_attr_id: null as any,
        cust_attr_name: attribute,
        cust_attr_type: 2,
      };

      queryClient.setQueryData<Attribute[]>(queryKey, (oldAttributes = []) => [
        ...oldAttributes,
        option,
      ]);

      return {
        previousAttributes,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (error, _, context) => {
      handleErrors(error);
      queryClient.setQueryData(queryKey, context?.previousAttributes);
    },
    mutationFn: async (value: string) => {
      return Api.agent
        .createAgentAttribute({ agentId, attribute: value })
        .then(({ data }) => data);
    },
  });

  const handleCreateAttribute = (attribute: string) => {
    return createAttribute.mutateAsync(attribute);
  };

  return (
    <AttributeContext.Provider
      value={{
        attributes,
        createAttribute: handleCreateAttribute,
        loading: isLoading,
      }}
    >
      {children}
    </AttributeContext.Provider>
  );
};
