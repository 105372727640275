import Axios from 'axios';

import { constants } from '~/config';

export const axiosClient = Axios.create({
  baseURL: constants.api_url,
});

axiosClient.interceptors.request.use((request) => {
  const token = window.localStorage.getItem('token');
  if (token) {
    request.headers!['Authorization'] = `Bearer ${token}`;
  }

  return request;
});
