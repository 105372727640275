import { AxiosError } from 'axios';
import { UseQueryOptions } from 'react-query';

import { useApi } from '~/hooks/useApi';
import { isValidAgentId } from '~/utils/agentUtils';

import { AgentType } from '../ConversationsList/types';

type PropsType = UseQueryOptions<AgentType, AxiosError<unknown>, AgentType> & {
  agentId: number;
};

export const useGetAgent = ({ agentId, ...queryOptions }: PropsType) => {
  return useApi(['Agent', agentId], (Api) => Api.agent.getAgentById(agentId), {
    enabled: isValidAgentId(agentId),
    ...queryOptions,
  });
};
