import { axiosClient } from '../axiosClient';

type WidgetInfo = {
  widget_version: string
  widget_url: string
}

export const getLatestWidgetInfo = async () => {
  return axiosClient.get<WidgetInfo>("/webchat-widget-versions/latest");
};
