import { CircularProgress, Box } from '@material-ui/core';
import * as React from 'react';
import { CSVLink } from 'react-csv';

import { Button, type ButtonProps } from '@botco/library';

type CSVLinkProps = React.ComponentProps<typeof CSVLink>;

type DataProps =
  | { kind: 'array'; data: any[] }
  | {
      kind: 'function';
      handleGetCsvData: () => Promise<any[] | undefined>;
    };

type PropsType = DataProps & {
  title?: string;
  headers?: CSVLinkProps['headers'];
  fileName: string;
  variant?: ButtonProps['variant'];
  disabled?: boolean;
};

export const DashboardCSVButton = (props: PropsType) => {
  const csvLink = React.useRef<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [csvData, setCsvData] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (props.kind === 'array') {
      setCsvData(props.data);
    }
  }, [props]);

  const handleExportCsv = async () => {
    if (props.kind === 'function') {
      setIsLoading(true);
      const data = await props.handleGetCsvData();
      setCsvData(data ?? []);

      if (data && data.length > 0) {
        csvLink.current.link.click();
      }
      setIsLoading(false);
      return;
    }

    if (props.kind === 'array' && csvData?.length > 0) {
      csvLink.current.link.click();
    }
  };

  return (
    <>
      <Button
        variant={props.variant ?? 'text'}
        disabled={isLoading || props.disabled}
        onClick={handleExportCsv}
      >
        {props.title ?? 'Export CSV'}
        {isLoading && (
          <Box marginLeft={1}>
            <CircularProgress size={18} />
          </Box>
        )}
      </Button>
      <CSVLink
        filename={props.fileName ?? 'botco.csv'}
        data={csvData}
        ref={csvLink}
        headers={props.headers}
        target="_blank"
        className="hidden"
      />
    </>
  );
};
