import { makeStyles, Link as MuiLink } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';

import { TableCell, Tooltip, Typography } from '@botco/library';
import { QueryConditionsDto } from '~/__generated__/query-service';
import { routes } from '~/constants/routes';
import { useQueryServiceApi } from '~/hooks/useQueryServiceApi';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { QueryServiceMetricsConfig } from '~/types/query-service-helpers';
import { validURL } from '~/utils';
import type { Attribute } from '~/utils/http/agent/types';
import { formatPercentage } from '~/utils/numberUtils';

import { useDashboardQueryServiceContext } from '../../Provider/DasboardQueryServiceProvider';

const useStyles = makeStyles(() => ({
  nameCell: {
    display: 'flex',
  },
  nameText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '264px',
  },
}));

type Props = {
  value: string;
  goals: string[];
  filteredBy: string;
};

export const AttributeBreakdownRow = ({ goals, value, filteredBy }: Props) => {
  const classes = useStyles();
  const { conditions } = useDashboardQueryServiceContext();
  const { selectedAgent } = useSelectedChatbot();

  const condition = {
    ...conditions!,
    attributes: {
      logical_operator: 'and',
      conditions: [
        ...(conditions?.attributes?.conditions ?? []),
        {
          name: filteredBy,
          operator: 'eq',
          value: value,
        },
      ],
    },
  } satisfies QueryConditionsDto;

  const { data, isLoading } = useQueryServiceApi(
    ['queryService', 'attributeBreakdown', condition],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: condition,
          projection: [
            QueryServiceMetricsConfig.UniqueViews,
            QueryServiceMetricsConfig.Calls,
            QueryServiceMetricsConfig.EngagementRate,
            QueryServiceMetricsConfig.EngagedUsers,
            QueryServiceMetricsConfig.SuccessRate,
            QueryServiceMetricsConfig.Goals,
          ],
        },
      }),
    {
      enabled: Boolean(conditions),
    }
  );

  const getContactsLink = () => {
    return `${routes.contacts.get(selectedAgent?.id!, {
      options: {
        filters: {
          attributes: {
            logical_operator: 'and',
            conditions: [
              {
                operator: 'eq',
                attribute: { cust_attr_name: filteredBy } as Attribute,
                value,
              },
            ],
          },
        },
      },
    })}`;
  };

  const goalsMap = (data?.goals?.content?.counts_by_tag ?? []).reduce(
    (acc, { tag, count }) => {
      acc.set(tag!, count!);
      return acc;
    },
    new Map<string, number>()
  );

  const isValidUrl = validURL(value);

  return (
    <>
      <TableCell>
        <div className={classes.nameCell}>
          <Link to={getContactsLink()}>
            <Typography
              color="primary"
              fontWeight={500}
              className={classes.nameText}
            >
              {value}
            </Typography>
          </Link>
          {isValidUrl && (
            <Tooltip
              title={value}
              interactive
              placement="top"
              arrow={false}
              open={isValidUrl ? undefined : false}
            >
              <MuiLink href={value} target="_blank">
                <Launch fontSize="small" />
              </MuiLink>
            </Tooltip>
          )}
        </div>
      </TableCell>
      {isLoading ? (
        <>
          {[...Array(5 + goals.length)].map((_, i) => (
            <TableCell key={i}>
              <Skeleton role="progressbar" animation="pulse" />
            </TableCell>
          ))}
        </>
      ) : (
        <>
          <TableCell align="center">
            <Typography color="black">
              {data?.widget_views?.count ?? 0}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography color="black">{data?.users?.count ?? 0}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography color="black">
              {formatPercentage(
                data?.calculations?.content?.engagement_rate?.engagement_rate
              )}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography color="black">
              {formatPercentage(
                data?.calculations?.content?.success_rate?.success_rate
              )}
            </Typography>
          </TableCell>
          {goals.map((goal) => (
            <TableCell key={goal} align="center">
              <Typography color="black">{goalsMap.get(goal) ?? 0}</Typography>
            </TableCell>
          ))}
          <TableCell align="center">
            <Typography color="black">
              {data?.widget_clicks?.count ?? 0}
            </Typography>
          </TableCell>
        </>
      )}
    </>
  );
};
