import { styled } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import * as React from 'react';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const PopularBlocksSkeleton: React.FC = () => {
  return (
    <Wrapper>
      <div>
        <Skeleton width="30%" />
        <Skeleton variant="rect" height={30} width="100%" />
      </div>
      <div>
        <Skeleton width="30%" />
        <Skeleton variant="rect" height={30} width="100%" />
      </div>
      <div>
        <Skeleton width="30%" />
        <Skeleton variant="rect" height={30} width="100%" />
      </div>
      <div>
        <Skeleton width="30%" />
        <Skeleton variant="rect" height={30} width="100%" />
      </div>
      <div>
        <Skeleton width="30%" />
        <Skeleton variant="rect" height={30} width="100%" />
      </div>
    </Wrapper>
  );
};
