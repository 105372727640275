export const formatCurrency = (value: number = 0) => {
  return Intl.NumberFormat('en-US', {
    style: 'decimal',
  }).format(value);
};

// formatPercentage
// The formatPercentage function takes a number and returns a string with the number formatted as a percentage.
// The value needs to be a number between 0 and 100
export const formatPercentage = (value?: number | null) => {
  if (value === undefined || value === null) {
    return 'N/A';
  }

  return Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value / 100);
};

export const formatNumber = (value?: number | null) => {
  if (value === undefined || value === null) {
    return 'N/A';
  }

  return Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value);
};
