import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractOkResponseFromPath,
  ExtractPathParamsFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';

type PathUrl = paths['/agents/{agentId}/attributes/{custAttrId}/values']['get'];

type UrlParams = ExtractPathParamsFromPath<PathUrl>;
type Response = ExtractOkResponseFromPath<PathUrl>;

type Request = {
  params: UrlParams;
};

export const getAgentAttributeValues = ({
  params: { agentId, custAttrId },
}: Request) => {
  return axiosClient.get<Response>(
    `/agents/${agentId}/attributes/${custAttrId}/values`
  );
};
