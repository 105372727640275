import { Role, User } from './types';
import { axiosClient } from '../axiosClient';

export type CreateUserParams = Required<
  Pick<User, 'account_id' | 'email' | 'first_name' | 'last_name'>
> & { roles: Array<{ role_id: Role['role_id'] }> };

export const createUser = (user: CreateUserParams) => {
  return axiosClient.post(`/users`, user);
};
