import { PaginatedResponse } from '@botco/api';
import { paths } from '~/__generated__/botco-api-schema';
import { ExtractQueryParamsFromPath } from '~/types/open-api-helpers';

import { AITrainingType } from './types';
import { axiosClient } from '../axiosClient';

type PathUrl = paths['/instastack/training-v3']['get'];
type Params = ExtractQueryParamsFromPath<PathUrl>;

export async function getAITrainings({
  collectionIds,
  sort,
  ...rest
}: Params = {}) {
  return axiosClient.get<PaginatedResponse<AITrainingType[]>>(
    '/instastack/training-v3',
    {
      params: {
        collectionIds: collectionIds?.join(','),
        sort: sort?.[0],
        ...rest,
      },
    }
  );
}
