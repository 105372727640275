import { clsx } from 'clsx';
import ReactPlayer from 'react-player';

import { Tooltip } from '@botco/library';
import type {
  ChatChannelDto,
  SessionTranscriptMessageDto,
} from '~/__generated__/query-service';
import type {
  TranscriptSender,
  TranscriptTypes,
} from '~/utils/http/transcript/types';

import * as S from './TranscriptItem.styles';

type Props = {
  sender: TranscriptSender | SessionTranscriptMessageDto['sent_from'];
  text: string;
  timestamp: string;
  avatar?: string;
  type?: TranscriptTypes;
  channel?: ChatChannelDto;
  className?: string;
  testId?: string;
};

export const TranscriptItem = ({
  avatar,
  sender,
  text,
  type,
  timestamp,
  channel,
  className,
  testId,
}: Props) => {
  const getMessage = () => {
    switch (type) {
      case 'video':
        return (
          <ReactPlayer
            url={text || 'https://www.youtube.com/watch?v=ysz5S6PUM-U'}
            width="100%"
            height="auto"
            controls
            playing={false}
          />
        );
      case 'image':
        return (
          <img
            alt="message card"
            src={text ?? 'https://place-hold.it/150'}
            className="img-fluid img-thumbnail"
          />
        );
      default:
        return (
          <S.MessageTypography fontWeight="500">{text}</S.MessageTypography>
        );
    }
  };

  return (
    <S.Container $sender={sender} className={className} data-testid={testId}>
      <S.BubbleWrapper>
        <S.Bubble
          className={clsx({
            receiver: sender === 'user',
            sms: sender === 'user' && channel?.code === 'sms',
          })}
          elevation={0}
          key={text}
          role="listitem"
        >
          {getMessage()}
        </S.Bubble>
        <Tooltip
          placement="bottom"
          title={new Date(timestamp).toLocaleDateString('en-US')}
        >
          <S.Typography variant="caption" color="textSecondary">
            {sender === 'user' ? 'User' : 'Bot'}&nbsp;
            {new Date(timestamp).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
              timeZoneName: 'short',
            })}
          </S.Typography>
        </Tooltip>
      </S.BubbleWrapper>
      {sender !== 'user' ? (
        <S.Avatar>
          <S.Img src={avatar} />
        </S.Avatar>
      ) : (
        <></>
      )}
    </S.Container>
  );
};
