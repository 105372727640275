import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';

import { Button, Select, TextInput } from '@botco/library';

import { JSONTemplateFormSchema } from './APIJSONTemplateModal.types';
import type { IntentApiJsonTemplate } from '../../../APICard.types';
import {
  ApiJSONTypeChoicesItems,
  ApiJsonTypesEnum,
} from '../../../constants/options';

type PropsType = {
  open: boolean;
  error?: string;
  jsonTemplate?: IntentApiJsonTemplate;
  onClose: () => void;
  onAddTemplate: (data: JSONTemplateFormSchema) => void;
};

const JSONPlaceholder = `"payload": {
    "template_type":"generic",
    "elements":[
      {
        "title":"<TITLE_TEXT>",
        "image_url":"<IMAGE_URL_TO_DISPLAY>",
        "subtitle":"<SUBTITLE_TEXT>",
        "default_action":{
          "type":"web_url",
          "url":"<DEFAULT_URL_TO_OPEN>",
          "messenger_extensions":<TRUE|FALSE>,
          "webview_height_ratio": "<COMPACT|TALL|FULL>"
        },
        "buttons":[<BUTTON_OBJECT>,...]
      }
    ]
  }
"` as const;

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    justifyContent: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(3, 0),
  },
}));

export const APIJSONTemplateModal = ({
  open,
  error,
  jsonTemplate,
  onAddTemplate,
  onClose,
}: PropsType) => {
  const classes = useStyles();

  const formMethods = useForm<JSONTemplateFormSchema>({
    resolver: yupResolver(JSONTemplateFormSchema),
    defaultValues: {
      type:
        (jsonTemplate?.template_location as ApiJsonTypesEnum) ??
        ApiJsonTypesEnum.None,
      template: jsonTemplate?.template_data ?? '',
    },
  });

  const handleSubmit = formMethods.handleSubmit(onAddTemplate);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Controller
          control={formMethods.control}
          name="type"
          render={({ field, fieldState }) => (
            <Select
              label="JSON Type"
              fullWidth
              options={ApiJSONTypeChoicesItems}
              current={field.value ?? ''}
              onChange={field.onChange}
              error={Boolean(error || fieldState.error?.message)}
              helperText={error || fieldState.error?.message}
            />
          )}
        />
        <Box mt={2}>
          <Controller
            control={formMethods.control}
            name="template"
            render={({ field, fieldState }) => (
              <TextInput
                fullWidth
                multiline
                rows={10}
                onChange={field.onChange}
                value={field.value}
                error={Boolean(fieldState.error?.message)}
                helperText={fieldState.error?.message}
                label="JSON Template"
                placeholder={JSONPlaceholder}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" color="grey" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!formMethods.formState.isValid}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
